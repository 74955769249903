import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const textTransition = {
    hidden: { opacity: 0, y: 50 },
    visible: custom => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
            ease: "easeInOut",
        }
    })
};

const opacityTransition = {
    visible: custom => ({
      opacity: 1,
      transition: {
        delay: custom * 0.4,
        duration: 0.3,
      },
    }),
    hidden: { opacity: 0 },
};

const opacityTransitionRepeat = {
    visible: custom => ({
        opacity: 1,
        transition: {
        delay: custom * 0.4,
        duration: 1,
        repeat: 3,
        ease: "easeInOut",
        },
    }),
    hidden: { opacity: 0 },
};


const ControllerAnimated2 = () => {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.5 });

    return (
        <motion.svg 
            ref={containerRef}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            custom={1} variants={textTransition}
            width="665" height="348" viewBox="0 0 665 348" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M286.081 5.60114H211.271V342.399H286.081V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M267.075 146.116C270.977 146.116 274.141 142.954 274.141 139.054C274.141 135.153 270.977 131.992 267.075 131.992C263.172 131.992 260.008 135.153 260.008 139.054C260.008 142.954 263.172 146.116 267.075 146.116Z" fill="white"/>
            <path d="M267.075 170.225C270.977 170.225 274.141 167.063 274.141 163.163C274.141 159.263 270.977 156.101 267.075 156.101C263.172 156.101 260.008 159.263 260.008 163.163C260.008 167.063 263.172 170.225 267.075 170.225Z" fill="white"/>
            <path d="M267.075 194.335C270.977 194.335 274.141 191.173 274.141 187.272C274.141 183.372 270.977 180.21 267.075 180.21C263.172 180.21 260.008 183.372 260.008 187.272C260.008 191.173 263.172 194.335 267.075 194.335Z" fill="white"/>
            <path d="M267.075 218.2C270.977 218.2 274.141 215.038 274.141 211.138C274.141 207.237 270.977 204.076 267.075 204.076C263.172 204.076 260.008 207.237 260.008 211.138C260.008 215.038 263.172 218.2 267.075 218.2Z" fill="white"/>
            <path d="M268.537 43.8348H227.599C226.137 43.8348 225.162 42.6172 225.162 41.3996V24.1092C225.162 22.648 226.381 21.6739 227.599 21.6739H268.537C269.999 21.6739 270.974 22.8915 270.974 24.1092V41.3996C270.974 42.6172 269.755 43.8348 268.537 43.8348Z" fill="white"/>
            <path d="M360.401 5.60114H285.592V342.399H360.401V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M339.446 146.116C343.348 146.116 346.512 142.954 346.512 139.054C346.512 135.153 343.348 131.992 339.446 131.992C335.543 131.992 332.379 135.153 332.379 139.054C332.379 142.954 335.543 146.116 339.446 146.116Z" fill="white"/>
            <path d="M339.446 170.225C343.348 170.225 346.512 167.063 346.512 163.163C346.512 159.263 343.348 156.101 339.446 156.101C335.543 156.101 332.379 159.263 332.379 163.163C332.379 167.063 335.543 170.225 339.446 170.225Z" fill="white"/>
            <path d="M339.446 194.335C343.348 194.335 346.512 191.173 346.512 187.272C346.512 183.372 343.348 180.21 339.446 180.21C335.543 180.21 332.379 183.372 332.379 187.272C332.379 191.173 335.543 194.335 339.446 194.335Z" fill="white"/>
            <path d="M339.446 218.2C343.348 218.2 346.512 215.038 346.512 211.138C346.512 207.237 343.348 204.076 339.446 204.076C335.543 204.076 332.379 207.237 332.379 211.138C332.379 215.038 335.543 218.2 339.446 218.2Z" fill="white"/>
            <path d="M343.344 43.8348H302.406C300.943 43.8348 299.969 42.6172 299.969 41.3996V24.1092C299.969 22.648 301.187 21.6739 302.406 21.6739H343.344C344.806 21.6739 345.78 22.8915 345.78 24.1092V41.3996C346.024 42.6172 344.806 43.8348 343.344 43.8348Z" fill="white"/>
            <path d="M434.724 5.60114H359.914V342.399H434.724V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M417.422 146.116C421.325 146.116 424.489 142.954 424.489 139.054C424.489 135.153 421.325 131.992 417.422 131.992C413.519 131.992 410.355 135.153 410.355 139.054C410.355 142.954 413.519 146.116 417.422 146.116Z" fill="white"/>
            <path d="M417.422 170.225C421.325 170.225 424.489 167.063 424.489 163.163C424.489 159.263 421.325 156.101 417.422 156.101C413.519 156.101 410.355 159.263 410.355 163.163C410.355 167.063 413.519 170.225 417.422 170.225Z" fill="white"/>
            <path d="M417.422 194.335C421.325 194.335 424.489 191.173 424.489 187.272C424.489 183.372 421.325 180.21 417.422 180.21C413.519 180.21 410.355 183.372 410.355 187.272C410.355 191.173 413.519 194.335 417.422 194.335Z" fill="white"/>
            <path d="M417.422 218.2C421.325 218.2 424.489 215.038 424.489 211.138C424.489 207.237 421.325 204.076 417.422 204.076C413.519 204.076 410.355 207.237 410.355 211.138C410.355 215.038 413.519 218.2 417.422 218.2Z" fill="white"/>
            <path d="M418.154 43.8348H377.216C375.754 43.8348 374.779 42.6172 374.779 41.3996V24.1092C374.779 22.648 375.998 21.6739 377.216 21.6739H418.154C419.616 21.6739 420.591 22.8915 420.591 24.1092V41.3996C420.591 42.6172 419.616 43.8348 418.154 43.8348Z" fill="white"/>
            <path d="M509.534 5.60114H434.725V342.399H509.534V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M489.553 146.116C493.456 146.116 496.62 142.954 496.62 139.054C496.62 135.153 493.456 131.992 489.553 131.992C485.65 131.992 482.486 135.153 482.486 139.054C482.486 142.954 485.65 146.116 489.553 146.116Z" fill="white"/>
            <path d="M489.553 170.225C493.456 170.225 496.62 167.063 496.62 163.163C496.62 159.263 493.456 156.101 489.553 156.101C485.65 156.101 482.486 159.263 482.486 163.163C482.486 167.063 485.65 170.225 489.553 170.225Z" fill="white"/>
            <path d="M489.553 194.335C493.456 194.335 496.62 191.173 496.62 187.272C496.62 183.372 493.456 180.21 489.553 180.21C485.65 180.21 482.486 183.372 482.486 187.272C482.486 191.173 485.65 194.335 489.553 194.335Z" fill="white"/>
            <path d="M489.553 218.2C493.456 218.2 496.62 215.038 496.62 211.138C496.62 207.237 493.456 204.076 489.553 204.076C485.65 204.076 482.486 207.237 482.486 211.138C482.486 215.038 485.65 218.2 489.553 218.2Z" fill="white"/>
            <path d="M492.721 43.8348H451.782C450.32 43.8348 449.346 42.6172 449.346 41.3996V24.1092C449.346 22.648 450.564 21.6739 451.782 21.6739H492.721C494.183 21.6739 495.157 22.8915 495.157 24.1092V41.3996C495.157 42.6172 494.183 43.8348 492.721 43.8348Z" fill="white"/>
            <path d="M584.587 5.60114H509.777V342.399H584.587V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M567.53 146.116C571.432 146.116 574.596 142.954 574.596 139.054C574.596 135.153 571.432 131.992 567.53 131.992C563.627 131.992 560.463 135.153 560.463 139.054C560.463 142.954 563.627 146.116 567.53 146.116Z" fill="white"/>
            <path d="M567.53 170.225C571.432 170.225 574.596 167.063 574.596 163.163C574.596 159.263 571.432 156.101 567.53 156.101C563.627 156.101 560.463 159.263 560.463 163.163C560.463 167.063 563.627 170.225 567.53 170.225Z" fill="white"/>
            <path d="M567.53 194.335C571.432 194.335 574.596 191.173 574.596 187.272C574.596 183.372 571.432 180.21 567.53 180.21C563.627 180.21 560.463 183.372 560.463 187.272C560.463 191.173 563.627 194.335 567.53 194.335Z" fill="white"/>
            <path d="M567.53 218.2C571.432 218.2 574.596 215.038 574.596 211.138C574.596 207.237 571.432 204.076 567.53 204.076C563.627 204.076 560.463 207.237 560.463 211.138C560.463 215.038 563.627 218.2 567.53 218.2Z" fill="white"/>
            <path d="M567.529 43.8348H526.591C525.129 43.8348 524.154 42.6172 524.154 41.3996V24.1092C524.154 22.648 525.373 21.6739 526.591 21.6739H567.529C568.991 21.6739 569.966 22.8915 569.966 24.1092V41.3996C569.966 42.6172 568.991 43.8348 567.529 43.8348Z" fill="white"/>
            <path d="M659.396 5.60114H5.60547V342.399H659.396V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M659.395 5.60114H584.586V342.399H659.395V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M639.901 146.116C643.803 146.116 646.967 142.954 646.967 139.054C646.967 135.153 643.803 131.992 639.901 131.992C635.998 131.992 632.834 135.153 632.834 139.054C632.834 142.954 635.998 146.116 639.901 146.116Z" fill="white"/>
            <path d="M639.901 170.225C643.803 170.225 646.967 167.063 646.967 163.163C646.967 159.263 643.803 156.101 639.901 156.101C635.998 156.101 632.834 159.263 632.834 163.163C632.834 167.063 635.998 170.225 639.901 170.225Z" fill="white"/>
            <path d="M639.901 194.335C643.803 194.335 646.967 191.173 646.967 187.272C646.967 183.372 643.803 180.21 639.901 180.21C635.998 180.21 632.834 183.372 632.834 187.272C632.834 191.173 635.998 194.335 639.901 194.335Z" fill="white"/>
            <path d="M639.901 218.2C643.803 218.2 646.967 215.038 646.967 211.138C646.967 207.237 643.803 204.076 639.901 204.076C635.998 204.076 632.834 207.237 632.834 211.138C632.834 215.038 635.998 218.2 639.901 218.2Z" fill="white"/>
            <path d="M642.338 43.8348H601.4C599.938 43.8348 598.963 42.6172 598.963 41.3996V24.1092C598.963 22.648 600.181 21.6739 601.4 21.6739H642.338C643.8 21.6739 644.775 22.8915 644.775 24.1092V41.3996C644.775 42.6172 643.8 43.8348 642.338 43.8348Z" fill="white"/>
            <path d="M210.54 5.60114H5.60547V342.399H210.54V5.60114Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.6537 206.998H19.9824V238.656H32.6537V206.998Z" fill="white"/>
            <path d="M32.6537 252.781H19.9824V284.44H32.6537V252.781Z" fill="white"/>
            <path d="M32.6537 298.321H19.9824V329.979H32.6537V298.321Z" fill="white"/>
            <path d="M5.60547 5.60114V119.815H210.54V5.60114H5.60547ZM186.659 34.3373C182.76 34.3373 179.592 31.1715 179.592 27.275C179.592 23.3786 182.76 20.2128 186.659 20.2128C190.558 20.2128 193.726 23.3786 193.726 27.275C193.726 31.1715 190.558 34.3373 186.659 34.3373Z" fill="white"/>
            
            <motion.rect custom={3} variants={opacityTransitionRepeat} x="360" y="8" width="74" height="338" stroke="#FF1405" strokeWidth="15"/>
            <motion.rect custom={3} variants={opacityTransitionRepeat} x="286" y="8" width="74" height="338" stroke="#FF1405" strokeWidth="15"/>
            <motion.rect custom={4} variants={opacityTransitionRepeat} x="586" y="8" width="74" height="338" stroke="#1D18FB" strokeWidth="15"/>
            <motion.rect custom={4} variants={opacityTransitionRepeat} x="512" y="8" width="74" height="338" stroke="#1D18FB" strokeWidth="15"/>
        </motion.svg>
    );
};

export default ControllerAnimated2;


