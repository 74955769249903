// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../src/fonts/geometria_light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../../src/fonts/geometria_lightitalic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../../src/fonts/geometria_medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../../src/fonts/geometria_mediumitalic.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Geometria";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");font-weight:300;font-style:normal}@font-face{font-family:"Geometria";src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");font-weight:300;font-style:italic}@font-face{font-family:"Geometria";src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");font-weight:500;font-style:normal}@font-face{font-family:"Geometria";src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("opentype");font-weight:500;font-style:italic}`, "",{"version":3,"sources":["webpack://./sass/base/fonts.scss"],"names":[],"mappings":"AAAA,WACE,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAGF,WACE,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAGF,WACE,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAGF,WACE,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":["@font-face {\n  font-family: 'Geometria';\n  src: url('./../../src/fonts/geometria_light.otf') format('opentype');\n  font-weight: 300;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Geometria';\n  src: url('./../../src/fonts/geometria_lightitalic.otf') format('opentype');\n  font-weight: 300;\n  font-style: italic;\n}\n\n@font-face {\n  font-family: 'Geometria';\n  src: url('./../../src/fonts/geometria_medium.otf') format('opentype');\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Geometria';\n  src: url('./../../src/fonts/geometria_mediumitalic.otf') format('opentype');\n  font-weight: 500;\n  font-style: italic;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
