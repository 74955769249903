import React, { useEffect, useRef } from 'react'
import './SdisolDcs.scss'
import { motion } from "framer-motion";
import { animateLeftMove, animateRightMove } from '../../api/animation-varints';
import TextAnimated from '../../components/TextAnimated/TextAnimated';
import AnimatedSVGCircle from '../../components/AnimatedSVG/AnimatedSVGCircle';
import ControllerAnimated from '../../components/ControllerAnimated/ControllerAnimated';
import ControllerAnimated2 from '../../components/ControllerAnimated2/ControllerAnimated2';
import ControllerAnimated3 from '../../components/ControllerAnimated3/ControllerAnimated3';
import DCSAnimated2 from '../../components/DCSAnimated2/DCSAnimated2';
import ShieldAnimated from '../../components/ShieldAnimated/ShieldAnimated';
import DCSAnimated from '../../components/DCSAnimated/DCSAnimated';
import Gallery from '../../components/Gallery/Gallery';
import ArrowBottom from '../../components/ArrowBottom/ArrowBottom';

const SdisolDcs = () => {

    const textTransition = {
        hidden: { opacity: 0, y: 50 },
        visible: custom => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: .5,
                delay: custom * 0.4,
                ease: "easeInOut",
            }
        })
    };

    const arrowBtnRef = useRef(null)

    const handleScroll = () => {
        arrowBtnRef.current.style.opacity = (1 - (window.scrollY * 0.0008))
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <div className="dcs">
            <AnimatedSVGCircle classStyle={'svg-circle-right-full-dcs'}/>

            <motion.img
                custom={3} variants={animateLeftMove}
                src="images/svg-elements/landscape-big-element.svg"
                className="svg-element PAD-page-landscape-element "
            />

            <motion.img
                custom={4} variants={animateLeftMove}
                src="images/svg-elements/dsc_triangle.svg"
                className="svg-element PAD-page-round-element"
                alt=""/>

            <motion.section 
                initial="hidden"
                animate="visible" 
                className="dcs__main"
            >
                <div className="container">
                    <div className="dcs__main__header">
                        <h1 className="dcs__main__header-title">
                            <TextAnimated text={`Программно-аппаратный комплекс автоматизации технологических процессов`} fontSize={48} />
                        </h1>
                        <div className='dcs__main__header-wrapper'>
                        <motion.img className='dcs__main__header-logo' custom={1} variants={textTransition} src="images/DCSpage/logo_DCS.svg" alt="sdisol-DCS-logo"/>
                        {/* <motion.img custom={1} variants={textTransition} className='dcs__main__header-wrapper_cifra' src="images/PADpage/min-cifr.svg" alt="min-cifra"/>     */}
                        </div>
                    </div> 
                    <div className="dcs__main__content">
                        <div className='dsc__main_img'>
                            <DCSAnimated />
                        </div>
                        <div className="dcs__main__content-text">
                            <TextAnimated 
                                text={`Распределённая система управления сложными технологическими процессами на российских ПТС`} 
                                fontSize={32}
                                order={4}
                            />  
                        </div>
                    </div>
                </div>
                
            </motion.section>
            <div className='arrowBottomDcs_position' ref={arrowBtnRef}>
                <ArrowBottom />
            </div>

            <section className="dcs__method">
                <div className="container">
                    <h2 className="dcs__method-title">
                        <TextAnimated 
                            text={`Высокие требования к надежности и отказоустойчивости`} 
                            fontSize={46}
                        />   
                    </h2>
                    <motion.div 
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <motion.img
                            custom={1} 
                            variants={textTransition}   
                            src='images/DCSpage/controller_1.1.svg'
                        />
                        <ShieldAnimated />
                    </motion.div>
                    
                    <p className="dcs__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Резервирование процессорных модулей контролера`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div 
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <ControllerAnimated />
                        <p className="dcs__method-text pad__method-text--l">
                            <TextAnimated 
                                text={`Возможность горячей замены процессоров`} 
                                fontSize={32}
                            />
                        </p>
                    </motion.div>
                    <p className="dcs__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Резервирование модулей ввода-вывода`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <ControllerAnimated2 />
                        <p className="dcs__method-text pad__method-text--l">
                            <TextAnimated 
                                text={`Возможность горячей замены модулей ввода вывода`} 
                                fontSize={32}
                            />
                        </p>

                    </motion.div>

                    <p className="dcs__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Резервирование корзин контроллеров`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >

                        <ControllerAnimated3 />
                    </motion.div>
                    <p className="dcs__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Поканальная гальваническая развязка`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <motion.div className='pad__method-galvanic-wrapper'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                        >
                            <motion.img custom={1} variants={animateLeftMove} src='images/DCSpage/galvanic.svg'/>
                            <motion.div custom={1} variants={animateRightMove}>
                                <img className='reverse' src='images/DCSpage/galvanic.svg'/>
                            </motion.div>
                            
                        </motion.div>
                        
                    </motion.div>
                </div>
            </section>

            <motion.section 
                className="dcs__risk" 
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true, amount: 0.5 }} 
            >
                <motion.img
                    custom={2} variants={animateLeftMove}
                    src="images/svg-elements/small-round-element.svg"
                    className="svg-element DCS-page-round-element"
                    alt=""
                />
                <div className='container' >
                    <h2 className="dcs__risk-title">
                        <TextAnimated 
                            text={`Гибкие возможности масштабирования`} 
                            fontSize={48}
                        />
                    </h2>
                    <div className="dcs__risk-text dcs__risk-text--l">
                        <TextAnimated 
                            text={`Кроссплатформенная SCADA позволяет запускать один и тот же проект на операционных системах Windows, Linux и  QNX и использовать узлы с разными операционными системами в одном проекте`} 
                            fontSize={32}
                        />
                    </div>
                    
                    <DCSAnimated2 />
                </div>
            </motion.section>

            {/* <div className="pad__performance-video">
                <div className='container'>
                    <Gallery />
                </div>
            </div> */}
        </div>
    )
}

export default SdisolDcs;