import React, { useEffect, useRef } from 'react';
import lightGallery from 'lightgallery';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import './Gallery.scss'

const Gallery = ({pageType = "PAD"}) => {
    const galleryRef = useRef(null);
    const lightGalleryInstance = useRef(null);

    useEffect(() => {
        if (galleryRef.current && !lightGalleryInstance.current) {
            lightGalleryInstance.current = lightGallery(galleryRef.current, {
                thumbnail: true,
                zoom: true,
                selector: '.gallery-item',
            });
        }

        return () => {
            if (lightGalleryInstance.current) {
                lightGalleryInstance.current.destroy(true);
                lightGalleryInstance.current = null;
            }
        };
    }, []);

    const images = pageType === 'PAD' ? [
        { src: 'images/PADpage/gallery_1.png', thumb: 'images/PADpage/gallery_1.png', alt: 'Сертефикат' },
        { src: 'images/PADpage/gallery_2.png', thumb: 'images/PADpage/gallery_2.png', alt: 'Отзыв 1' },
        { src: 'images/PADpage/gallery_3.png', thumb: 'images/PADpage/gallery_3.png', alt: 'Отзыв 2' },
        { src: 'images/PADpage/gallery_4.png', thumb: 'images/PADpage/gallery_4.png', alt: 'Отзыв 3' }
    ] : [
        { src: 'images/EDpage/ed_certificate.png', thumb: 'images/EDpage/ed_certificate.png', alt: 'Сертефикат' },
    ];

    return (
        <div className="gallery-container" ref={galleryRef}>
            {images.map((image, index) => (
                <a href={image.src} className="gallery-item" key={index}>
                    <img src={image.thumb} alt={image.alt} />
                </a>
            ))}
        </div>
    );
};

export default Gallery;