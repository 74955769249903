import React from 'react';
import "../AnimText/css/AnimText.css";


const getOpacity = (text, n, progress) => {
  const len = text.length || 0;
  return progress > n /  (len  + 0.00001) ? 1 : 0.1;
};

const AnimText = ({text, progress}) =>text
  .split('')
  .map((char, i) =>
    <span style={{opacity: getOpacity(text, i, progress )}}>
      {char}
    </span>);




export default AnimText;