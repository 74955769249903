import React, { useEffect, useRef, useState } from 'react'
import { motion, useInView } from 'framer-motion';
import "./EdCalendarAnimated.scss"

const EdCalendarAnimated = () => {
    const [visibleRectIndex, setVisibleRectIndex] = useState(0);
    const containerRef1 = useRef(null);
    const isInView = useInView(containerRef1, { once: true, amount: 0.5 });

    const rectVariants = {
        hidden: { opacity: 0 },
        visible: (i) => ({
          opacity: 1,
          transition: {
            delay: i + 0.3,
            duration: 1
          }
        }),
        exit: { opacity: 0, transition: { duration: 1 } }
    };
    
    const rects = [
    { x: 60.5, y: 172.5, width: 43, height: 43 },
    { x: 112, y: 172.5, width: 43, height: 43 },
    { x: 163.5, y: 172.5, width: 43, height: 43 },
    { x: 214.5, y: 172.5, width: 43, height: 43 },
    { x: 60.5, y: 222, width: 43, height: 43 },
    { x: 112, y: 222, width: 43, height: 43 },
    { x: 163.5, y: 222, width: 43, height: 43 },
    { x: 214.5, y: 222, width: 43, height: 43 },
    { x: 60.5, y: 270.5, width: 43, height: 43 },
    
    ];

    useEffect(() => {
        if (isInView && visibleRectIndex < rects.length) {
            const timer = setTimeout(() => {
            setVisibleRectIndex((prevIndex) => prevIndex + 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [visibleRectIndex, isInView]);
    console.log(visibleRectIndex);
    console.log(isInView);

  return (
    <motion.svg ref={containerRef1} width="302" height="344" viewBox="0 0 302 344" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7769_1345)">
            <path d="M146.7 69.2H258.8C278.4 69.2 294.3 85.1 294.3 104.7V300.6C294.3 320.2 278.4 336.1 258.8 336.1H63.5996C43.9996 336.1 28.0996 320.2 28.0996 300.6V136.9" stroke="white" strokeWidth="14.0192" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M232.5 38.3V94.4001" stroke="white" strokeWidth="14.0192" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            
            <path d="M92.5992 208.2H71.4992C69.3992 208.2 67.6992 206.5 67.6992 204.4V183.3C67.6992 181.2 69.3992 179.5 71.4992 179.5H92.5992C94.6992 179.5 96.3992 181.2 96.3992 183.3V204.4C96.3992 206.6 94.6992 208.2 92.5992 208.2Z" fill="white"/>
            <path d="M144.099 208.2H122.999C120.899 208.2 119.199 206.5 119.199 204.4V183.3C119.199 181.2 120.899 179.5 122.999 179.5H144.099C146.199 179.5 147.899 181.2 147.899 183.3V204.4C147.799 206.6 146.099 208.2 144.099 208.2Z" fill="white"/>
            <path d="M195.5 208.2H174.4C172.3 208.2 170.6 206.5 170.6 204.4V183.3C170.6 181.2 172.3 179.5 174.4 179.5H195.5C197.6 179.5 199.3 181.2 199.3 183.3V204.4C199.2 206.6 197.5 208.2 195.5 208.2Z" fill="white"/>
            <path d="M246.9 208.2H225.8C223.7 208.2 222 206.5 222 204.4V183.3C222 181.2 223.7 179.5 225.8 179.5H246.9C249 179.5 250.7 181.2 250.7 183.3V204.4C250.6 206.6 248.9 208.2 246.9 208.2Z" fill="white"/>
            {/* <rect id="calendar_day" x="214.5" y="172.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="163.5" y="172.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="112" y="172.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="60.5" y="172.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/> */}
            
            <path d="M92.5992 306.8H71.4992C69.3992 306.8 67.6992 305.1 67.6992 303V281.9C67.6992 279.8 69.3992 278.1 71.4992 278.1H92.5992C94.6992 278.1 96.3992 279.8 96.3992 281.9V303C96.3992 305.1 94.6992 306.8 92.5992 306.8Z" fill="white"/>
            <path d="M144.099 306.8H122.999C120.899 306.8 119.199 305.1 119.199 303V281.9C119.199 279.8 120.899 278.1 122.999 278.1H144.099C146.199 278.1 147.899 279.8 147.899 281.9V303C147.799 305.1 146.099 306.8 144.099 306.8Z" fill="white"/>
            <path d="M195.5 306.8H174.4C172.3 306.8 170.6 305.1 170.6 303V281.9C170.6 279.8 172.3 278.1 174.4 278.1H195.5C197.6 278.1 199.3 279.8 199.3 281.9V303C199.2 305.1 197.5 306.8 195.5 306.8Z" fill="white"/>
            {/* <rect x="163.5" y="270.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="112" y="270.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="60.5" y="270.5" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/> */}
            
            <path d="M92.5992 257.5H71.4992C69.3992 257.5 67.6992 255.8 67.6992 253.7V232.6C67.6992 230.5 69.3992 228.8 71.4992 228.8H92.5992C94.6992 228.8 96.3992 230.5 96.3992 232.6V253.7C96.3992 255.8 94.6992 257.5 92.5992 257.5Z" fill="white"/>
            <path d="M144.099 257.5H122.999C120.899 257.5 119.199 255.8 119.199 253.7V232.6C119.199 230.5 120.899 228.8 122.999 228.8H144.099C146.199 228.8 147.899 230.5 147.899 232.6V253.7C147.799 255.8 146.099 257.5 144.099 257.5Z" fill="white"/>
            <path d="M195.5 257.5H174.4C172.3 257.5 170.6 255.8 170.6 253.7V232.6C170.6 230.5 172.3 228.8 174.4 228.8H195.5C197.6 228.8 199.3 230.5 199.3 232.6V253.7C199.2 255.8 197.5 257.5 195.5 257.5Z" fill="white"/>
            <path d="M246.9 257.5H225.8C223.7 257.5 222 255.8 222 253.7V232.6C222 230.5 223.7 228.8 225.8 228.8H246.9C249 228.8 250.7 230.5 250.7 232.6V253.7C250.6 255.8 248.9 257.5 246.9 257.5Z" fill="white"/>
            {/* <rect x="214.5" y="222" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="163.5" y="222" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="112" y="222" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/>
            <rect x="60.5" y="222" width="43" height="43" rx="2.5" stroke="#FF1405" strokeWidth="5"/> */}

            {rects.map((rect, i) => {
                // if (i !== rects.length - 1) {
                    return (
                        <motion.rect
                            key={i}
                            x={rect.x}
                            y={rect.y}
                            width={rect.width}
                            height={rect.height}
                            rx="2.5"
                            stroke="#FF1405"
                            strokeWidth="5"
                            initial={{ opacity: 0 }}
                            animate={{
                            opacity: isInView && visibleRectIndex === i
                                ? [0, 1]
                                : i < visibleRectIndex
                                ? 0
                                : i === rects.length - 1 && visibleRectIndex === rects.length - 1
                                ? 1
                                : 0
                            }}
                            transition={{ duration: 1 }}
                        />
                    )       
                // } else {
                //     return (
                //         <motion.rect
                //             key={i}
                //             x={rect.x}
                //             y={rect.y}
                //             width={rect.width}
                //             height={rect.height}
                //             rx="2.5"
                //             stroke="#FF1405"
                //             strokeWidth="5"
                //             custom={i}
                //             variants={rectVariants}
                //             initial="hidden"
                //             animate="visible"
                //             exit={isInView && i === rects.length - 1 ? "visible" : "exit"}
                //         />
                //     )   
                // }
            })}

            <motion.rect
                key={rects.length}
                x={112}
                y={270.5}
                width={43}
                height={43}
                rx="2.5"
                stroke="#FF1405"
                strokeWidth="5"
                custom={rects.length}
                variants={rectVariants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                exit={isInView && visibleRectIndex === rects.length ? "visible" : "exit"}
            />
            {/* { x: 112, y: 270.5, width: 43, height: 43 } */}
            
            <path d="M28.0996 152.9H294.4" stroke="white" strokeWidth="14.0192" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M66.0996 124.6C98.4083 124.6 124.6 98.4086 124.6 66.1C124.6 33.7913 98.4083 7.59998 66.0996 7.59998C33.791 7.59998 7.59961 33.7913 7.59961 66.1C7.59961 98.4086 33.791 124.6 66.0996 124.6Z" stroke="#00F0FF" strokeWidth="15.1538" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            {
                isInView && <path d="M66.0996 40.5V66.1L85.4996 87.1" id="clock-hand" stroke="#00F0FF" strokeWidth="15.1538" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            }
        </g>
        <defs>
            <clipPath id="clip0_7769_1345">
                <rect width="301.4" height="343.2" fill="white"/>
            </clipPath>
        </defs>
    </motion.svg>
  )
}



export default EdCalendarAnimated