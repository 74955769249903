import React, { useRef, useEffect, useState } from 'react';
import { motion, useInView } from 'framer-motion';

const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;

    return context.measureText(text).width;
}

const wrapText = (text, maxWidth, font) => {
    const words = text.split(' ');
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = getTextWidth(currentLine + " " + word, font);

        if (width < maxWidth) {
            currentLine += " " + word;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }

    lines.push(currentLine);

    return lines;
}

const textTransition = {
  visible: custom => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: 0.3,
    },
  }),
  hidden: { opacity: 0, y: 20 },
};

const TextAnimated = ({ text, fontSize, order = 1 }) => {
    const containerRef = useRef(null);
    const [lines, setLines] = useState([]);
    const isInView = useInView(containerRef, { once: true, amount: 0.5 });

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const maxWidth = container.clientWidth;
            const font = `${fontSize}px Geometria, "Gill Sans", sans-serif`;
            const wrappedLines = wrapText(text, maxWidth, font);
            setLines(wrappedLines);
        }
    }, [text, fontSize]);

    return (
        <div 
          ref={containerRef} 
          style={{ width: '100%'}}
        >
            {lines.map((line, index) => (
                <motion.div
                    key={index}
                    custom={index + order}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    variants={textTransition}
                >
                    {line}
                </motion.div>
            ))}
        </div>
    );
};

export default TextAnimated;