import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "./EdAnimatedLine.scss";
import { Autoplay } from 'swiper/modules';

const images = [
    {src: "images/EDpage/pipe_1.svg"},
    {src: "images/EDpage/pipe_2.svg"},
    {src: "images/EDpage/pipe_3.svg"},
    {src: "images/EDpage/pipe_4.svg"},
    {src: "images/EDpage/pipe_5.svg"},
    {src: "images/EDpage/pipe_6.svg"},
    {src: "images/EDpage/pipe_7.svg"},
]

const EdAnimatedLine = () => {
  return (
    <Swiper
      loop={true}
      slidesPerView="auto"
      spaceBetween={30}
      autoplay={{
          delay: 1000,
          disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      grabCursor={true}
      className="my-swiper"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index} className="swiper-slide">
          <img src={image.src} alt={`Slide ${index}`} className="slide-image" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default EdAnimatedLine;