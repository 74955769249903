import React from "react";
import "./../sass/main.scss";
import "../sass/base/fonts.scss";
import "./styles.css";
import { ParallaxProvider } from "react-scroll-parallax";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/NewStructure/HomePage";
// import SdisolED from "./pages/NewStructure/SdisolED";
import NewsPage from "./pages/NewStructure/NewsPage";
import Navigation from "./components/Navigation/Navigation";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SdisolPad from "./pages/SdisolPad/SdisolPad";
import SdisolDcs from "./pages/SdisolDcs/SdisolDcs";
import About from "./pages/About/About";
import SdisolEd from "./pages/SdisolEd/SdisolEd";

const App = () => {

  return (
    <ParallaxProvider>
        <ScrollToTop />
        <Navigation />
          <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/sdisol-ed" component={SdisolEd} />
              <Route exact path="/sdisol-pad" component={SdisolPad} />
              <Route exact path="/sdisol-dcs" component={SdisolDcs} />
              <Route exact path="/about-sdisol" component={About} />
              <Route exact path="/news" component={NewsPage} />
          </Switch>
        <Navigation footer={true}/>
    </ParallaxProvider>
  )
};
export default App;