import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const svgTransitionRight = {
    hidden: { opacity: 0, x: 0, y: 0 },
    visible: custom => ({
        opacity: 1,
        x: 200,
        y: -25,
        transition: {
            duration: 1,
            delay: custom * 0.4,
            ease: "easeInOut",
        }
    })
};

const svgTransitionLeft = {
    hidden: { opacity: 0, x: 0, y: 0 },
    visible: custom => ({
        opacity: 1,
        x: -200,
        y: 25,
        transition: {
            duration: 1,
            delay: custom * 0.4,
            ease: "easeInOut",
        }
    })
};

const opacityTransitionRepeat = {
    visible: custom => ({
      opacity: 1,
      transition: {
        delay: custom * 0.4,
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
      },
    }),
    hidden: { opacity: 0 },
};

  
const ControllerAnimated3 = () => {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.5 });

    return (
        <motion.div 
            ref={containerRef} 
            initial="hidden"
            animate={isInView ? "visible" : "hidden"} 
            style={{paddingTop: 50}}
            className='pad__method-grahp-wrapper'
        >
            <motion.svg style={{overflow: 'visible'}} custom={1} variants={svgTransitionRight} width="670" height="351" viewBox="0 0 670 351" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M288.229 5.64941H212.857V345.351H288.229V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M269.079 147.376C273.011 147.376 276.199 144.187 276.199 140.253C276.199 136.319 273.011 133.13 269.079 133.13C265.147 133.13 261.959 136.319 261.959 140.253C261.959 144.187 265.147 147.376 269.079 147.376Z" fill="white"/>
                <path d="M269.079 171.693C273.011 171.693 276.199 168.504 276.199 164.57C276.199 160.636 273.011 157.447 269.079 157.447C265.147 157.447 261.959 160.636 261.959 164.57C261.959 168.504 265.147 171.693 269.079 171.693Z" fill="white"/>
                <path d="M269.079 196.01C273.011 196.01 276.199 192.821 276.199 188.887C276.199 184.953 273.011 181.764 269.079 181.764C265.147 181.764 261.959 184.953 261.959 188.887C261.959 192.821 265.147 196.01 269.079 196.01Z" fill="white"/>
                <path d="M269.079 220.081C273.011 220.081 276.199 216.892 276.199 212.958C276.199 209.024 273.011 205.835 269.079 205.835C265.147 205.835 261.959 209.024 261.959 212.958C261.959 216.892 265.147 220.081 269.079 220.081Z" fill="white"/>
                <path d="M270.553 44.2127H229.307C227.834 44.2127 226.852 42.9846 226.852 41.7564V24.317C226.852 22.8432 228.079 21.8607 229.307 21.8607H270.553C272.026 21.8607 273.008 23.0888 273.008 24.317V41.7564C273.008 42.9846 271.78 44.2127 270.553 44.2127Z" fill="white"/>
                <path d="M363.11 5.64941H287.738V345.351H363.11V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M341.997 147.376C345.929 147.376 349.117 144.187 349.117 140.253C349.117 136.319 345.929 133.13 341.997 133.13C338.065 133.13 334.877 136.319 334.877 140.253C334.877 144.187 338.065 147.376 341.997 147.376Z" fill="white"/>
                <path d="M341.997 171.693C345.929 171.693 349.117 168.504 349.117 164.57C349.117 160.636 345.929 157.447 341.997 157.447C338.065 157.447 334.877 160.636 334.877 164.57C334.877 168.504 338.065 171.693 341.997 171.693Z" fill="white"/>
                <path d="M341.997 196.01C345.929 196.01 349.117 192.821 349.117 188.887C349.117 184.953 345.929 181.764 341.997 181.764C338.065 181.764 334.877 184.953 334.877 188.887C334.877 192.821 338.065 196.01 341.997 196.01Z" fill="white"/>
                <path d="M341.997 220.081C345.929 220.081 349.117 216.892 349.117 212.958C349.117 209.024 345.929 205.835 341.997 205.835C338.065 205.835 334.877 209.024 334.877 212.958C334.877 216.892 338.065 220.081 341.997 220.081Z" fill="white"/>
                <path d="M345.924 44.2127H304.678C303.205 44.2127 302.223 42.9846 302.223 41.7564V24.317C302.223 22.8432 303.45 21.8607 304.678 21.8607H345.924C347.397 21.8607 348.379 23.0888 348.379 24.317V41.7564C348.624 42.9846 347.397 44.2127 345.924 44.2127Z" fill="white"/>
                <path d="M437.991 5.64941H362.619V345.351H437.991V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M420.559 147.376C424.491 147.376 427.679 144.187 427.679 140.253C427.679 136.319 424.491 133.13 420.559 133.13C416.627 133.13 413.439 136.319 413.439 140.253C413.439 144.187 416.627 147.376 420.559 147.376Z" fill="white"/>
                <path d="M420.559 171.693C424.491 171.693 427.679 168.504 427.679 164.57C427.679 160.636 424.491 157.447 420.559 157.447C416.627 157.447 413.439 160.636 413.439 164.57C413.439 168.504 416.627 171.693 420.559 171.693Z" fill="white"/>
                <path d="M420.559 196.01C424.491 196.01 427.679 192.821 427.679 188.887C427.679 184.953 424.491 181.764 420.559 181.764C416.627 181.764 413.439 184.953 413.439 188.887C413.439 192.821 416.627 196.01 420.559 196.01Z" fill="white"/>
                <path d="M420.559 220.081C424.491 220.081 427.679 216.892 427.679 212.958C427.679 209.024 424.491 205.835 420.559 205.835C416.627 205.835 413.439 209.024 413.439 212.958C413.439 216.892 416.627 220.081 420.559 220.081Z" fill="white"/>
                <path d="M421.297 44.2127H380.051C378.578 44.2127 377.596 42.9846 377.596 41.7564V24.317C377.596 22.8432 378.823 21.8607 380.051 21.8607H421.297C422.77 21.8607 423.752 23.0888 423.752 24.317V41.7564C423.752 42.9846 422.77 44.2127 421.297 44.2127Z" fill="white"/>
                <path d="M513.364 5.64941H437.992V345.351H513.364V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M493.233 147.376C497.165 147.376 500.353 144.187 500.353 140.253C500.353 136.319 497.165 133.13 493.233 133.13C489.301 133.13 486.113 136.319 486.113 140.253C486.113 144.187 489.301 147.376 493.233 147.376Z" fill="white"/>
                <path d="M493.233 171.693C497.165 171.693 500.353 168.504 500.353 164.57C500.353 160.636 497.165 157.447 493.233 157.447C489.301 157.447 486.113 160.636 486.113 164.57C486.113 168.504 489.301 171.693 493.233 171.693Z" fill="white"/>
                <path d="M493.233 196.01C497.165 196.01 500.353 192.821 500.353 188.887C500.353 184.953 497.165 181.764 493.233 181.764C489.301 181.764 486.113 184.953 486.113 188.887C486.113 192.821 489.301 196.01 493.233 196.01Z" fill="white"/>
                <path d="M493.233 220.081C497.165 220.081 500.353 216.892 500.353 212.958C500.353 209.024 497.165 205.835 493.233 205.835C489.301 205.835 486.113 209.024 486.113 212.958C486.113 216.892 489.301 220.081 493.233 220.081Z" fill="white"/>
                <path d="M496.424 44.2127H455.178C453.705 44.2127 452.723 42.9846 452.723 41.7564V24.317C452.723 22.8432 453.95 21.8607 455.178 21.8607H496.424C497.897 21.8607 498.879 23.0888 498.879 24.317V41.7564C498.879 42.9846 497.897 44.2127 496.424 44.2127Z" fill="white"/>
                <path d="M588.981 5.64941H513.609V345.351H588.981V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M571.796 147.376C575.728 147.376 578.915 144.187 578.915 140.253C578.915 136.319 575.728 133.13 571.796 133.13C567.863 133.13 564.676 136.319 564.676 140.253C564.676 144.187 567.863 147.376 571.796 147.376Z" fill="white"/>
                <path d="M571.796 171.693C575.728 171.693 578.915 168.504 578.915 164.57C578.915 160.636 575.728 157.447 571.796 157.447C567.863 157.447 564.676 160.636 564.676 164.57C564.676 168.504 567.863 171.693 571.796 171.693Z" fill="white"/>
                <path d="M571.796 196.01C575.728 196.01 578.915 192.821 578.915 188.887C578.915 184.953 575.728 181.764 571.796 181.764C567.863 181.764 564.676 184.953 564.676 188.887C564.676 192.821 567.863 196.01 571.796 196.01Z" fill="white"/>
                <path d="M571.796 220.081C575.728 220.081 578.915 216.892 578.915 212.958C578.915 209.024 575.728 205.835 571.796 205.835C567.863 205.835 564.676 209.024 564.676 212.958C564.676 216.892 567.863 220.081 571.796 220.081Z" fill="white"/>
                <path d="M571.795 44.2127H530.549C529.076 44.2127 528.094 42.9846 528.094 41.7564V24.317C528.094 22.8432 529.321 21.8607 530.549 21.8607H571.795C573.268 21.8607 574.25 23.0888 574.25 24.317V41.7564C574.25 42.9846 573.268 44.2127 571.795 44.2127Z" fill="white"/>
                <path d="M664.353 5.64941H5.64648V345.351H664.353V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M664.352 5.64941H588.98V345.351H664.352V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M644.712 147.376C648.644 147.376 651.831 144.187 651.831 140.253C651.831 136.319 648.644 133.13 644.712 133.13C640.779 133.13 637.592 136.319 637.592 140.253C637.592 144.187 640.779 147.376 644.712 147.376Z" fill="white"/>
                <path d="M644.712 171.693C648.644 171.693 651.831 168.504 651.831 164.57C651.831 160.636 648.644 157.447 644.712 157.447C640.779 157.447 637.592 160.636 637.592 164.57C637.592 168.504 640.779 171.693 644.712 171.693Z" fill="white"/>
                <path d="M644.712 196.01C648.644 196.01 651.831 192.821 651.831 188.887C651.831 184.953 648.644 181.764 644.712 181.764C640.779 181.764 637.592 184.953 637.592 188.887C637.592 192.821 640.779 196.01 644.712 196.01Z" fill="white"/>
                <path d="M644.712 220.081C648.644 220.081 651.831 216.892 651.831 212.958C651.831 209.024 648.644 205.835 644.712 205.835C640.779 205.835 637.592 209.024 637.592 212.958C637.592 216.892 640.779 220.081 644.712 220.081Z" fill="white"/>
                <path d="M647.166 44.2127H605.92C604.447 44.2127 603.465 42.9846 603.465 41.7564V24.317C603.465 22.8432 604.692 21.8607 605.92 21.8607H647.166C648.639 21.8607 649.621 23.0888 649.621 24.317V41.7564C649.621 42.9846 648.639 44.2127 647.166 44.2127Z" fill="white"/>
                <path d="M211.876 5.64941H108.762V345.351H211.876V5.64941Z" stroke="white" strokeWidth="3.2293" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M140.923 198.957H128.156V230.889H140.923V198.957Z" fill="white"/>
                <path d="M140.923 245.135H128.156V277.067H140.923V245.135Z" fill="white"/>
                <path d="M140.923 291.067H128.156V322.999H140.923V291.067Z" fill="white"/>
                <path d="M108.762 5.64941V120.848H212.122V5.64941H108.762ZM188.062 34.6333C184.134 34.6333 180.942 31.4402 180.942 27.5102C180.942 23.5801 184.134 20.387 188.062 20.387C191.99 20.387 195.182 23.5801 195.182 27.5102C195.182 31.4402 191.99 34.6333 188.062 34.6333Z" fill="white"/>
                <path d="M108.761 5.64941H5.64648V345.351H108.761V5.64941Z" stroke="white" strokeWidth="3.2293" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M37.8076 198.957H25.041V230.889H37.8076V198.957Z" fill="white"/>
                <path d="M37.8076 245.135H25.041V277.066H37.8076V245.135Z" fill="white"/>
                <path d="M37.8076 291.067H25.041V322.999H37.8076V291.067Z" fill="white"/>
                <path d="M5.64648 5.64941V120.848H109.007V5.64941H5.64648ZM84.9466 34.6333C81.0184 34.6333 77.8268 31.4402 77.8268 27.5102C77.8268 23.5801 81.0184 20.387 84.9466 20.387C88.8748 20.387 92.0664 23.5801 92.0664 27.5102C92.0664 31.4402 88.8748 34.6333 84.9466 34.6333Z" fill="white"/>
                <motion.rect custom={4} variants={opacityTransitionRepeat} x="8" y="8" width="655" height="336" stroke="#FF1405" strokeWidth="15"/>
            </motion.svg>
            <motion.svg style={{overflow: 'visible', background: 'linear-gradient(90deg, rgb(15 179 229) 0%, rgb(7 193 212) 100%)'}} custom={1} variants={svgTransitionLeft} width="670" height="351" viewBox="0 0 670 351" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M288.229 5.64941H212.857V345.351H288.229V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M269.079 147.376C273.011 147.376 276.199 144.187 276.199 140.253C276.199 136.319 273.011 133.13 269.079 133.13C265.147 133.13 261.959 136.319 261.959 140.253C261.959 144.187 265.147 147.376 269.079 147.376Z" fill="white"/>
                <path d="M269.079 171.693C273.011 171.693 276.199 168.504 276.199 164.57C276.199 160.636 273.011 157.447 269.079 157.447C265.147 157.447 261.959 160.636 261.959 164.57C261.959 168.504 265.147 171.693 269.079 171.693Z" fill="white"/>
                <path d="M269.079 196.01C273.011 196.01 276.199 192.821 276.199 188.887C276.199 184.953 273.011 181.764 269.079 181.764C265.147 181.764 261.959 184.953 261.959 188.887C261.959 192.821 265.147 196.01 269.079 196.01Z" fill="white"/>
                <path d="M269.079 220.081C273.011 220.081 276.199 216.892 276.199 212.958C276.199 209.024 273.011 205.835 269.079 205.835C265.147 205.835 261.959 209.024 261.959 212.958C261.959 216.892 265.147 220.081 269.079 220.081Z" fill="white"/>
                <path d="M270.553 44.2127H229.307C227.834 44.2127 226.852 42.9846 226.852 41.7564V24.317C226.852 22.8432 228.079 21.8607 229.307 21.8607H270.553C272.026 21.8607 273.008 23.0888 273.008 24.317V41.7564C273.008 42.9846 271.78 44.2127 270.553 44.2127Z" fill="white"/>
                <path d="M363.11 5.64941H287.738V345.351H363.11V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M341.997 147.376C345.929 147.376 349.117 144.187 349.117 140.253C349.117 136.319 345.929 133.13 341.997 133.13C338.065 133.13 334.877 136.319 334.877 140.253C334.877 144.187 338.065 147.376 341.997 147.376Z" fill="white"/>
                <path d="M341.997 171.693C345.929 171.693 349.117 168.504 349.117 164.57C349.117 160.636 345.929 157.447 341.997 157.447C338.065 157.447 334.877 160.636 334.877 164.57C334.877 168.504 338.065 171.693 341.997 171.693Z" fill="white"/>
                <path d="M341.997 196.01C345.929 196.01 349.117 192.821 349.117 188.887C349.117 184.953 345.929 181.764 341.997 181.764C338.065 181.764 334.877 184.953 334.877 188.887C334.877 192.821 338.065 196.01 341.997 196.01Z" fill="white"/>
                <path d="M341.997 220.081C345.929 220.081 349.117 216.892 349.117 212.958C349.117 209.024 345.929 205.835 341.997 205.835C338.065 205.835 334.877 209.024 334.877 212.958C334.877 216.892 338.065 220.081 341.997 220.081Z" fill="white"/>
                <path d="M345.924 44.2127H304.678C303.205 44.2127 302.223 42.9846 302.223 41.7564V24.317C302.223 22.8432 303.45 21.8607 304.678 21.8607H345.924C347.397 21.8607 348.379 23.0888 348.379 24.317V41.7564C348.624 42.9846 347.397 44.2127 345.924 44.2127Z" fill="white"/>
                <path d="M437.991 5.64941H362.619V345.351H437.991V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M420.559 147.376C424.491 147.376 427.679 144.187 427.679 140.253C427.679 136.319 424.491 133.13 420.559 133.13C416.627 133.13 413.439 136.319 413.439 140.253C413.439 144.187 416.627 147.376 420.559 147.376Z" fill="white"/>
                <path d="M420.559 171.693C424.491 171.693 427.679 168.504 427.679 164.57C427.679 160.636 424.491 157.447 420.559 157.447C416.627 157.447 413.439 160.636 413.439 164.57C413.439 168.504 416.627 171.693 420.559 171.693Z" fill="white"/>
                <path d="M420.559 196.01C424.491 196.01 427.679 192.821 427.679 188.887C427.679 184.953 424.491 181.764 420.559 181.764C416.627 181.764 413.439 184.953 413.439 188.887C413.439 192.821 416.627 196.01 420.559 196.01Z" fill="white"/>
                <path d="M420.559 220.081C424.491 220.081 427.679 216.892 427.679 212.958C427.679 209.024 424.491 205.835 420.559 205.835C416.627 205.835 413.439 209.024 413.439 212.958C413.439 216.892 416.627 220.081 420.559 220.081Z" fill="white"/>
                <path d="M421.297 44.2127H380.051C378.578 44.2127 377.596 42.9846 377.596 41.7564V24.317C377.596 22.8432 378.823 21.8607 380.051 21.8607H421.297C422.77 21.8607 423.752 23.0888 423.752 24.317V41.7564C423.752 42.9846 422.77 44.2127 421.297 44.2127Z" fill="white"/>
                <path d="M513.364 5.64941H437.992V345.351H513.364V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M493.233 147.376C497.165 147.376 500.353 144.187 500.353 140.253C500.353 136.319 497.165 133.13 493.233 133.13C489.301 133.13 486.113 136.319 486.113 140.253C486.113 144.187 489.301 147.376 493.233 147.376Z" fill="white"/>
                <path d="M493.233 171.693C497.165 171.693 500.353 168.504 500.353 164.57C500.353 160.636 497.165 157.447 493.233 157.447C489.301 157.447 486.113 160.636 486.113 164.57C486.113 168.504 489.301 171.693 493.233 171.693Z" fill="white"/>
                <path d="M493.233 196.01C497.165 196.01 500.353 192.821 500.353 188.887C500.353 184.953 497.165 181.764 493.233 181.764C489.301 181.764 486.113 184.953 486.113 188.887C486.113 192.821 489.301 196.01 493.233 196.01Z" fill="white"/>
                <path d="M493.233 220.081C497.165 220.081 500.353 216.892 500.353 212.958C500.353 209.024 497.165 205.835 493.233 205.835C489.301 205.835 486.113 209.024 486.113 212.958C486.113 216.892 489.301 220.081 493.233 220.081Z" fill="white"/>
                <path d="M496.424 44.2127H455.178C453.705 44.2127 452.723 42.9846 452.723 41.7564V24.317C452.723 22.8432 453.95 21.8607 455.178 21.8607H496.424C497.897 21.8607 498.879 23.0888 498.879 24.317V41.7564C498.879 42.9846 497.897 44.2127 496.424 44.2127Z" fill="white"/>
                <path d="M588.981 5.64941H513.609V345.351H588.981V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M571.796 147.376C575.728 147.376 578.915 144.187 578.915 140.253C578.915 136.319 575.728 133.13 571.796 133.13C567.863 133.13 564.676 136.319 564.676 140.253C564.676 144.187 567.863 147.376 571.796 147.376Z" fill="white"/>
                <path d="M571.796 171.693C575.728 171.693 578.915 168.504 578.915 164.57C578.915 160.636 575.728 157.447 571.796 157.447C567.863 157.447 564.676 160.636 564.676 164.57C564.676 168.504 567.863 171.693 571.796 171.693Z" fill="white"/>
                <path d="M571.796 196.01C575.728 196.01 578.915 192.821 578.915 188.887C578.915 184.953 575.728 181.764 571.796 181.764C567.863 181.764 564.676 184.953 564.676 188.887C564.676 192.821 567.863 196.01 571.796 196.01Z" fill="white"/>
                <path d="M571.796 220.081C575.728 220.081 578.915 216.892 578.915 212.958C578.915 209.024 575.728 205.835 571.796 205.835C567.863 205.835 564.676 209.024 564.676 212.958C564.676 216.892 567.863 220.081 571.796 220.081Z" fill="white"/>
                <path d="M571.795 44.2127H530.549C529.076 44.2127 528.094 42.9846 528.094 41.7564V24.317C528.094 22.8432 529.321 21.8607 530.549 21.8607H571.795C573.268 21.8607 574.25 23.0888 574.25 24.317V41.7564C574.25 42.9846 573.268 44.2127 571.795 44.2127Z" fill="white"/>
                <path d="M664.353 5.64941H5.64648V345.351H664.353V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M664.352 5.64941H588.98V345.351H664.352V5.64941Z" stroke="white" strokeWidth="4.5669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M644.712 147.376C648.644 147.376 651.831 144.187 651.831 140.253C651.831 136.319 648.644 133.13 644.712 133.13C640.779 133.13 637.592 136.319 637.592 140.253C637.592 144.187 640.779 147.376 644.712 147.376Z" fill="white"/>
                <path d="M644.712 171.693C648.644 171.693 651.831 168.504 651.831 164.57C651.831 160.636 648.644 157.447 644.712 157.447C640.779 157.447 637.592 160.636 637.592 164.57C637.592 168.504 640.779 171.693 644.712 171.693Z" fill="white"/>
                <path d="M644.712 196.01C648.644 196.01 651.831 192.821 651.831 188.887C651.831 184.953 648.644 181.764 644.712 181.764C640.779 181.764 637.592 184.953 637.592 188.887C637.592 192.821 640.779 196.01 644.712 196.01Z" fill="white"/>
                <path d="M644.712 220.081C648.644 220.081 651.831 216.892 651.831 212.958C651.831 209.024 648.644 205.835 644.712 205.835C640.779 205.835 637.592 209.024 637.592 212.958C637.592 216.892 640.779 220.081 644.712 220.081Z" fill="white"/>
                <path d="M647.166 44.2127H605.92C604.447 44.2127 603.465 42.9846 603.465 41.7564V24.317C603.465 22.8432 604.692 21.8607 605.92 21.8607H647.166C648.639 21.8607 649.621 23.0888 649.621 24.317V41.7564C649.621 42.9846 648.639 44.2127 647.166 44.2127Z" fill="white"/>
                <path d="M211.876 5.64941H108.762V345.351H211.876V5.64941Z" stroke="white" strokeWidth="3.2293" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M140.923 198.957H128.156V230.889H140.923V198.957Z" fill="white"/>
                <path d="M140.923 245.135H128.156V277.067H140.923V245.135Z" fill="white"/>
                <path d="M140.923 291.067H128.156V322.999H140.923V291.067Z" fill="white"/>
                <path d="M108.762 5.64941V120.848H212.122V5.64941H108.762ZM188.062 34.6333C184.134 34.6333 180.942 31.4402 180.942 27.5102C180.942 23.5801 184.134 20.387 188.062 20.387C191.99 20.387 195.182 23.5801 195.182 27.5102C195.182 31.4402 191.99 34.6333 188.062 34.6333Z" fill="white"/>
                <path d="M108.761 5.64941H5.64648V345.351H108.761V5.64941Z" stroke="white" strokeWidth="3.2293" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M37.8076 198.957H25.041V230.889H37.8076V198.957Z" fill="white"/>
                <path d="M37.8076 245.135H25.041V277.066H37.8076V245.135Z" fill="white"/>
                <path d="M37.8076 291.067H25.041V322.999H37.8076V291.067Z" fill="white"/>
                <path d="M5.64648 5.64941V120.848H109.007V5.64941H5.64648ZM84.9466 34.6333C81.0184 34.6333 77.8268 31.4402 77.8268 27.5102C77.8268 23.5801 81.0184 20.387 84.9466 20.387C88.8748 20.387 92.0664 23.5801 92.0664 27.5102C92.0664 31.4402 88.8748 34.6333 84.9466 34.6333Z" fill="white"/>
                <motion.rect custom={5} variants={opacityTransitionRepeat} x="8" y="8" width="655" height="336" stroke="#FF1405" strokeWidth="15"/>
            </motion.svg>
            
        </motion.div>
        
    );
};

export default ControllerAnimated3;


