import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const pathVariants = {
    hidden: {
      pathLength: 0,
      opacity: 0,
    },
    visible: custom => ({
        pathLength: 1,
        opacity: 1,
        transition: {
            delay: custom * 0.6,
            duration: 1,
            ease: "easeInOut",
        }
    })
};


const DCSAnimated = () => {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.5 });

    return (
        <motion.svg ref={containerRef} 
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}  width="838" height="313" viewBox="0 0 838 313" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7536_27009)">
            {/* <!-- Линии --> */}
            <motion.path variants={pathVariants} custom={2} d="M39.6065 210.032H31.4395" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M39.6065 253.135H31.4395" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M30.7988 269.472V171.932" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M131.419 210.032H123.252" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M131.419 253.135H123.252" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M131.419 296.238H123.252" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M122.611 312.361V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M222.698 210.032H214.531" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M222.698 253.135H214.531" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M213.891 269.472V171.932" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M1.06836 171.932H836.88" stroke="#00F0FF" strokeWidth="4.0127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M1.06836 79.3409H836.88" stroke="#00F0FF" strokeWidth="4.0127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M113.912 151.87V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M178.5 151.87V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M676.262 59.2795V79.3409" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M113.912 79.3409V94.2406" stroke="#00F0FF" strokeWidth="2.0678" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M178.5 79.3409V94.2406" stroke="#00F0FF" strokeWidth="2.0678" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M414.17 59.2795V79.3409" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M568.595 210.032H560.428" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M568.595 253.135H560.428" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M568.595 296.238H560.428" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M559.787 312.361V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M660.407 210.032H652.24" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M660.407 253.135H652.24" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M660.407 296.238H652.24" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M651.6 312.361V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M751.687 210.032H743.52" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M751.687 253.135H743.52" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M742.879 269.472V171.932" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M642.898 151.87V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M707.488 151.87V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M642.898 79.3409V94.2406" stroke="#00F0FF" strokeWidth="2.0678" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M707.488 79.3409V94.2406" stroke="#00F0FF" strokeWidth="2.0678" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M350.808 210.032H342.641" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M350.808 253.135H342.641" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M350.808 296.238H342.641" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M342 312.361V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M442.087 210.032H433.92" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M442.087 253.135H433.92" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M433.279 269.472V171.932" stroke="#00F0FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M381.342 151.87V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M445.93 151.87V171.932" stroke="#00F0FF" strokeWidth="2.3997" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M381.342 79.3409V94.2406" stroke="#00F0FF" strokeWidth="2.0678" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={2} d="M445.93 79.3409V94.2406" stroke="#00F0FF" strokeWidth="2.0678" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
 
            {/* <!-- Верхняя часть --> */}
            <motion.path variants={pathVariants} custom={4} d="M707.008 1.48997H647.063C645.782 1.48997 644.715 2.55423 644.715 3.83135V45.6569C644.715 46.934 645.782 47.9983 647.063 47.9983H707.008C708.289 47.9983 709.357 46.934 709.357 45.6569V3.83135C709.357 2.55423 708.289 1.48997 707.008 1.48997Z" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M658.061 59.2795H695.373" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M664.732 59.2795L670.711 47.9451" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M683.682 47.9451L689.713 59.2795" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M444.915 1.48997H384.97C383.689 1.48997 382.621 2.55423 382.621 3.83135V45.6569C382.621 46.934 383.689 47.9983 384.97 47.9983H444.915C446.196 47.9983 447.263 46.934 447.263 45.6569V3.83135C447.263 2.55423 446.196 1.48997 444.915 1.48997Z" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M395.967 59.2795H433.279" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M402.639 59.2795L408.617 47.9451" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>
            <motion.path variants={pathVariants} custom={4} d="M421.588 47.9451L427.62 59.2795" stroke="white" strokeWidth="5.6146" strokeMiterlimit="10" strokeLinecap="round"/>

            {/* <!-- Средняя часть --> */}
            <motion.path variants={pathVariants} custom={3} d="M467.602 107.171H424.258C423.618 107.171 423.084 106.639 423.084 106.001V95.4113C423.084 94.7727 423.618 94.2406 424.258 94.2406H467.602C468.243 94.2406 468.777 94.7727 468.777 95.4113V106.001C468.777 106.639 468.243 107.171 467.602 107.171Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M427.568 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M464.293 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M427.781 100.679H442.033" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M451.375 102.01C452.113 102.01 452.71 101.414 452.71 100.679C452.71 99.9446 452.113 99.349 451.375 99.349C450.638 99.349 450.041 99.9446 450.041 100.679C450.041 101.414 450.638 102.01 451.375 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M456.979 102.01C457.716 102.01 458.313 101.414 458.313 100.679C458.313 99.9446 457.716 99.349 456.979 99.349C456.242 99.349 455.645 99.9446 455.645 100.679C455.645 101.414 456.242 102.01 456.979 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M462.745 102.01C463.482 102.01 464.079 101.414 464.079 100.679C464.079 99.9446 463.482 99.349 462.745 99.349C462.008 99.349 461.41 99.9446 461.41 100.679C461.41 101.414 462.008 102.01 462.745 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M467.602 126.754H424.258C423.618 126.754 423.084 126.222 423.084 125.583V114.994C423.084 114.355 423.618 113.823 424.258 113.823H467.602C468.243 113.823 468.777 114.355 468.777 114.994V125.583C468.777 126.222 468.243 126.754 467.602 126.754Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M427.568 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M464.293 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M427.781 120.262H442.033" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M451.375 121.592C452.113 121.592 452.71 120.997 452.71 120.262C452.71 119.527 452.113 118.931 451.375 118.931C450.638 118.931 450.041 119.527 450.041 120.262C450.041 120.997 450.638 121.592 451.375 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M456.979 121.592C457.716 121.592 458.313 120.997 458.313 120.262C458.313 119.527 457.716 118.931 456.979 118.931C456.242 118.931 455.645 119.527 455.645 120.262C455.645 120.997 456.242 121.592 456.979 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M462.745 121.592C463.482 121.592 464.079 120.997 464.079 120.262C464.079 119.527 463.482 118.931 462.745 118.931C462.008 118.931 461.41 119.527 461.41 120.262C461.41 120.997 462.008 121.592 462.745 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M467.602 146.389H424.258C423.618 146.389 423.084 145.857 423.084 145.219V134.629C423.084 133.991 423.618 133.459 424.258 133.459H467.602C468.243 133.459 468.777 133.991 468.777 134.629V145.219C468.777 145.857 468.243 146.389 467.602 146.389Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M427.781 139.897H442.033" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M451.375 141.228C452.113 141.228 452.71 140.632 452.71 139.897C452.71 139.163 452.113 138.567 451.375 138.567C450.638 138.567 450.041 139.163 450.041 139.897C450.041 140.632 450.638 141.228 451.375 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M456.979 141.228C457.716 141.228 458.313 140.632 458.313 139.897C458.313 139.163 457.716 138.567 456.979 138.567C456.242 138.567 455.645 139.163 455.645 139.897C455.645 140.632 456.242 141.228 456.979 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M462.745 141.228C463.482 141.228 464.079 140.632 464.079 139.897C464.079 139.163 463.482 138.567 462.745 138.567C462.008 138.567 461.41 139.163 461.41 139.897C461.41 140.632 462.008 141.228 462.745 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M427.889 146.389V151.87H464.026V146.389" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>



            <motion.path variants={pathVariants} custom={3} d="M403.012 107.171H359.668C359.028 107.171 358.494 106.639 358.494 106.001V95.4113C358.494 94.7727 359.028 94.2406 359.668 94.2406H403.012C403.653 94.2406 404.187 94.7727 404.187 95.4113V106.001C404.187 106.639 403.653 107.171 403.012 107.171Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M362.979 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M399.703 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M363.191 100.679H377.444" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M386.786 102.01C387.523 102.01 388.12 101.414 388.12 100.679C388.12 99.9446 387.523 99.349 386.786 99.349C386.049 99.349 385.451 99.9446 385.451 100.679C385.451 101.414 386.049 102.01 386.786 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M392.389 102.01C393.126 102.01 393.724 101.414 393.724 100.679C393.724 99.9446 393.126 99.349 392.389 99.349C391.652 99.349 391.055 99.9446 391.055 100.679C391.055 101.414 391.652 102.01 392.389 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M398.155 102.01C398.892 102.01 399.489 101.414 399.489 100.679C399.489 99.9446 398.892 99.349 398.155 99.349C397.418 99.349 396.82 99.9446 396.82 100.679C396.82 101.414 397.418 102.01 398.155 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M403.012 126.754H359.668C359.028 126.754 358.494 126.222 358.494 125.583V114.994C358.494 114.355 359.028 113.823 359.668 113.823H403.012C403.653 113.823 404.187 114.355 404.187 114.994V125.583C404.187 126.222 403.653 126.754 403.012 126.754Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M362.979 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M399.703 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M363.191 120.262H377.444" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M386.786 121.592C387.523 121.592 388.12 120.997 388.12 120.262C388.12 119.527 387.523 118.931 386.786 118.931C386.049 118.931 385.451 119.527 385.451 120.262C385.451 120.997 386.049 121.592 386.786 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M392.389 121.592C393.126 121.592 393.724 120.997 393.724 120.262C393.724 119.527 393.126 118.931 392.389 118.931C391.652 118.931 391.055 119.527 391.055 120.262C391.055 120.997 391.652 121.592 392.389 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M398.155 121.592C398.892 121.592 399.489 120.997 399.489 120.262C399.489 119.527 398.892 118.931 398.155 118.931C397.418 118.931 396.82 119.527 396.82 120.262C396.82 120.997 397.418 121.592 398.155 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M403.012 146.389H359.668C359.028 146.389 358.494 145.857 358.494 145.219V134.629C358.494 133.991 359.028 133.459 359.668 133.459H403.012C403.653 133.459 404.187 133.991 404.187 134.629V145.219C404.187 145.857 403.653 146.389 403.012 146.389Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M363.191 139.897H377.444" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M386.786 141.228C387.523 141.228 388.12 140.632 388.12 139.897C388.12 139.163 387.523 138.567 386.786 138.567C386.049 138.567 385.451 139.163 385.451 139.897C385.451 140.632 386.049 141.228 386.786 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M392.389 141.228C393.126 141.228 393.724 140.632 393.724 139.897C393.724 139.163 393.126 138.567 392.389 138.567C391.652 138.567 391.055 139.163 391.055 139.897C391.055 140.632 391.652 141.228 392.389 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M398.155 141.228C398.892 141.228 399.489 140.632 399.489 139.897C399.489 139.163 398.892 138.567 398.155 138.567C397.418 138.567 396.82 139.163 396.82 139.897C396.82 140.632 397.418 141.228 398.155 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M363.299 146.389V151.87H399.437V146.389" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>


            <motion.path variants={pathVariants} custom={3} d="M135.583 107.171H92.2388C91.5982 107.171 91.0645 106.639 91.0645 106.001V95.4113C91.0645 94.7727 91.5982 94.2406 92.2388 94.2406H135.583C136.223 94.2406 136.757 94.7727 136.757 95.4113V106.001C136.757 106.639 136.223 107.171 135.583 107.171Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M95.5488 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M132.273 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M95.7617 100.679H110.014" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M119.356 102.01C120.093 102.01 120.69 101.414 120.69 100.679C120.69 99.9446 120.093 99.349 119.356 99.349C118.619 99.349 118.021 99.9446 118.021 100.679C118.021 101.414 118.619 102.01 119.356 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M124.959 102.01C125.696 102.01 126.294 101.414 126.294 100.679C126.294 99.9446 125.696 99.349 124.959 99.349C124.222 99.349 123.625 99.9446 123.625 100.679C123.625 101.414 124.222 102.01 124.959 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M130.725 102.01C131.462 102.01 132.06 101.414 132.06 100.679C132.06 99.9446 131.462 99.349 130.725 99.349C129.988 99.349 129.391 99.9446 129.391 100.679C129.391 101.414 129.988 102.01 130.725 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M135.583 126.754H92.2388C91.5982 126.754 91.0645 126.222 91.0645 125.583V114.994C91.0645 114.355 91.5982 113.823 92.2388 113.823H135.583C136.223 113.823 136.757 114.355 136.757 114.994V125.583C136.757 126.222 136.223 126.754 135.583 126.754Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M95.5488 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M132.273 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M95.7617 120.262H110.014" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M119.356 121.592C120.093 121.592 120.69 120.997 120.69 120.262C120.69 119.527 120.093 118.931 119.356 118.931C118.619 118.931 118.021 119.527 118.021 120.262C118.021 120.997 118.619 121.592 119.356 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M124.959 121.592C125.696 121.592 126.294 120.997 126.294 120.262C126.294 119.527 125.696 118.931 124.959 118.931C124.222 118.931 123.625 119.527 123.625 120.262C123.625 120.997 124.222 121.592 124.959 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M130.725 121.592C131.462 121.592 132.06 120.997 132.06 120.262C132.06 119.527 131.462 118.931 130.725 118.931C129.988 118.931 129.391 119.527 129.391 120.262C129.391 120.997 129.988 121.592 130.725 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M135.583 146.389H92.2388C91.5982 146.389 91.0645 145.857 91.0645 145.219V134.629C91.0645 133.991 91.5982 133.459 92.2388 133.459H135.583C136.223 133.459 136.757 133.991 136.757 134.629V145.219C136.757 145.857 136.223 146.389 135.583 146.389Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M95.7617 139.897H110.014" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M119.356 141.228C120.093 141.228 120.69 140.632 120.69 139.897C120.69 139.163 120.093 138.567 119.356 138.567C118.619 138.567 118.021 139.163 118.021 139.897C118.021 140.632 118.619 141.228 119.356 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M124.959 141.228C125.696 141.228 126.294 140.632 126.294 139.897C126.294 139.163 125.696 138.567 124.959 138.567C124.222 138.567 123.625 139.163 123.625 139.897C123.625 140.632 124.222 141.228 124.959 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M130.725 141.228C131.462 141.228 132.06 140.632 132.06 139.897C132.06 139.163 131.462 138.567 130.725 138.567C129.988 138.567 129.391 139.163 129.391 139.897C129.391 140.632 129.988 141.228 130.725 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M95.8691 146.389V151.87H132.007V146.389" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M200.173 107.171H156.829C156.188 107.171 155.654 106.639 155.654 106.001V95.4113C155.654 94.7727 156.188 94.2406 156.829 94.2406H200.173C200.813 94.2406 201.347 94.7727 201.347 95.4113V106.001C201.347 106.639 200.813 107.171 200.173 107.171Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M160.139 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M196.863 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M160.352 100.679H174.604" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M183.946 102.01C184.683 102.01 185.28 101.414 185.28 100.679C185.28 99.9446 184.683 99.349 183.946 99.349C183.209 99.349 182.611 99.9446 182.611 100.679C182.611 101.414 183.209 102.01 183.946 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M189.549 102.01C190.286 102.01 190.884 101.414 190.884 100.679C190.884 99.9446 190.286 99.349 189.549 99.349C188.812 99.349 188.215 99.9446 188.215 100.679C188.215 101.414 188.812 102.01 189.549 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M195.315 102.01C196.052 102.01 196.649 101.414 196.649 100.679C196.649 99.9446 196.052 99.349 195.315 99.349C194.578 99.349 193.98 99.9446 193.98 100.679C193.98 101.414 194.578 102.01 195.315 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M200.173 126.754H156.829C156.188 126.754 155.654 126.222 155.654 125.583V114.994C155.654 114.355 156.188 113.823 156.829 113.823H200.173C200.813 113.823 201.347 114.355 201.347 114.994V125.583C201.347 126.222 200.813 126.754 200.173 126.754Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M160.139 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M196.863 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M160.352 120.262H174.604" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M183.946 121.592C184.683 121.592 185.28 120.997 185.28 120.262C185.28 119.527 184.683 118.931 183.946 118.931C183.209 118.931 182.611 119.527 182.611 120.262C182.611 120.997 183.209 121.592 183.946 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M189.549 121.592C190.286 121.592 190.884 120.997 190.884 120.262C190.884 119.527 190.286 118.931 189.549 118.931C188.812 118.931 188.215 119.527 188.215 120.262C188.215 120.997 188.812 121.592 189.549 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M195.315 121.592C196.052 121.592 196.649 120.997 196.649 120.262C196.649 119.527 196.052 118.931 195.315 118.931C194.578 118.931 193.98 119.527 193.98 120.262C193.98 120.997 194.578 121.592 195.315 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M200.173 146.389H156.829C156.188 146.389 155.654 145.857 155.654 145.219V134.629C155.654 133.991 156.188 133.459 156.829 133.459H200.173C200.813 133.459 201.347 133.991 201.347 134.629V145.219C201.347 145.857 200.813 146.389 200.173 146.389Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M160.352 139.897H174.604" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M183.946 141.228C184.683 141.228 185.28 140.632 185.28 139.897C185.28 139.163 184.683 138.567 183.946 138.567C183.209 138.567 182.611 139.163 182.611 139.897C182.611 140.632 183.209 141.228 183.946 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M189.549 141.228C190.286 141.228 190.884 140.632 190.884 139.897C190.884 139.163 190.286 138.567 189.549 138.567C188.812 138.567 188.215 139.163 188.215 139.897C188.215 140.632 188.812 141.228 189.549 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M195.315 141.228C196.052 141.228 196.649 140.632 196.649 139.897C196.649 139.163 196.052 138.567 195.315 138.567C194.578 138.567 193.98 139.163 193.98 139.897C193.98 140.632 194.578 141.228 195.315 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M160.459 146.389V151.87H196.597V146.389" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>



            <motion.path variants={pathVariants} custom={3} d="M664.571 107.171H621.227C620.587 107.171 620.053 106.639 620.053 106.001V95.4113C620.053 94.7727 620.587 94.2406 621.227 94.2406H664.571C665.212 94.2406 665.745 94.7727 665.745 95.4113V106.001C665.745 106.639 665.212 107.171 664.571 107.171Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M624.537 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M661.262 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M624.75 100.679H639.002" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M648.344 102.01C649.081 102.01 649.679 101.414 649.679 100.679C649.679 99.9446 649.081 99.349 648.344 99.349C647.607 99.349 647.01 99.9446 647.01 100.679C647.01 101.414 647.607 102.01 648.344 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M653.948 102.01C654.685 102.01 655.282 101.414 655.282 100.679C655.282 99.9446 654.685 99.349 653.948 99.349C653.211 99.349 652.613 99.9446 652.613 100.679C652.613 101.414 653.211 102.01 653.948 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M659.713 102.01C660.45 102.01 661.048 101.414 661.048 100.679C661.048 99.9446 660.45 99.349 659.713 99.349C658.976 99.349 658.379 99.9446 658.379 100.679C658.379 101.414 658.976 102.01 659.713 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M664.571 126.754H621.227C620.587 126.754 620.053 126.222 620.053 125.583V114.994C620.053 114.355 620.587 113.823 621.227 113.823H664.571C665.212 113.823 665.745 114.355 665.745 114.994V125.583C665.745 126.222 665.212 126.754 664.571 126.754Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M624.537 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M661.262 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M624.75 120.262H639.002" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M648.344 121.592C649.081 121.592 649.679 120.997 649.679 120.262C649.679 119.527 649.081 118.931 648.344 118.931C647.607 118.931 647.01 119.527 647.01 120.262C647.01 120.997 647.607 121.592 648.344 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M653.948 121.592C654.685 121.592 655.282 120.997 655.282 120.262C655.282 119.527 654.685 118.931 653.948 118.931C653.211 118.931 652.613 119.527 652.613 120.262C652.613 120.997 653.211 121.592 653.948 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M659.713 121.592C660.45 121.592 661.048 120.997 661.048 120.262C661.048 119.527 660.45 118.931 659.713 118.931C658.976 118.931 658.379 119.527 658.379 120.262C658.379 120.997 658.976 121.592 659.713 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M664.571 146.389H621.227C620.587 146.389 620.053 145.857 620.053 145.219V134.629C620.053 133.991 620.587 133.459 621.227 133.459H664.571C665.212 133.459 665.745 133.991 665.745 134.629V145.219C665.745 145.857 665.212 146.389 664.571 146.389Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M624.75 139.897H639.002" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M648.344 141.228C649.081 141.228 649.679 140.632 649.679 139.897C649.679 139.163 649.081 138.567 648.344 138.567C647.607 138.567 647.01 139.163 647.01 139.897C647.01 140.632 647.607 141.228 648.344 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M653.948 141.228C654.685 141.228 655.282 140.632 655.282 139.897C655.282 139.163 654.685 138.567 653.948 138.567C653.211 138.567 652.613 139.163 652.613 139.897C652.613 140.632 653.211 141.228 653.948 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M659.713 141.228C660.45 141.228 661.048 140.632 661.048 139.897C661.048 139.163 660.45 138.567 659.713 138.567C658.976 138.567 658.379 139.163 658.379 139.897C658.379 140.632 658.976 141.228 659.713 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M624.857 146.389V151.87H660.995V146.389" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M729.159 107.171H685.815C685.174 107.171 684.641 106.639 684.641 106.001V95.4113C684.641 94.7727 685.174 94.2406 685.815 94.2406H729.159C729.799 94.2406 730.333 94.7727 730.333 95.4113V106.001C730.333 106.639 729.799 107.171 729.159 107.171Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M689.125 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M725.85 110.258V107.171" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M689.338 100.679H703.59" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M712.932 102.01C713.669 102.01 714.267 101.414 714.267 100.679C714.267 99.9446 713.669 99.349 712.932 99.349C712.195 99.349 711.598 99.9446 711.598 100.679C711.598 101.414 712.195 102.01 712.932 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M718.536 102.01C719.273 102.01 719.87 101.414 719.87 100.679C719.87 99.9446 719.273 99.349 718.536 99.349C717.799 99.349 717.201 99.9446 717.201 100.679C717.201 101.414 717.799 102.01 718.536 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M724.301 102.01C725.038 102.01 725.636 101.414 725.636 100.679C725.636 99.9446 725.038 99.349 724.301 99.349C723.564 99.349 722.967 99.9446 722.967 100.679C722.967 101.414 723.564 102.01 724.301 102.01Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M729.159 126.754H685.815C685.174 126.754 684.641 126.222 684.641 125.583V114.994C684.641 114.355 685.174 113.823 685.815 113.823H729.159C729.799 113.823 730.333 114.355 730.333 114.994V125.583C730.333 126.222 729.799 126.754 729.159 126.754Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M689.125 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M725.85 129.893V126.754" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M689.338 120.262H703.59" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M712.932 121.592C713.669 121.592 714.267 120.997 714.267 120.262C714.267 119.527 713.669 118.931 712.932 118.931C712.195 118.931 711.598 119.527 711.598 120.262C711.598 120.997 712.195 121.592 712.932 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M718.536 121.592C719.273 121.592 719.87 120.997 719.87 120.262C719.87 119.527 719.273 118.931 718.536 118.931C717.799 118.931 717.201 119.527 717.201 120.262C717.201 120.997 717.799 121.592 718.536 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M724.301 121.592C725.038 121.592 725.636 120.997 725.636 120.262C725.636 119.527 725.038 118.931 724.301 118.931C723.564 118.931 722.967 119.527 722.967 120.262C722.967 120.997 723.564 121.592 724.301 121.592Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M729.159 146.389H685.815C685.174 146.389 684.641 145.857 684.641 145.219V134.629C684.641 133.991 685.174 133.459 685.815 133.459H729.159C729.799 133.459 730.333 133.991 730.333 134.629V145.219C730.333 145.857 729.799 146.389 729.159 146.389Z" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M689.338 139.897H703.59" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={3} d="M712.932 141.228C713.669 141.228 714.267 140.632 714.267 139.897C714.267 139.163 713.669 138.567 712.932 138.567C712.195 138.567 711.598 139.163 711.598 139.897C711.598 140.632 712.195 141.228 712.932 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M718.536 141.228C719.273 141.228 719.87 140.632 719.87 139.897C719.87 139.163 719.273 138.567 718.536 138.567C717.799 138.567 717.201 139.163 717.201 139.897C717.201 140.632 717.799 141.228 718.536 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M724.301 141.228C725.038 141.228 725.636 140.632 725.636 139.897C725.636 139.163 725.038 138.567 724.301 138.567C723.564 138.567 722.967 139.163 722.967 139.897C722.967 140.632 723.564 141.228 724.301 141.228Z" fill="white"/>
            <motion.path variants={pathVariants} custom={3} d="M689.445 146.389V151.87H725.583V146.389" stroke="white" strokeWidth="3.2127" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>

            {/* <!-- Нижняя часть --> */}
            <motion.path variants={pathVariants} custom={1} d="M769.676 239.779C770.03 239.779 770.316 239.493 770.316 239.14C770.316 238.787 770.03 238.502 769.676 238.502C769.322 238.502 769.035 238.787 769.035 239.14C769.035 239.493 769.322 239.779 769.676 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M460.076 239.779C460.43 239.779 460.717 239.493 460.717 239.14C460.717 238.787 460.43 238.502 460.076 238.502C459.722 238.502 459.436 238.787 459.436 239.14C459.436 239.493 459.722 239.779 460.076 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>

            <motion.path variants={pathVariants} custom={1} d="M67.1515 193.909H59.9453V226.156H67.1515V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 207.319C65.6369 207.319 65.9237 207.033 65.9237 206.68C65.9237 206.327 65.6369 206.041 65.2831 206.041C64.9294 206.041 64.6426 206.327 64.6426 206.68C64.6426 207.033 64.9294 207.319 65.2831 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 209.66C65.6369 209.66 65.9237 209.374 65.9237 209.021C65.9237 208.669 65.6369 208.383 65.2831 208.383C64.9294 208.383 64.6426 208.669 64.6426 209.021C64.6426 209.374 64.9294 209.66 65.2831 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 211.948C65.6369 211.948 65.9237 211.662 65.9237 211.31C65.9237 210.957 65.6369 210.671 65.2831 210.671C64.9294 210.671 64.6426 210.957 64.6426 211.31C64.6426 211.662 64.9294 211.948 65.2831 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 214.236C65.6369 214.236 65.9237 213.95 65.9237 213.598C65.9237 213.245 65.6369 212.959 65.2831 212.959C64.9294 212.959 64.6426 213.245 64.6426 213.598C64.6426 213.95 64.9294 214.236 65.2831 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.4429 197.581H61.5462C61.4394 197.581 61.2793 197.474 61.2793 197.315V195.665C61.2793 195.558 61.3861 195.399 61.5462 195.399H65.4429C65.5496 195.399 65.7098 195.505 65.7098 195.665V197.315C65.6564 197.474 65.5496 197.581 65.4429 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M74.3038 193.909H67.0977V226.156H74.3038V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 207.319C72.5763 207.319 72.8631 207.033 72.8631 206.68C72.8631 206.327 72.5763 206.041 72.2226 206.041C71.8688 206.041 71.582 206.327 71.582 206.68C71.582 207.033 71.8688 207.319 72.2226 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 209.66C72.5763 209.66 72.8631 209.374 72.8631 209.021C72.8631 208.669 72.5763 208.383 72.2226 208.383C71.8688 208.383 71.582 208.669 71.582 209.021C71.582 209.374 71.8688 209.66 72.2226 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 211.948C72.5763 211.948 72.8631 211.662 72.8631 211.31C72.8631 210.957 72.5763 210.671 72.2226 210.671C71.8688 210.671 71.582 210.957 71.582 211.31C71.582 211.662 71.8688 211.948 72.2226 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 214.236C72.5763 214.236 72.8631 213.95 72.8631 213.598C72.8631 213.245 72.5763 212.959 72.2226 212.959C71.8688 212.959 71.582 213.245 71.582 213.598C71.582 213.95 71.8688 214.236 72.2226 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.6499 197.581H68.7532C68.6465 197.581 68.4863 197.474 68.4863 197.315V195.665C68.4863 195.558 68.5931 195.399 68.7532 195.399H72.6499C72.7567 195.399 72.9168 195.505 72.9168 195.665V197.315C72.8634 197.474 72.7567 197.581 72.6499 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M81.4035 193.909H74.1973V226.156H81.4035V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 207.319C80.049 207.319 80.3358 207.033 80.3358 206.68C80.3358 206.327 80.049 206.041 79.6952 206.041C79.3415 206.041 79.0547 206.327 79.0547 206.68C79.0547 207.033 79.3415 207.319 79.6952 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 209.66C80.049 209.66 80.3358 209.374 80.3358 209.021C80.3358 208.669 80.049 208.383 79.6952 208.383C79.3415 208.383 79.0547 208.669 79.0547 209.021C79.0547 209.374 79.3415 209.66 79.6952 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 211.948C80.049 211.948 80.3358 211.662 80.3358 211.31C80.3358 210.957 80.049 210.671 79.6952 210.671C79.3415 210.671 79.0547 210.957 79.0547 211.31C79.0547 211.662 79.3415 211.948 79.6952 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 214.236C80.049 214.236 80.3358 213.95 80.3358 213.598C80.3358 213.245 80.049 212.959 79.6952 212.959C79.3415 212.959 79.0547 213.245 79.0547 213.598C79.0547 213.95 79.3415 214.236 79.6952 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.8023 197.581H75.9056C75.7988 197.581 75.6387 197.474 75.6387 197.315V195.665C75.6387 195.558 75.7454 195.399 75.9056 195.399H79.8023C79.909 195.399 80.0691 195.505 80.0691 195.665V197.315C80.0158 197.474 79.909 197.581 79.8023 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M88.6105 193.909H81.4043V226.156H88.6105V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 207.319C86.9885 207.319 87.2752 207.033 87.2752 206.68C87.2752 206.327 86.9885 206.041 86.6347 206.041C86.2809 206.041 85.9941 206.327 85.9941 206.68C85.9941 207.033 86.2809 207.319 86.6347 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 209.66C86.9885 209.66 87.2752 209.374 87.2752 209.021C87.2752 208.669 86.9885 208.383 86.6347 208.383C86.2809 208.383 85.9941 208.669 85.9941 209.021C85.9941 209.374 86.2809 209.66 86.6347 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 211.948C86.9885 211.948 87.2752 211.662 87.2752 211.31C87.2752 210.957 86.9885 210.671 86.6347 210.671C86.2809 210.671 85.9941 210.957 85.9941 211.31C85.9941 211.662 86.2809 211.948 86.6347 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 214.236C86.9885 214.236 87.2752 213.95 87.2752 213.598C87.2752 213.245 86.9885 212.959 86.6347 212.959C86.2809 212.959 85.9941 213.245 85.9941 213.598C85.9941 213.95 86.2809 214.236 86.6347 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.9565 197.581H83.0599C82.9531 197.581 82.793 197.474 82.793 197.315V195.665C82.793 195.558 82.8997 195.399 83.0599 195.399H86.9565C87.0633 195.399 87.2234 195.505 87.2234 195.665V197.315C87.1701 197.474 87.0633 197.581 86.9565 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M95.7628 193.909H88.5566V226.156H95.7628V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 207.319C94.4611 207.319 94.7479 207.033 94.7479 206.68C94.7479 206.327 94.4611 206.041 94.1073 206.041C93.7536 206.041 93.4668 206.327 93.4668 206.68C93.4668 207.033 93.7536 207.319 94.1073 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 209.66C94.4611 209.66 94.7479 209.374 94.7479 209.021C94.7479 208.669 94.4611 208.383 94.1073 208.383C93.7536 208.383 93.4668 208.669 93.4668 209.021C93.4668 209.374 93.7536 209.66 94.1073 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 211.948C94.4611 211.948 94.7479 211.662 94.7479 211.31C94.7479 210.957 94.4611 210.671 94.1073 210.671C93.7536 210.671 93.4668 210.957 93.4668 211.31C93.4668 211.662 93.7536 211.948 94.1073 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 214.236C94.4611 214.236 94.7479 213.95 94.7479 213.598C94.7479 213.245 94.4611 212.959 94.1073 212.959C93.7536 212.959 93.4668 213.245 93.4668 213.598C93.4668 213.95 93.7536 214.236 94.1073 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1089 197.581H90.2122C90.1055 197.581 89.9453 197.474 89.9453 197.315V195.665C89.9453 195.558 90.0521 195.399 90.2122 195.399H94.1089C94.2156 195.399 94.3758 195.505 94.3758 195.665V197.315C94.3758 197.474 94.269 197.581 94.1089 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M102.916 193.909H40.1953V226.156H102.916V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M102.97 193.909H95.7637V226.156H102.97V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 207.319C101.403 207.319 101.689 207.033 101.689 206.68C101.689 206.327 101.403 206.041 101.049 206.041C100.695 206.041 100.408 206.327 100.408 206.68C100.408 207.033 100.695 207.319 101.049 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 209.66C101.403 209.66 101.689 209.374 101.689 209.021C101.689 208.669 101.403 208.383 101.049 208.383C100.695 208.383 100.408 208.669 100.408 209.021C100.408 209.374 100.695 209.66 101.049 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 211.948C101.403 211.948 101.689 211.662 101.689 211.31C101.689 210.957 101.403 210.671 101.049 210.671C100.695 210.671 100.408 210.957 100.408 211.31C100.408 211.662 100.695 211.948 101.049 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 214.236C101.403 214.236 101.689 213.95 101.689 213.598C101.689 213.245 101.403 212.959 101.049 212.959C100.695 212.959 100.408 213.245 100.408 213.598C100.408 213.95 100.695 214.236 101.049 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.316 197.581H97.4192C97.3125 197.581 97.1523 197.474 97.1523 197.315V195.665C97.1523 195.558 97.2591 195.399 97.4192 195.399H101.316C101.423 195.399 101.583 195.505 101.583 195.665V197.315C101.529 197.474 101.423 197.581 101.316 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M59.8389 193.909H40.1953V226.156H59.8389V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M42.8117 213.225H41.584V216.258H42.8117V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M42.8117 217.589H41.584V220.622H42.8117V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M42.8117 221.952H41.584V224.985H42.8117V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M59.8389 193.909H40.1953V204.871H59.8389V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M57.5976 196.676C57.9514 196.676 58.2381 196.39 58.2381 196.037C58.2381 195.685 57.9514 195.399 57.5976 195.399C57.2438 195.399 56.957 195.685 56.957 196.037C56.957 196.39 57.2438 196.676 57.5976 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M67.1515 237.012H59.9453V269.259H67.1515V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 250.421C65.6369 250.421 65.9237 250.135 65.9237 249.783C65.9237 249.43 65.6369 249.144 65.2831 249.144C64.9294 249.144 64.6426 249.43 64.6426 249.783C64.6426 250.135 64.9294 250.421 65.2831 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 252.763C65.6369 252.763 65.9237 252.477 65.9237 252.124C65.9237 251.771 65.6369 251.486 65.2831 251.486C64.9294 251.486 64.6426 251.771 64.6426 252.124C64.6426 252.477 64.9294 252.763 65.2831 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 255.051C65.6369 255.051 65.9237 254.765 65.9237 254.412C65.9237 254.06 65.6369 253.774 65.2831 253.774C64.9294 253.774 64.6426 254.06 64.6426 254.412C64.6426 254.765 64.9294 255.051 65.2831 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.2831 257.339C65.6369 257.339 65.9237 257.053 65.9237 256.7C65.9237 256.348 65.6369 256.062 65.2831 256.062C64.9294 256.062 64.6426 256.348 64.6426 256.7C64.6426 257.053 64.9294 257.339 65.2831 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M65.4429 240.683H61.5462C61.4394 240.683 61.2793 240.577 61.2793 240.417V238.768C61.2793 238.661 61.3861 238.502 61.5462 238.502H65.4429C65.5496 238.502 65.7098 238.608 65.7098 238.768V240.417C65.6564 240.577 65.5496 240.683 65.4429 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M74.3038 237.012H67.0977V269.259H74.3038V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 250.421C72.5763 250.421 72.8631 250.135 72.8631 249.783C72.8631 249.43 72.5763 249.144 72.2226 249.144C71.8688 249.144 71.582 249.43 71.582 249.783C71.582 250.135 71.8688 250.421 72.2226 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 252.763C72.5763 252.763 72.8631 252.477 72.8631 252.124C72.8631 251.771 72.5763 251.486 72.2226 251.486C71.8688 251.486 71.582 251.771 71.582 252.124C71.582 252.477 71.8688 252.763 72.2226 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 255.051C72.5763 255.051 72.8631 254.765 72.8631 254.412C72.8631 254.06 72.5763 253.774 72.2226 253.774C71.8688 253.774 71.582 254.06 71.582 254.412C71.582 254.765 71.8688 255.051 72.2226 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.2226 257.339C72.5763 257.339 72.8631 257.053 72.8631 256.7C72.8631 256.348 72.5763 256.062 72.2226 256.062C71.8688 256.062 71.582 256.348 71.582 256.7C71.582 257.053 71.8688 257.339 72.2226 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M72.6499 240.683H68.7532C68.6465 240.683 68.4863 240.577 68.4863 240.417V238.768C68.4863 238.661 68.5931 238.502 68.7532 238.502H72.6499C72.7567 238.502 72.9168 238.608 72.9168 238.768V240.417C72.8634 240.577 72.7567 240.683 72.6499 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M81.4035 237.012H74.1973V269.259H81.4035V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 250.421C80.049 250.421 80.3358 250.135 80.3358 249.783C80.3358 249.43 80.049 249.144 79.6952 249.144C79.3415 249.144 79.0547 249.43 79.0547 249.783C79.0547 250.135 79.3415 250.421 79.6952 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 252.763C80.049 252.763 80.3358 252.477 80.3358 252.124C80.3358 251.771 80.049 251.486 79.6952 251.486C79.3415 251.486 79.0547 251.771 79.0547 252.124C79.0547 252.477 79.3415 252.763 79.6952 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 255.051C80.049 255.051 80.3358 254.765 80.3358 254.412C80.3358 254.06 80.049 253.774 79.6952 253.774C79.3415 253.774 79.0547 254.06 79.0547 254.412C79.0547 254.765 79.3415 255.051 79.6952 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.6952 257.339C80.049 257.339 80.3358 257.053 80.3358 256.7C80.3358 256.348 80.049 256.062 79.6952 256.062C79.3415 256.062 79.0547 256.348 79.0547 256.7C79.0547 257.053 79.3415 257.339 79.6952 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M79.8023 240.683H75.9056C75.7988 240.683 75.6387 240.577 75.6387 240.417V238.768C75.6387 238.661 75.7454 238.502 75.9056 238.502H79.8023C79.909 238.502 80.0691 238.608 80.0691 238.768V240.417C80.0158 240.577 79.909 240.683 79.8023 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M88.6105 237.012H81.4043V269.259H88.6105V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 250.421C86.9885 250.421 87.2752 250.135 87.2752 249.783C87.2752 249.43 86.9885 249.144 86.6347 249.144C86.2809 249.144 85.9941 249.43 85.9941 249.783C85.9941 250.135 86.2809 250.421 86.6347 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 252.763C86.9885 252.763 87.2752 252.477 87.2752 252.124C87.2752 251.771 86.9885 251.486 86.6347 251.486C86.2809 251.486 85.9941 251.771 85.9941 252.124C85.9941 252.477 86.2809 252.763 86.6347 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 255.051C86.9885 255.051 87.2752 254.765 87.2752 254.412C87.2752 254.06 86.9885 253.774 86.6347 253.774C86.2809 253.774 85.9941 254.06 85.9941 254.412C85.9941 254.765 86.2809 255.051 86.6347 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.6347 257.339C86.9885 257.339 87.2752 257.053 87.2752 256.7C87.2752 256.348 86.9885 256.062 86.6347 256.062C86.2809 256.062 85.9941 256.348 85.9941 256.7C85.9941 257.053 86.2809 257.339 86.6347 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M86.9565 240.683H83.0599C82.9531 240.683 82.793 240.577 82.793 240.417V238.768C82.793 238.661 82.8997 238.502 83.0599 238.502H86.9565C87.0633 238.502 87.2234 238.608 87.2234 238.768V240.417C87.1701 240.577 87.0633 240.683 86.9565 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M95.7628 237.012H88.5566V269.259H95.7628V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 250.421C94.4611 250.421 94.7479 250.135 94.7479 249.783C94.7479 249.43 94.4611 249.144 94.1073 249.144C93.7536 249.144 93.4668 249.43 93.4668 249.783C93.4668 250.135 93.7536 250.421 94.1073 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 252.763C94.4611 252.763 94.7479 252.477 94.7479 252.124C94.7479 251.771 94.4611 251.486 94.1073 251.486C93.7536 251.486 93.4668 251.771 93.4668 252.124C93.4668 252.477 93.7536 252.763 94.1073 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 255.051C94.4611 255.051 94.7479 254.765 94.7479 254.412C94.7479 254.06 94.4611 253.774 94.1073 253.774C93.7536 253.774 93.4668 254.06 93.4668 254.412C93.4668 254.765 93.7536 255.051 94.1073 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1073 257.339C94.4611 257.339 94.7479 257.053 94.7479 256.7C94.7479 256.348 94.4611 256.062 94.1073 256.062C93.7536 256.062 93.4668 256.348 93.4668 256.7C93.4668 257.053 93.7536 257.339 94.1073 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M94.1089 240.683H90.2122C90.1055 240.683 89.9453 240.577 89.9453 240.417V238.768C89.9453 238.661 90.0521 238.502 90.2122 238.502H94.1089C94.2156 238.502 94.3758 238.608 94.3758 238.768V240.417C94.3758 240.577 94.269 240.683 94.1089 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M102.916 237.012H40.1953V269.259H102.916V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M102.97 237.012H95.7637V269.259H102.97V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 250.421C101.403 250.421 101.689 250.135 101.689 249.783C101.689 249.43 101.403 249.144 101.049 249.144C100.695 249.144 100.408 249.43 100.408 249.783C100.408 250.135 100.695 250.421 101.049 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 252.763C101.403 252.763 101.689 252.477 101.689 252.124C101.689 251.771 101.403 251.486 101.049 251.486C100.695 251.486 100.408 251.771 100.408 252.124C100.408 252.477 100.695 252.763 101.049 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 255.051C101.403 255.051 101.689 254.765 101.689 254.412C101.689 254.06 101.403 253.774 101.049 253.774C100.695 253.774 100.408 254.06 100.408 254.412C100.408 254.765 100.695 255.051 101.049 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.049 257.339C101.403 257.339 101.689 257.053 101.689 256.7C101.689 256.348 101.403 256.062 101.049 256.062C100.695 256.062 100.408 256.348 100.408 256.7C100.408 257.053 100.695 257.339 101.049 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M101.316 240.683H97.4192C97.3125 240.683 97.1523 240.577 97.1523 240.417V238.768C97.1523 238.661 97.2591 238.502 97.4192 238.502H101.316C101.423 238.502 101.583 238.608 101.583 238.768V240.417C101.529 240.577 101.423 240.683 101.316 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M59.8389 237.012H40.1953V269.259H59.8389V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M42.8117 256.328H41.584V259.361H42.8117V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M42.8117 260.691H41.584V263.725H42.8117V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M42.8117 265.055H41.584V268.088H42.8117V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M59.8389 237.012H40.1953V247.973H59.8389V237.012Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M57.5976 239.779C57.9514 239.779 58.2381 239.493 58.2381 239.14C58.2381 238.787 57.9514 238.502 57.5976 238.502C57.2438 238.502 56.957 238.787 56.957 239.14C56.957 239.493 57.2438 239.779 57.5976 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M158.962 193.909H151.756V226.156H158.962V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 207.319C157.447 207.319 157.734 207.033 157.734 206.68C157.734 206.327 157.447 206.041 157.094 206.041C156.74 206.041 156.453 206.327 156.453 206.68C156.453 207.033 156.74 207.319 157.094 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 209.66C157.447 209.66 157.734 209.374 157.734 209.021C157.734 208.669 157.447 208.383 157.094 208.383C156.74 208.383 156.453 208.669 156.453 209.021C156.453 209.374 156.74 209.66 157.094 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 211.948C157.447 211.948 157.734 211.662 157.734 211.31C157.734 210.957 157.447 210.671 157.094 210.671C156.74 210.671 156.453 210.957 156.453 211.31C156.453 211.662 156.74 211.948 157.094 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 214.236C157.447 214.236 157.734 213.95 157.734 213.598C157.734 213.245 157.447 212.959 157.094 212.959C156.74 212.959 156.453 213.245 156.453 213.598C156.453 213.95 156.74 214.236 157.094 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.253 197.581H153.357C153.25 197.581 153.09 197.474 153.09 197.315V195.665C153.09 195.558 153.197 195.399 153.357 195.399H157.253C157.36 195.399 157.52 195.505 157.52 195.665V197.315C157.467 197.474 157.36 197.581 157.253 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M166.114 193.909H158.908V226.156H166.114V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 207.319C164.387 207.319 164.674 207.033 164.674 206.68C164.674 206.327 164.387 206.041 164.033 206.041C163.679 206.041 163.393 206.327 163.393 206.68C163.393 207.033 163.679 207.319 164.033 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 209.66C164.387 209.66 164.674 209.374 164.674 209.021C164.674 208.669 164.387 208.383 164.033 208.383C163.679 208.383 163.393 208.669 163.393 209.021C163.393 209.374 163.679 209.66 164.033 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 211.948C164.387 211.948 164.674 211.662 164.674 211.31C164.674 210.957 164.387 210.671 164.033 210.671C163.679 210.671 163.393 210.957 163.393 211.31C163.393 211.662 163.679 211.948 164.033 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 214.236C164.387 214.236 164.674 213.95 164.674 213.598C164.674 213.245 164.387 212.959 164.033 212.959C163.679 212.959 163.393 213.245 163.393 213.598C163.393 213.95 163.679 214.236 164.033 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.46 197.581H160.564C160.457 197.581 160.297 197.474 160.297 197.315V195.665C160.297 195.558 160.404 195.399 160.564 195.399H164.46C164.567 195.399 164.727 195.505 164.727 195.665V197.315C164.674 197.474 164.567 197.581 164.46 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M173.214 193.909H166.008V226.156H173.214V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 207.319C171.86 207.319 172.146 207.033 172.146 206.68C172.146 206.327 171.86 206.041 171.506 206.041C171.152 206.041 170.865 206.327 170.865 206.68C170.865 207.033 171.152 207.319 171.506 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 209.66C171.86 209.66 172.146 209.374 172.146 209.021C172.146 208.669 171.86 208.383 171.506 208.383C171.152 208.383 170.865 208.669 170.865 209.021C170.865 209.374 171.152 209.66 171.506 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 211.948C171.86 211.948 172.146 211.662 172.146 211.31C172.146 210.957 171.86 210.671 171.506 210.671C171.152 210.671 170.865 210.957 170.865 211.31C170.865 211.662 171.152 211.948 171.506 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 214.236C171.86 214.236 172.146 213.95 172.146 213.598C172.146 213.245 171.86 212.959 171.506 212.959C171.152 212.959 170.865 213.245 170.865 213.598C170.865 213.95 171.152 214.236 171.506 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.613 197.581H167.716C167.609 197.581 167.449 197.474 167.449 197.315V195.665C167.449 195.558 167.556 195.399 167.716 195.399H171.613C171.72 195.399 171.88 195.505 171.88 195.665V197.315C171.826 197.474 171.72 197.581 171.613 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M180.421 193.909H173.215V226.156H180.421V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 207.319C178.799 207.319 179.086 207.033 179.086 206.68C179.086 206.327 178.799 206.041 178.445 206.041C178.091 206.041 177.805 206.327 177.805 206.68C177.805 207.033 178.091 207.319 178.445 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 209.66C178.799 209.66 179.086 209.374 179.086 209.021C179.086 208.669 178.799 208.383 178.445 208.383C178.091 208.383 177.805 208.669 177.805 209.021C177.805 209.374 178.091 209.66 178.445 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 211.948C178.799 211.948 179.086 211.662 179.086 211.31C179.086 210.957 178.799 210.671 178.445 210.671C178.091 210.671 177.805 210.957 177.805 211.31C177.805 211.662 178.091 211.948 178.445 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 214.236C178.799 214.236 179.086 213.95 179.086 213.598C179.086 213.245 178.799 212.959 178.445 212.959C178.091 212.959 177.805 213.245 177.805 213.598C177.805 213.95 178.091 214.236 178.445 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.767 197.581H174.87C174.764 197.581 174.604 197.474 174.604 197.315V195.665C174.604 195.558 174.71 195.399 174.87 195.399H178.767C178.874 195.399 179.034 195.505 179.034 195.665V197.315C178.981 197.474 178.874 197.581 178.767 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M187.573 193.909H180.367V226.156H187.573V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 207.319C186.272 207.319 186.558 207.033 186.558 206.68C186.558 206.327 186.272 206.041 185.918 206.041C185.564 206.041 185.277 206.327 185.277 206.68C185.277 207.033 185.564 207.319 185.918 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 209.66C186.272 209.66 186.558 209.374 186.558 209.021C186.558 208.669 186.272 208.383 185.918 208.383C185.564 208.383 185.277 208.669 185.277 209.021C185.277 209.374 185.564 209.66 185.918 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 211.948C186.272 211.948 186.558 211.662 186.558 211.31C186.558 210.957 186.272 210.671 185.918 210.671C185.564 210.671 185.277 210.957 185.277 211.31C185.277 211.662 185.564 211.948 185.918 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 214.236C186.272 214.236 186.558 213.95 186.558 213.598C186.558 213.245 186.272 212.959 185.918 212.959C185.564 212.959 185.277 213.245 185.277 213.598C185.277 213.95 185.564 214.236 185.918 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.919 197.581H182.023C181.916 197.581 181.756 197.474 181.756 197.315V195.665C181.756 195.558 181.863 195.399 182.023 195.399H185.919C186.026 195.399 186.186 195.505 186.186 195.665V197.315C186.186 197.474 186.08 197.581 185.919 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M194.726 193.909H132.006V226.156H194.726V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M194.78 193.909H187.574V226.156H194.78V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 207.319C193.213 207.319 193.5 207.033 193.5 206.68C193.5 206.327 193.213 206.041 192.859 206.041C192.506 206.041 192.219 206.327 192.219 206.68C192.219 207.033 192.506 207.319 192.859 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 209.66C193.213 209.66 193.5 209.374 193.5 209.021C193.5 208.669 193.213 208.383 192.859 208.383C192.506 208.383 192.219 208.669 192.219 209.021C192.219 209.374 192.506 209.66 192.859 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 211.948C193.213 211.948 193.5 211.662 193.5 211.31C193.5 210.957 193.213 210.671 192.859 210.671C192.506 210.671 192.219 210.957 192.219 211.31C192.219 211.662 192.506 211.948 192.859 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 214.236C193.213 214.236 193.5 213.95 193.5 213.598C193.5 213.245 193.213 212.959 192.859 212.959C192.506 212.959 192.219 213.245 192.219 213.598C192.219 213.95 192.506 214.236 192.859 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M193.126 197.581H189.23C189.123 197.581 188.963 197.474 188.963 197.315V195.665C188.963 195.558 189.07 195.399 189.23 195.399H193.126C193.233 195.399 193.393 195.505 193.393 195.665V197.315C193.34 197.474 193.233 197.581 193.126 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M151.649 193.909H132.006V226.156H151.649V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 213.225H133.395V216.258H134.622V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 217.589H133.395V220.622H134.622V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 221.952H133.395V224.985H134.622V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M151.649 193.909H132.006V204.871H151.649V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M149.408 196.676C149.762 196.676 150.049 196.39 150.049 196.037C150.049 195.685 149.762 195.399 149.408 195.399C149.054 195.399 148.768 195.685 148.768 196.037C148.768 196.39 149.054 196.676 149.408 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M158.962 237.012H151.756V269.259H158.962V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 250.421C157.447 250.421 157.734 250.135 157.734 249.783C157.734 249.43 157.447 249.144 157.094 249.144C156.74 249.144 156.453 249.43 156.453 249.783C156.453 250.135 156.74 250.421 157.094 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 252.763C157.447 252.763 157.734 252.477 157.734 252.124C157.734 251.771 157.447 251.486 157.094 251.486C156.74 251.486 156.453 251.771 156.453 252.124C156.453 252.477 156.74 252.763 157.094 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 255.051C157.447 255.051 157.734 254.765 157.734 254.412C157.734 254.06 157.447 253.774 157.094 253.774C156.74 253.774 156.453 254.06 156.453 254.412C156.453 254.765 156.74 255.051 157.094 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 257.339C157.447 257.339 157.734 257.053 157.734 256.7C157.734 256.348 157.447 256.062 157.094 256.062C156.74 256.062 156.453 256.348 156.453 256.7C156.453 257.053 156.74 257.339 157.094 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.253 240.683H153.357C153.25 240.683 153.09 240.577 153.09 240.417V238.768C153.09 238.661 153.197 238.502 153.357 238.502H157.253C157.36 238.502 157.52 238.608 157.52 238.768V240.417C157.467 240.577 157.36 240.683 157.253 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M166.114 237.012H158.908V269.259H166.114V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 250.421C164.387 250.421 164.674 250.135 164.674 249.783C164.674 249.43 164.387 249.144 164.033 249.144C163.679 249.144 163.393 249.43 163.393 249.783C163.393 250.135 163.679 250.421 164.033 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 252.763C164.387 252.763 164.674 252.477 164.674 252.124C164.674 251.771 164.387 251.486 164.033 251.486C163.679 251.486 163.393 251.771 163.393 252.124C163.393 252.477 163.679 252.763 164.033 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 255.051C164.387 255.051 164.674 254.765 164.674 254.412C164.674 254.06 164.387 253.774 164.033 253.774C163.679 253.774 163.393 254.06 163.393 254.412C163.393 254.765 163.679 255.051 164.033 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 257.339C164.387 257.339 164.674 257.053 164.674 256.7C164.674 256.348 164.387 256.062 164.033 256.062C163.679 256.062 163.393 256.348 163.393 256.7C163.393 257.053 163.679 257.339 164.033 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.46 240.683H160.564C160.457 240.683 160.297 240.577 160.297 240.417V238.768C160.297 238.661 160.404 238.502 160.564 238.502H164.46C164.567 238.502 164.727 238.608 164.727 238.768V240.417C164.674 240.577 164.567 240.683 164.46 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M173.214 237.012H166.008V269.259H173.214V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 250.421C171.86 250.421 172.146 250.135 172.146 249.783C172.146 249.43 171.86 249.144 171.506 249.144C171.152 249.144 170.865 249.43 170.865 249.783C170.865 250.135 171.152 250.421 171.506 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 252.763C171.86 252.763 172.146 252.477 172.146 252.124C172.146 251.771 171.86 251.486 171.506 251.486C171.152 251.486 170.865 251.771 170.865 252.124C170.865 252.477 171.152 252.763 171.506 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 255.051C171.86 255.051 172.146 254.765 172.146 254.412C172.146 254.06 171.86 253.774 171.506 253.774C171.152 253.774 170.865 254.06 170.865 254.412C170.865 254.765 171.152 255.051 171.506 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 257.339C171.86 257.339 172.146 257.053 172.146 256.7C172.146 256.348 171.86 256.062 171.506 256.062C171.152 256.062 170.865 256.348 170.865 256.7C170.865 257.053 171.152 257.339 171.506 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.613 240.683H167.716C167.609 240.683 167.449 240.577 167.449 240.417V238.768C167.449 238.661 167.556 238.502 167.716 238.502H171.613C171.72 238.502 171.88 238.608 171.88 238.768V240.417C171.826 240.577 171.72 240.683 171.613 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M180.421 237.012H173.215V269.259H180.421V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 250.421C178.799 250.421 179.086 250.135 179.086 249.783C179.086 249.43 178.799 249.144 178.445 249.144C178.091 249.144 177.805 249.43 177.805 249.783C177.805 250.135 178.091 250.421 178.445 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 252.763C178.799 252.763 179.086 252.477 179.086 252.124C179.086 251.771 178.799 251.486 178.445 251.486C178.091 251.486 177.805 251.771 177.805 252.124C177.805 252.477 178.091 252.763 178.445 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 255.051C178.799 255.051 179.086 254.765 179.086 254.412C179.086 254.06 178.799 253.774 178.445 253.774C178.091 253.774 177.805 254.06 177.805 254.412C177.805 254.765 178.091 255.051 178.445 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 257.339C178.799 257.339 179.086 257.053 179.086 256.7C179.086 256.348 178.799 256.062 178.445 256.062C178.091 256.062 177.805 256.348 177.805 256.7C177.805 257.053 178.091 257.339 178.445 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.767 240.683H174.87C174.764 240.683 174.604 240.577 174.604 240.417V238.768C174.604 238.661 174.71 238.502 174.87 238.502H178.767C178.874 238.502 179.034 238.608 179.034 238.768V240.417C178.981 240.577 178.874 240.683 178.767 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M187.573 237.012H180.367V269.259H187.573V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 250.421C186.272 250.421 186.558 250.135 186.558 249.783C186.558 249.43 186.272 249.144 185.918 249.144C185.564 249.144 185.277 249.43 185.277 249.783C185.277 250.135 185.564 250.421 185.918 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 252.763C186.272 252.763 186.558 252.477 186.558 252.124C186.558 251.771 186.272 251.486 185.918 251.486C185.564 251.486 185.277 251.771 185.277 252.124C185.277 252.477 185.564 252.763 185.918 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 255.051C186.272 255.051 186.558 254.765 186.558 254.412C186.558 254.06 186.272 253.774 185.918 253.774C185.564 253.774 185.277 254.06 185.277 254.412C185.277 254.765 185.564 255.051 185.918 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 257.339C186.272 257.339 186.558 257.053 186.558 256.7C186.558 256.348 186.272 256.062 185.918 256.062C185.564 256.062 185.277 256.348 185.277 256.7C185.277 257.053 185.564 257.339 185.918 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.919 240.683H182.023C181.916 240.683 181.756 240.577 181.756 240.417V238.768C181.756 238.661 181.863 238.502 182.023 238.502H185.919C186.026 238.502 186.186 238.608 186.186 238.768V240.417C186.186 240.577 186.08 240.683 185.919 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M194.726 237.012H132.006V269.259H194.726V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M194.78 237.012H187.574V269.259H194.78V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 250.421C193.213 250.421 193.5 250.135 193.5 249.783C193.5 249.43 193.213 249.144 192.859 249.144C192.506 249.144 192.219 249.43 192.219 249.783C192.219 250.135 192.506 250.421 192.859 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 252.763C193.213 252.763 193.5 252.477 193.5 252.124C193.5 251.771 193.213 251.486 192.859 251.486C192.506 251.486 192.219 251.771 192.219 252.124C192.219 252.477 192.506 252.763 192.859 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 255.051C193.213 255.051 193.5 254.765 193.5 254.412C193.5 254.06 193.213 253.774 192.859 253.774C192.506 253.774 192.219 254.06 192.219 254.412C192.219 254.765 192.506 255.051 192.859 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 257.339C193.213 257.339 193.5 257.053 193.5 256.7C193.5 256.348 193.213 256.062 192.859 256.062C192.506 256.062 192.219 256.348 192.219 256.7C192.219 257.053 192.506 257.339 192.859 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M193.126 240.683H189.23C189.123 240.683 188.963 240.577 188.963 240.417V238.768C188.963 238.661 189.07 238.502 189.23 238.502H193.126C193.233 238.502 193.393 238.608 193.393 238.768V240.417C193.34 240.577 193.233 240.683 193.126 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M151.649 237.012H132.006V269.259H151.649V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 256.328H133.395V259.361H134.622V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 260.691H133.395V263.725H134.622V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 265.055H133.395V268.088H134.622V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M151.649 237.012H132.006V247.973H151.649V237.012Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M149.408 239.779C149.762 239.779 150.049 239.493 150.049 239.14C150.049 238.787 149.762 238.502 149.408 238.502C149.054 238.502 148.768 238.787 148.768 239.14C148.768 239.493 149.054 239.779 149.408 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M158.962 280.114H151.756V312.361H158.962V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 293.524C157.447 293.524 157.734 293.238 157.734 292.885C157.734 292.533 157.447 292.247 157.094 292.247C156.74 292.247 156.453 292.533 156.453 292.885C156.453 293.238 156.74 293.524 157.094 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 295.865C157.447 295.865 157.734 295.579 157.734 295.227C157.734 294.874 157.447 294.588 157.094 294.588C156.74 294.588 156.453 294.874 156.453 295.227C156.453 295.579 156.74 295.865 157.094 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 298.154C157.447 298.154 157.734 297.868 157.734 297.515C157.734 297.162 157.447 296.876 157.094 296.876C156.74 296.876 156.453 297.162 156.453 297.515C156.453 297.868 156.74 298.154 157.094 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.094 300.442C157.447 300.442 157.734 300.156 157.734 299.803C157.734 299.45 157.447 299.165 157.094 299.165C156.74 299.165 156.453 299.45 156.453 299.803C156.453 300.156 156.74 300.442 157.094 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M157.253 283.786H153.357C153.25 283.786 153.09 283.68 153.09 283.52V281.87C153.09 281.764 153.197 281.604 153.357 281.604H157.253C157.36 281.604 157.52 281.711 157.52 281.87V283.52C157.467 283.68 157.36 283.786 157.253 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M166.114 280.114H158.908V312.361H166.114V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 293.524C164.387 293.524 164.674 293.238 164.674 292.885C164.674 292.533 164.387 292.247 164.033 292.247C163.679 292.247 163.393 292.533 163.393 292.885C163.393 293.238 163.679 293.524 164.033 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 295.865C164.387 295.865 164.674 295.579 164.674 295.227C164.674 294.874 164.387 294.588 164.033 294.588C163.679 294.588 163.393 294.874 163.393 295.227C163.393 295.579 163.679 295.865 164.033 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 298.154C164.387 298.154 164.674 297.868 164.674 297.515C164.674 297.162 164.387 296.876 164.033 296.876C163.679 296.876 163.393 297.162 163.393 297.515C163.393 297.868 163.679 298.154 164.033 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.033 300.442C164.387 300.442 164.674 300.156 164.674 299.803C164.674 299.45 164.387 299.165 164.033 299.165C163.679 299.165 163.393 299.45 163.393 299.803C163.393 300.156 163.679 300.442 164.033 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M164.46 283.786H160.564C160.457 283.786 160.297 283.68 160.297 283.52V281.87C160.297 281.764 160.404 281.604 160.564 281.604H164.46C164.567 281.604 164.727 281.711 164.727 281.87V283.52C164.674 283.68 164.567 283.786 164.46 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M173.214 280.114H166.008V312.361H173.214V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 293.524C171.86 293.524 172.146 293.238 172.146 292.885C172.146 292.533 171.86 292.247 171.506 292.247C171.152 292.247 170.865 292.533 170.865 292.885C170.865 293.238 171.152 293.524 171.506 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 295.865C171.86 295.865 172.146 295.579 172.146 295.227C172.146 294.874 171.86 294.588 171.506 294.588C171.152 294.588 170.865 294.874 170.865 295.227C170.865 295.579 171.152 295.865 171.506 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 298.154C171.86 298.154 172.146 297.868 172.146 297.515C172.146 297.162 171.86 296.876 171.506 296.876C171.152 296.876 170.865 297.162 170.865 297.515C170.865 297.868 171.152 298.154 171.506 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.506 300.442C171.86 300.442 172.146 300.156 172.146 299.803C172.146 299.45 171.86 299.165 171.506 299.165C171.152 299.165 170.865 299.45 170.865 299.803C170.865 300.156 171.152 300.442 171.506 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M171.613 283.786H167.716C167.609 283.786 167.449 283.68 167.449 283.52V281.87C167.449 281.764 167.556 281.604 167.716 281.604H171.613C171.72 281.604 171.88 281.711 171.88 281.87V283.52C171.826 283.68 171.72 283.786 171.613 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M180.421 280.114H173.215V312.361H180.421V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 293.524C178.799 293.524 179.086 293.238 179.086 292.885C179.086 292.533 178.799 292.247 178.445 292.247C178.091 292.247 177.805 292.533 177.805 292.885C177.805 293.238 178.091 293.524 178.445 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 295.865C178.799 295.865 179.086 295.579 179.086 295.227C179.086 294.874 178.799 294.588 178.445 294.588C178.091 294.588 177.805 294.874 177.805 295.227C177.805 295.579 178.091 295.865 178.445 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 298.154C178.799 298.154 179.086 297.868 179.086 297.515C179.086 297.162 178.799 296.876 178.445 296.876C178.091 296.876 177.805 297.162 177.805 297.515C177.805 297.868 178.091 298.154 178.445 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.445 300.442C178.799 300.442 179.086 300.156 179.086 299.803C179.086 299.45 178.799 299.165 178.445 299.165C178.091 299.165 177.805 299.45 177.805 299.803C177.805 300.156 178.091 300.442 178.445 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M178.767 283.786H174.87C174.764 283.786 174.604 283.68 174.604 283.52V281.87C174.604 281.764 174.71 281.604 174.87 281.604H178.767C178.874 281.604 179.034 281.711 179.034 281.87V283.52C178.981 283.68 178.874 283.786 178.767 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M187.573 280.114H180.367V312.361H187.573V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 293.524C186.272 293.524 186.558 293.238 186.558 292.885C186.558 292.533 186.272 292.247 185.918 292.247C185.564 292.247 185.277 292.533 185.277 292.885C185.277 293.238 185.564 293.524 185.918 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 295.865C186.272 295.865 186.558 295.579 186.558 295.227C186.558 294.874 186.272 294.588 185.918 294.588C185.564 294.588 185.277 294.874 185.277 295.227C185.277 295.579 185.564 295.865 185.918 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 298.154C186.272 298.154 186.558 297.868 186.558 297.515C186.558 297.162 186.272 296.876 185.918 296.876C185.564 296.876 185.277 297.162 185.277 297.515C185.277 297.868 185.564 298.154 185.918 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.918 300.442C186.272 300.442 186.558 300.156 186.558 299.803C186.558 299.45 186.272 299.165 185.918 299.165C185.564 299.165 185.277 299.45 185.277 299.803C185.277 300.156 185.564 300.442 185.918 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M185.919 283.786H182.023C181.916 283.786 181.756 283.68 181.756 283.52V281.87C181.756 281.764 181.863 281.604 182.023 281.604H185.919C186.026 281.604 186.186 281.711 186.186 281.87V283.52C186.186 283.68 186.08 283.786 185.919 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M194.726 280.114H132.006V312.361H194.726V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M194.78 280.114H187.574V312.361H194.78V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 293.524C193.213 293.524 193.5 293.238 193.5 292.885C193.5 292.533 193.213 292.247 192.859 292.247C192.506 292.247 192.219 292.533 192.219 292.885C192.219 293.238 192.506 293.524 192.859 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 295.865C193.213 295.865 193.5 295.579 193.5 295.227C193.5 294.874 193.213 294.588 192.859 294.588C192.506 294.588 192.219 294.874 192.219 295.227C192.219 295.579 192.506 295.865 192.859 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 298.154C193.213 298.154 193.5 297.868 193.5 297.515C193.5 297.162 193.213 296.876 192.859 296.876C192.506 296.876 192.219 297.162 192.219 297.515C192.219 297.868 192.506 298.154 192.859 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M192.859 300.442C193.213 300.442 193.5 300.156 193.5 299.803C193.5 299.45 193.213 299.165 192.859 299.165C192.506 299.165 192.219 299.45 192.219 299.803C192.219 300.156 192.506 300.442 192.859 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M193.126 283.786H189.23C189.123 283.786 188.963 283.68 188.963 283.52V281.87C188.963 281.764 189.07 281.604 189.23 281.604H193.126C193.233 281.604 193.393 281.711 193.393 281.87V283.52C193.34 283.68 193.233 283.786 193.126 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M151.649 280.114H132.006V312.361H151.649V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 299.431H133.395V302.464H134.622V299.431Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 303.794H133.395V306.827H134.622V303.794Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M134.622 308.158H133.395V311.191H134.622V308.158Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M151.649 280.114H132.006V291.076H151.649V280.114Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M149.408 282.881C149.762 282.881 150.049 282.595 150.049 282.243C150.049 281.89 149.762 281.604 149.408 281.604C149.054 281.604 148.768 281.89 148.768 282.243C148.768 282.595 149.054 282.881 149.408 282.881Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M250.241 193.909H243.035V226.156H250.241V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 207.319C248.727 207.319 249.014 207.033 249.014 206.68C249.014 206.327 248.727 206.041 248.373 206.041C248.019 206.041 247.732 206.327 247.732 206.68C247.732 207.033 248.019 207.319 248.373 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 209.66C248.727 209.66 249.014 209.374 249.014 209.021C249.014 208.669 248.727 208.383 248.373 208.383C248.019 208.383 247.732 208.669 247.732 209.021C247.732 209.374 248.019 209.66 248.373 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 211.948C248.727 211.948 249.014 211.662 249.014 211.31C249.014 210.957 248.727 210.671 248.373 210.671C248.019 210.671 247.732 210.957 247.732 211.31C247.732 211.662 248.019 211.948 248.373 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 214.236C248.727 214.236 249.014 213.95 249.014 213.598C249.014 213.245 248.727 212.959 248.373 212.959C248.019 212.959 247.732 213.245 247.732 213.598C247.732 213.95 248.019 214.236 248.373 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.533 197.581H244.636C244.529 197.581 244.369 197.474 244.369 197.315V195.665C244.369 195.558 244.476 195.399 244.636 195.399H248.533C248.639 195.399 248.8 195.505 248.8 195.665V197.315C248.746 197.474 248.639 197.581 248.533 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M257.394 193.909H250.188V226.156H257.394V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 207.319C255.666 207.319 255.953 207.033 255.953 206.68C255.953 206.327 255.666 206.041 255.312 206.041C254.959 206.041 254.672 206.327 254.672 206.68C254.672 207.033 254.959 207.319 255.312 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 209.66C255.666 209.66 255.953 209.374 255.953 209.021C255.953 208.669 255.666 208.383 255.312 208.383C254.959 208.383 254.672 208.669 254.672 209.021C254.672 209.374 254.959 209.66 255.312 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 211.948C255.666 211.948 255.953 211.662 255.953 211.31C255.953 210.957 255.666 210.671 255.312 210.671C254.959 210.671 254.672 210.957 254.672 211.31C254.672 211.662 254.959 211.948 255.312 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 214.236C255.666 214.236 255.953 213.95 255.953 213.598C255.953 213.245 255.666 212.959 255.312 212.959C254.959 212.959 254.672 213.245 254.672 213.598C254.672 213.95 254.959 214.236 255.312 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.74 197.581H251.843C251.736 197.581 251.576 197.474 251.576 197.315V195.665C251.576 195.558 251.683 195.399 251.843 195.399H255.74C255.847 195.399 256.007 195.505 256.007 195.665V197.315C255.953 197.474 255.847 197.581 255.74 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M264.493 193.909H257.287V226.156H264.493V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 207.319C263.139 207.319 263.426 207.033 263.426 206.68C263.426 206.327 263.139 206.041 262.785 206.041C262.431 206.041 262.145 206.327 262.145 206.68C262.145 207.033 262.431 207.319 262.785 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 209.66C263.139 209.66 263.426 209.374 263.426 209.021C263.426 208.669 263.139 208.383 262.785 208.383C262.431 208.383 262.145 208.669 262.145 209.021C262.145 209.374 262.431 209.66 262.785 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 211.948C263.139 211.948 263.426 211.662 263.426 211.31C263.426 210.957 263.139 210.671 262.785 210.671C262.431 210.671 262.145 210.957 262.145 211.31C262.145 211.662 262.431 211.948 262.785 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 214.236C263.139 214.236 263.426 213.95 263.426 213.598C263.426 213.245 263.139 212.959 262.785 212.959C262.431 212.959 262.145 213.245 262.145 213.598C262.145 213.95 262.431 214.236 262.785 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.892 197.581H258.995C258.889 197.581 258.729 197.474 258.729 197.315V195.665C258.729 195.558 258.835 195.399 258.995 195.399H262.892C262.999 195.399 263.159 195.505 263.159 195.665V197.315C263.106 197.474 262.999 197.581 262.892 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M271.7 193.909H264.494V226.156H271.7V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 207.319C270.078 207.319 270.365 207.033 270.365 206.68C270.365 206.327 270.078 206.041 269.725 206.041C269.371 206.041 269.084 206.327 269.084 206.68C269.084 207.033 269.371 207.319 269.725 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 209.66C270.078 209.66 270.365 209.374 270.365 209.021C270.365 208.669 270.078 208.383 269.725 208.383C269.371 208.383 269.084 208.669 269.084 209.021C269.084 209.374 269.371 209.66 269.725 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 211.948C270.078 211.948 270.365 211.662 270.365 211.31C270.365 210.957 270.078 210.671 269.725 210.671C269.371 210.671 269.084 210.957 269.084 211.31C269.084 211.662 269.371 211.948 269.725 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 214.236C270.078 214.236 270.365 213.95 270.365 213.598C270.365 213.245 270.078 212.959 269.725 212.959C269.371 212.959 269.084 213.245 269.084 213.598C269.084 213.95 269.371 214.236 269.725 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M270.046 197.581H266.15C266.043 197.581 265.883 197.474 265.883 197.315V195.665C265.883 195.558 265.99 195.399 266.15 195.399H270.046C270.153 195.399 270.313 195.505 270.313 195.665V197.315C270.26 197.474 270.153 197.581 270.046 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M278.853 193.909H271.646V226.156H278.853V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 207.319C277.551 207.319 277.838 207.033 277.838 206.68C277.838 206.327 277.551 206.041 277.197 206.041C276.843 206.041 276.557 206.327 276.557 206.68C276.557 207.033 276.843 207.319 277.197 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 209.66C277.551 209.66 277.838 209.374 277.838 209.021C277.838 208.669 277.551 208.383 277.197 208.383C276.843 208.383 276.557 208.669 276.557 209.021C276.557 209.374 276.843 209.66 277.197 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 211.948C277.551 211.948 277.838 211.662 277.838 211.31C277.838 210.957 277.551 210.671 277.197 210.671C276.843 210.671 276.557 210.957 276.557 211.31C276.557 211.662 276.843 211.948 277.197 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 214.236C277.551 214.236 277.838 213.95 277.838 213.598C277.838 213.245 277.551 212.959 277.197 212.959C276.843 212.959 276.557 213.245 276.557 213.598C276.557 213.95 276.843 214.236 277.197 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.199 197.581H273.302C273.195 197.581 273.035 197.474 273.035 197.315V195.665C273.035 195.558 273.142 195.399 273.302 195.399H277.199C277.305 195.399 277.466 195.505 277.466 195.665V197.315C277.466 197.474 277.359 197.581 277.199 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M286.006 193.909H223.285V226.156H286.006V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M286.06 193.909H278.854V226.156H286.06V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 207.319C284.492 207.319 284.779 207.033 284.779 206.68C284.779 206.327 284.492 206.041 284.139 206.041C283.785 206.041 283.498 206.327 283.498 206.68C283.498 207.033 283.785 207.319 284.139 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 209.66C284.492 209.66 284.779 209.374 284.779 209.021C284.779 208.669 284.492 208.383 284.139 208.383C283.785 208.383 283.498 208.669 283.498 209.021C283.498 209.374 283.785 209.66 284.139 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 211.948C284.492 211.948 284.779 211.662 284.779 211.31C284.779 210.957 284.492 210.671 284.139 210.671C283.785 210.671 283.498 210.957 283.498 211.31C283.498 211.662 283.785 211.948 284.139 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 214.236C284.492 214.236 284.779 213.95 284.779 213.598C284.779 213.245 284.492 212.959 284.139 212.959C283.785 212.959 283.498 213.245 283.498 213.598C283.498 213.95 283.785 214.236 284.139 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.406 197.581H280.509C280.402 197.581 280.242 197.474 280.242 197.315V195.665C280.242 195.558 280.349 195.399 280.509 195.399H284.406C284.513 195.399 284.673 195.505 284.673 195.665V197.315C284.619 197.474 284.513 197.581 284.406 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M242.929 193.909H223.285V226.156H242.929V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M225.902 213.225H224.674V216.258H225.902V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M225.902 217.589H224.674V220.622H225.902V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M225.902 221.952H224.674V224.985H225.902V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M242.929 193.909H223.285V204.871H242.929V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M240.687 196.676C241.041 196.676 241.328 196.39 241.328 196.037C241.328 195.685 241.041 195.399 240.687 195.399C240.334 195.399 240.047 195.685 240.047 196.037C240.047 196.39 240.334 196.676 240.687 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M250.241 237.012H243.035V269.259H250.241V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 250.421C248.727 250.421 249.014 250.135 249.014 249.783C249.014 249.43 248.727 249.144 248.373 249.144C248.019 249.144 247.732 249.43 247.732 249.783C247.732 250.135 248.019 250.421 248.373 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 252.763C248.727 252.763 249.014 252.477 249.014 252.124C249.014 251.771 248.727 251.486 248.373 251.486C248.019 251.486 247.732 251.771 247.732 252.124C247.732 252.477 248.019 252.763 248.373 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 255.051C248.727 255.051 249.014 254.765 249.014 254.412C249.014 254.06 248.727 253.774 248.373 253.774C248.019 253.774 247.732 254.06 247.732 254.412C247.732 254.765 248.019 255.051 248.373 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.373 257.339C248.727 257.339 249.014 257.053 249.014 256.7C249.014 256.348 248.727 256.062 248.373 256.062C248.019 256.062 247.732 256.348 247.732 256.7C247.732 257.053 248.019 257.339 248.373 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M248.533 240.683H244.636C244.529 240.683 244.369 240.577 244.369 240.417V238.768C244.369 238.661 244.476 238.502 244.636 238.502H248.533C248.639 238.502 248.8 238.608 248.8 238.768V240.417C248.746 240.577 248.639 240.683 248.533 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M257.394 237.012H250.188V269.259H257.394V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 250.421C255.666 250.421 255.953 250.135 255.953 249.783C255.953 249.43 255.666 249.144 255.312 249.144C254.959 249.144 254.672 249.43 254.672 249.783C254.672 250.135 254.959 250.421 255.312 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 252.763C255.666 252.763 255.953 252.477 255.953 252.124C255.953 251.771 255.666 251.486 255.312 251.486C254.959 251.486 254.672 251.771 254.672 252.124C254.672 252.477 254.959 252.763 255.312 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 255.051C255.666 255.051 255.953 254.765 255.953 254.412C255.953 254.06 255.666 253.774 255.312 253.774C254.959 253.774 254.672 254.06 254.672 254.412C254.672 254.765 254.959 255.051 255.312 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.312 257.339C255.666 257.339 255.953 257.053 255.953 256.7C255.953 256.348 255.666 256.062 255.312 256.062C254.959 256.062 254.672 256.348 254.672 256.7C254.672 257.053 254.959 257.339 255.312 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M255.74 240.683H251.843C251.736 240.683 251.576 240.577 251.576 240.417V238.768C251.576 238.661 251.683 238.502 251.843 238.502H255.74C255.847 238.502 256.007 238.608 256.007 238.768V240.417C255.953 240.577 255.847 240.683 255.74 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M264.493 237.012H257.287V269.259H264.493V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 250.421C263.139 250.421 263.426 250.135 263.426 249.783C263.426 249.43 263.139 249.144 262.785 249.144C262.431 249.144 262.145 249.43 262.145 249.783C262.145 250.135 262.431 250.421 262.785 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 252.763C263.139 252.763 263.426 252.477 263.426 252.124C263.426 251.771 263.139 251.486 262.785 251.486C262.431 251.486 262.145 251.771 262.145 252.124C262.145 252.477 262.431 252.763 262.785 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 255.051C263.139 255.051 263.426 254.765 263.426 254.412C263.426 254.06 263.139 253.774 262.785 253.774C262.431 253.774 262.145 254.06 262.145 254.412C262.145 254.765 262.431 255.051 262.785 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.785 257.339C263.139 257.339 263.426 257.053 263.426 256.7C263.426 256.348 263.139 256.062 262.785 256.062C262.431 256.062 262.145 256.348 262.145 256.7C262.145 257.053 262.431 257.339 262.785 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M262.892 240.683H258.995C258.889 240.683 258.729 240.577 258.729 240.417V238.768C258.729 238.661 258.835 238.502 258.995 238.502H262.892C262.999 238.502 263.159 238.608 263.159 238.768V240.417C263.106 240.577 262.999 240.683 262.892 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M271.7 237.012H264.494V269.259H271.7V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 250.421C270.078 250.421 270.365 250.135 270.365 249.783C270.365 249.43 270.078 249.144 269.725 249.144C269.371 249.144 269.084 249.43 269.084 249.783C269.084 250.135 269.371 250.421 269.725 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 252.763C270.078 252.763 270.365 252.477 270.365 252.124C270.365 251.771 270.078 251.486 269.725 251.486C269.371 251.486 269.084 251.771 269.084 252.124C269.084 252.477 269.371 252.763 269.725 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 255.051C270.078 255.051 270.365 254.765 270.365 254.412C270.365 254.06 270.078 253.774 269.725 253.774C269.371 253.774 269.084 254.06 269.084 254.412C269.084 254.765 269.371 255.051 269.725 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M269.725 257.339C270.078 257.339 270.365 257.053 270.365 256.7C270.365 256.348 270.078 256.062 269.725 256.062C269.371 256.062 269.084 256.348 269.084 256.7C269.084 257.053 269.371 257.339 269.725 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M270.046 240.683H266.15C266.043 240.683 265.883 240.577 265.883 240.417V238.768C265.883 238.661 265.99 238.502 266.15 238.502H270.046C270.153 238.502 270.313 238.608 270.313 238.768V240.417C270.26 240.577 270.153 240.683 270.046 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M278.853 237.012H271.646V269.259H278.853V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 250.421C277.551 250.421 277.838 250.135 277.838 249.783C277.838 249.43 277.551 249.144 277.197 249.144C276.843 249.144 276.557 249.43 276.557 249.783C276.557 250.135 276.843 250.421 277.197 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 252.763C277.551 252.763 277.838 252.477 277.838 252.124C277.838 251.771 277.551 251.486 277.197 251.486C276.843 251.486 276.557 251.771 276.557 252.124C276.557 252.477 276.843 252.763 277.197 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 255.051C277.551 255.051 277.838 254.765 277.838 254.412C277.838 254.06 277.551 253.774 277.197 253.774C276.843 253.774 276.557 254.06 276.557 254.412C276.557 254.765 276.843 255.051 277.197 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.197 257.339C277.551 257.339 277.838 257.053 277.838 256.7C277.838 256.348 277.551 256.062 277.197 256.062C276.843 256.062 276.557 256.348 276.557 256.7C276.557 257.053 276.843 257.339 277.197 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M277.199 240.683H273.302C273.195 240.683 273.035 240.577 273.035 240.417V238.768C273.035 238.661 273.142 238.502 273.302 238.502H277.199C277.305 238.502 277.466 238.608 277.466 238.768V240.417C277.466 240.577 277.359 240.683 277.199 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M286.006 237.012H223.285V269.259H286.006V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M286.06 237.012H278.854V269.259H286.06V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 250.421C284.492 250.421 284.779 250.135 284.779 249.783C284.779 249.43 284.492 249.144 284.139 249.144C283.785 249.144 283.498 249.43 283.498 249.783C283.498 250.135 283.785 250.421 284.139 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 252.763C284.492 252.763 284.779 252.477 284.779 252.124C284.779 251.771 284.492 251.486 284.139 251.486C283.785 251.486 283.498 251.771 283.498 252.124C283.498 252.477 283.785 252.763 284.139 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 255.051C284.492 255.051 284.779 254.765 284.779 254.412C284.779 254.06 284.492 253.774 284.139 253.774C283.785 253.774 283.498 254.06 283.498 254.412C283.498 254.765 283.785 255.051 284.139 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.139 257.339C284.492 257.339 284.779 257.053 284.779 256.7C284.779 256.348 284.492 256.062 284.139 256.062C283.785 256.062 283.498 256.348 283.498 256.7C283.498 257.053 283.785 257.339 284.139 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M284.406 240.683H280.509C280.402 240.683 280.242 240.577 280.242 240.417V238.768C280.242 238.661 280.349 238.502 280.509 238.502H284.406C284.513 238.502 284.673 238.608 284.673 238.768V240.417C284.619 240.577 284.513 240.683 284.406 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M242.929 237.012H223.285V269.259H242.929V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M225.902 256.328H224.674V259.361H225.902V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M225.902 260.691H224.674V263.725H225.902V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M225.902 265.055H224.674V268.088H225.902V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M242.929 237.012H223.285V247.973H242.929V237.012Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M240.687 239.779C241.041 239.779 241.328 239.493 241.328 239.14C241.328 238.787 241.041 238.502 240.687 238.502C240.334 238.502 240.047 238.787 240.047 239.14C240.047 239.493 240.334 239.779 240.687 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>


            <motion.path variants={pathVariants} custom={1} d="M596.138 193.909H588.932V226.156H596.138V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 207.319C594.623 207.319 594.91 207.033 594.91 206.68C594.91 206.327 594.623 206.041 594.269 206.041C593.916 206.041 593.629 206.327 593.629 206.68C593.629 207.033 593.916 207.319 594.269 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 209.66C594.623 209.66 594.91 209.374 594.91 209.021C594.91 208.669 594.623 208.383 594.269 208.383C593.916 208.383 593.629 208.669 593.629 209.021C593.629 209.374 593.916 209.66 594.269 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 211.948C594.623 211.948 594.91 211.662 594.91 211.31C594.91 210.957 594.623 210.671 594.269 210.671C593.916 210.671 593.629 210.957 593.629 211.31C593.629 211.662 593.916 211.948 594.269 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 214.236C594.623 214.236 594.91 213.95 594.91 213.598C594.91 213.245 594.623 212.959 594.269 212.959C593.916 212.959 593.629 213.245 593.629 213.598C593.629 213.95 593.916 214.236 594.269 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.429 197.581H590.533C590.426 197.581 590.266 197.474 590.266 197.315V195.665C590.266 195.558 590.372 195.399 590.533 195.399H594.429C594.536 195.399 594.696 195.505 594.696 195.665V197.315C594.643 197.474 594.536 197.581 594.429 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M603.29 193.909H596.084V226.156H603.29V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 207.319C601.563 207.319 601.849 207.033 601.849 206.68C601.849 206.327 601.563 206.041 601.209 206.041C600.855 206.041 600.568 206.327 600.568 206.68C600.568 207.033 600.855 207.319 601.209 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 209.66C601.563 209.66 601.849 209.374 601.849 209.021C601.849 208.669 601.563 208.383 601.209 208.383C600.855 208.383 600.568 208.669 600.568 209.021C600.568 209.374 600.855 209.66 601.209 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 211.948C601.563 211.948 601.849 211.662 601.849 211.31C601.849 210.957 601.563 210.671 601.209 210.671C600.855 210.671 600.568 210.957 600.568 211.31C600.568 211.662 600.855 211.948 601.209 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 214.236C601.563 214.236 601.849 213.95 601.849 213.598C601.849 213.245 601.563 212.959 601.209 212.959C600.855 212.959 600.568 213.245 600.568 213.598C600.568 213.95 600.855 214.236 601.209 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.636 197.581H597.74C597.633 197.581 597.473 197.474 597.473 197.315V195.665C597.473 195.558 597.579 195.399 597.74 195.399H601.636C601.743 195.399 601.903 195.505 601.903 195.665V197.315C601.85 197.474 601.743 197.581 601.636 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M610.39 193.909H603.184V226.156H610.39V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 207.319C609.035 207.319 609.322 207.033 609.322 206.68C609.322 206.327 609.035 206.041 608.682 206.041C608.328 206.041 608.041 206.327 608.041 206.68C608.041 207.033 608.328 207.319 608.682 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 209.66C609.035 209.66 609.322 209.374 609.322 209.021C609.322 208.669 609.035 208.383 608.682 208.383C608.328 208.383 608.041 208.669 608.041 209.021C608.041 209.374 608.328 209.66 608.682 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 211.948C609.035 211.948 609.322 211.662 609.322 211.31C609.322 210.957 609.035 210.671 608.682 210.671C608.328 210.671 608.041 210.957 608.041 211.31C608.041 211.662 608.328 211.948 608.682 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 214.236C609.035 214.236 609.322 213.95 609.322 213.598C609.322 213.245 609.035 212.959 608.682 212.959C608.328 212.959 608.041 213.245 608.041 213.598C608.041 213.95 608.328 214.236 608.682 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.789 197.581H604.892C604.785 197.581 604.625 197.474 604.625 197.315V195.665C604.625 195.558 604.732 195.399 604.892 195.399H608.789C608.895 195.399 609.055 195.505 609.055 195.665V197.315C609.002 197.474 608.895 197.581 608.789 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M617.597 193.909H610.391V226.156H617.597V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 207.319C615.975 207.319 616.262 207.033 616.262 206.68C616.262 206.327 615.975 206.041 615.621 206.041C615.267 206.041 614.98 206.327 614.98 206.68C614.98 207.033 615.267 207.319 615.621 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 209.66C615.975 209.66 616.262 209.374 616.262 209.021C616.262 208.669 615.975 208.383 615.621 208.383C615.267 208.383 614.98 208.669 614.98 209.021C614.98 209.374 615.267 209.66 615.621 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 211.948C615.975 211.948 616.262 211.662 616.262 211.31C616.262 210.957 615.975 210.671 615.621 210.671C615.267 210.671 614.98 210.957 614.98 211.31C614.98 211.662 615.267 211.948 615.621 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 214.236C615.975 214.236 616.262 213.95 616.262 213.598C616.262 213.245 615.975 212.959 615.621 212.959C615.267 212.959 614.98 213.245 614.98 213.598C614.98 213.95 615.267 214.236 615.621 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.943 197.581H612.046C611.939 197.581 611.779 197.474 611.779 197.315V195.665C611.779 195.558 611.886 195.399 612.046 195.399H615.943C616.05 195.399 616.21 195.505 616.21 195.665V197.315C616.156 197.474 616.05 197.581 615.943 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M624.749 193.909H617.543V226.156H624.749V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 207.319C623.447 207.319 623.734 207.033 623.734 206.68C623.734 206.327 623.447 206.041 623.094 206.041C622.74 206.041 622.453 206.327 622.453 206.68C622.453 207.033 622.74 207.319 623.094 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 209.66C623.447 209.66 623.734 209.374 623.734 209.021C623.734 208.669 623.447 208.383 623.094 208.383C622.74 208.383 622.453 208.669 622.453 209.021C622.453 209.374 622.74 209.66 623.094 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 211.948C623.447 211.948 623.734 211.662 623.734 211.31C623.734 210.957 623.447 210.671 623.094 210.671C622.74 210.671 622.453 210.957 622.453 211.31C622.453 211.662 622.74 211.948 623.094 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 214.236C623.447 214.236 623.734 213.95 623.734 213.598C623.734 213.245 623.447 212.959 623.094 212.959C622.74 212.959 622.453 213.245 622.453 213.598C622.453 213.95 622.74 214.236 623.094 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.095 197.581H619.199C619.092 197.581 618.932 197.474 618.932 197.315V195.665C618.932 195.558 619.038 195.399 619.199 195.399H623.095C623.202 195.399 623.362 195.505 623.362 195.665V197.315C623.362 197.474 623.255 197.581 623.095 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M631.902 193.909H569.182V226.156H631.902V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M631.956 193.909H624.75V226.156H631.956V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 207.319C630.389 207.319 630.676 207.033 630.676 206.68C630.676 206.327 630.389 206.041 630.035 206.041C629.681 206.041 629.395 206.327 629.395 206.68C629.395 207.033 629.681 207.319 630.035 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 209.66C630.389 209.66 630.676 209.374 630.676 209.021C630.676 208.669 630.389 208.383 630.035 208.383C629.681 208.383 629.395 208.669 629.395 209.021C629.395 209.374 629.681 209.66 630.035 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 211.948C630.389 211.948 630.676 211.662 630.676 211.31C630.676 210.957 630.389 210.671 630.035 210.671C629.681 210.671 629.395 210.957 629.395 211.31C629.395 211.662 629.681 211.948 630.035 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 214.236C630.389 214.236 630.676 213.95 630.676 213.598C630.676 213.245 630.389 212.959 630.035 212.959C629.681 212.959 629.395 213.245 629.395 213.598C629.395 213.95 629.681 214.236 630.035 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.302 197.581H626.406C626.299 197.581 626.139 197.474 626.139 197.315V195.665C626.139 195.558 626.245 195.399 626.406 195.399H630.302C630.409 195.399 630.569 195.505 630.569 195.665V197.315C630.516 197.474 630.409 197.581 630.302 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M588.825 193.909H569.182V226.156H588.825V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 213.225H570.57V216.258H571.798V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 217.589H570.57V220.622H571.798V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 221.952H570.57V224.985H571.798V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M588.825 193.909H569.182V204.871H588.825V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M586.584 196.676C586.938 196.676 587.224 196.39 587.224 196.037C587.224 195.685 586.938 195.399 586.584 195.399C586.23 195.399 585.943 195.685 585.943 196.037C585.943 196.39 586.23 196.676 586.584 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M596.138 237.012H588.932V269.259H596.138V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 250.421C594.623 250.421 594.91 250.135 594.91 249.783C594.91 249.43 594.623 249.144 594.269 249.144C593.916 249.144 593.629 249.43 593.629 249.783C593.629 250.135 593.916 250.421 594.269 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 252.763C594.623 252.763 594.91 252.477 594.91 252.124C594.91 251.771 594.623 251.486 594.269 251.486C593.916 251.486 593.629 251.771 593.629 252.124C593.629 252.477 593.916 252.763 594.269 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 255.051C594.623 255.051 594.91 254.765 594.91 254.412C594.91 254.06 594.623 253.774 594.269 253.774C593.916 253.774 593.629 254.06 593.629 254.412C593.629 254.765 593.916 255.051 594.269 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 257.339C594.623 257.339 594.91 257.053 594.91 256.7C594.91 256.348 594.623 256.062 594.269 256.062C593.916 256.062 593.629 256.348 593.629 256.7C593.629 257.053 593.916 257.339 594.269 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.429 240.683H590.533C590.426 240.683 590.266 240.577 590.266 240.417V238.768C590.266 238.661 590.372 238.502 590.533 238.502H594.429C594.536 238.502 594.696 238.608 594.696 238.768V240.417C594.643 240.577 594.536 240.683 594.429 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M603.29 237.012H596.084V269.259H603.29V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 250.421C601.563 250.421 601.849 250.135 601.849 249.783C601.849 249.43 601.563 249.144 601.209 249.144C600.855 249.144 600.568 249.43 600.568 249.783C600.568 250.135 600.855 250.421 601.209 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 252.763C601.563 252.763 601.849 252.477 601.849 252.124C601.849 251.771 601.563 251.486 601.209 251.486C600.855 251.486 600.568 251.771 600.568 252.124C600.568 252.477 600.855 252.763 601.209 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 255.051C601.563 255.051 601.849 254.765 601.849 254.412C601.849 254.06 601.563 253.774 601.209 253.774C600.855 253.774 600.568 254.06 600.568 254.412C600.568 254.765 600.855 255.051 601.209 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 257.339C601.563 257.339 601.849 257.053 601.849 256.7C601.849 256.348 601.563 256.062 601.209 256.062C600.855 256.062 600.568 256.348 600.568 256.7C600.568 257.053 600.855 257.339 601.209 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.636 240.683H597.74C597.633 240.683 597.473 240.577 597.473 240.417V238.768C597.473 238.661 597.579 238.502 597.74 238.502H601.636C601.743 238.502 601.903 238.608 601.903 238.768V240.417C601.85 240.577 601.743 240.683 601.636 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M610.39 237.012H603.184V269.259H610.39V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 250.421C609.035 250.421 609.322 250.135 609.322 249.783C609.322 249.43 609.035 249.144 608.682 249.144C608.328 249.144 608.041 249.43 608.041 249.783C608.041 250.135 608.328 250.421 608.682 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 252.763C609.035 252.763 609.322 252.477 609.322 252.124C609.322 251.771 609.035 251.486 608.682 251.486C608.328 251.486 608.041 251.771 608.041 252.124C608.041 252.477 608.328 252.763 608.682 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 255.051C609.035 255.051 609.322 254.765 609.322 254.412C609.322 254.06 609.035 253.774 608.682 253.774C608.328 253.774 608.041 254.06 608.041 254.412C608.041 254.765 608.328 255.051 608.682 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 257.339C609.035 257.339 609.322 257.053 609.322 256.7C609.322 256.348 609.035 256.062 608.682 256.062C608.328 256.062 608.041 256.348 608.041 256.7C608.041 257.053 608.328 257.339 608.682 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.789 240.683H604.892C604.785 240.683 604.625 240.577 604.625 240.417V238.768C604.625 238.661 604.732 238.502 604.892 238.502H608.789C608.895 238.502 609.055 238.608 609.055 238.768V240.417C609.002 240.577 608.895 240.683 608.789 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M617.597 237.012H610.391V269.259H617.597V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 250.421C615.975 250.421 616.262 250.135 616.262 249.783C616.262 249.43 615.975 249.144 615.621 249.144C615.267 249.144 614.98 249.43 614.98 249.783C614.98 250.135 615.267 250.421 615.621 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 252.763C615.975 252.763 616.262 252.477 616.262 252.124C616.262 251.771 615.975 251.486 615.621 251.486C615.267 251.486 614.98 251.771 614.98 252.124C614.98 252.477 615.267 252.763 615.621 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 255.051C615.975 255.051 616.262 254.765 616.262 254.412C616.262 254.06 615.975 253.774 615.621 253.774C615.267 253.774 614.98 254.06 614.98 254.412C614.98 254.765 615.267 255.051 615.621 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 257.339C615.975 257.339 616.262 257.053 616.262 256.7C616.262 256.348 615.975 256.062 615.621 256.062C615.267 256.062 614.98 256.348 614.98 256.7C614.98 257.053 615.267 257.339 615.621 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.943 240.683H612.046C611.939 240.683 611.779 240.577 611.779 240.417V238.768C611.779 238.661 611.886 238.502 612.046 238.502H615.943C616.05 238.502 616.21 238.608 616.21 238.768V240.417C616.156 240.577 616.05 240.683 615.943 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M624.749 237.012H617.543V269.259H624.749V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 250.421C623.447 250.421 623.734 250.135 623.734 249.783C623.734 249.43 623.447 249.144 623.094 249.144C622.74 249.144 622.453 249.43 622.453 249.783C622.453 250.135 622.74 250.421 623.094 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 252.763C623.447 252.763 623.734 252.477 623.734 252.124C623.734 251.771 623.447 251.486 623.094 251.486C622.74 251.486 622.453 251.771 622.453 252.124C622.453 252.477 622.74 252.763 623.094 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 255.051C623.447 255.051 623.734 254.765 623.734 254.412C623.734 254.06 623.447 253.774 623.094 253.774C622.74 253.774 622.453 254.06 622.453 254.412C622.453 254.765 622.74 255.051 623.094 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 257.339C623.447 257.339 623.734 257.053 623.734 256.7C623.734 256.348 623.447 256.062 623.094 256.062C622.74 256.062 622.453 256.348 622.453 256.7C622.453 257.053 622.74 257.339 623.094 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.095 240.683H619.199C619.092 240.683 618.932 240.577 618.932 240.417V238.768C618.932 238.661 619.038 238.502 619.199 238.502H623.095C623.202 238.502 623.362 238.608 623.362 238.768V240.417C623.362 240.577 623.255 240.683 623.095 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M631.902 237.012H569.182V269.259H631.902V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M631.956 237.012H624.75V269.259H631.956V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 250.421C630.389 250.421 630.676 250.135 630.676 249.783C630.676 249.43 630.389 249.144 630.035 249.144C629.681 249.144 629.395 249.43 629.395 249.783C629.395 250.135 629.681 250.421 630.035 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 252.763C630.389 252.763 630.676 252.477 630.676 252.124C630.676 251.771 630.389 251.486 630.035 251.486C629.681 251.486 629.395 251.771 629.395 252.124C629.395 252.477 629.681 252.763 630.035 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 255.051C630.389 255.051 630.676 254.765 630.676 254.412C630.676 254.06 630.389 253.774 630.035 253.774C629.681 253.774 629.395 254.06 629.395 254.412C629.395 254.765 629.681 255.051 630.035 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 257.339C630.389 257.339 630.676 257.053 630.676 256.7C630.676 256.348 630.389 256.062 630.035 256.062C629.681 256.062 629.395 256.348 629.395 256.7C629.395 257.053 629.681 257.339 630.035 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.302 240.683H626.406C626.299 240.683 626.139 240.577 626.139 240.417V238.768C626.139 238.661 626.245 238.502 626.406 238.502H630.302C630.409 238.502 630.569 238.608 630.569 238.768V240.417C630.516 240.577 630.409 240.683 630.302 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M588.825 237.012H569.182V269.259H588.825V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 256.328H570.57V259.361H571.798V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 260.691H570.57V263.725H571.798V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 265.055H570.57V268.088H571.798V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M588.825 237.012H569.182V247.973H588.825V237.012Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M586.584 239.779C586.938 239.779 587.224 239.493 587.224 239.14C587.224 238.787 586.938 238.502 586.584 238.502C586.23 238.502 585.943 238.787 585.943 239.14C585.943 239.493 586.23 239.779 586.584 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M596.138 280.114H588.932V312.361H596.138V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 293.524C594.623 293.524 594.91 293.238 594.91 292.885C594.91 292.533 594.623 292.247 594.269 292.247C593.916 292.247 593.629 292.533 593.629 292.885C593.629 293.238 593.916 293.524 594.269 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 295.865C594.623 295.865 594.91 295.579 594.91 295.227C594.91 294.874 594.623 294.588 594.269 294.588C593.916 294.588 593.629 294.874 593.629 295.227C593.629 295.579 593.916 295.865 594.269 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 298.154C594.623 298.154 594.91 297.868 594.91 297.515C594.91 297.162 594.623 296.876 594.269 296.876C593.916 296.876 593.629 297.162 593.629 297.515C593.629 297.868 593.916 298.154 594.269 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.269 300.442C594.623 300.442 594.91 300.156 594.91 299.803C594.91 299.45 594.623 299.165 594.269 299.165C593.916 299.165 593.629 299.45 593.629 299.803C593.629 300.156 593.916 300.442 594.269 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M594.429 283.786H590.533C590.426 283.786 590.266 283.68 590.266 283.52V281.87C590.266 281.764 590.372 281.604 590.533 281.604H594.429C594.536 281.604 594.696 281.711 594.696 281.87V283.52C594.643 283.68 594.536 283.786 594.429 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M603.29 280.114H596.084V312.361H603.29V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 293.524C601.563 293.524 601.849 293.238 601.849 292.885C601.849 292.533 601.563 292.247 601.209 292.247C600.855 292.247 600.568 292.533 600.568 292.885C600.568 293.238 600.855 293.524 601.209 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 295.865C601.563 295.865 601.849 295.579 601.849 295.227C601.849 294.874 601.563 294.588 601.209 294.588C600.855 294.588 600.568 294.874 600.568 295.227C600.568 295.579 600.855 295.865 601.209 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 298.154C601.563 298.154 601.849 297.868 601.849 297.515C601.849 297.162 601.563 296.876 601.209 296.876C600.855 296.876 600.568 297.162 600.568 297.515C600.568 297.868 600.855 298.154 601.209 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.209 300.442C601.563 300.442 601.849 300.156 601.849 299.803C601.849 299.45 601.563 299.165 601.209 299.165C600.855 299.165 600.568 299.45 600.568 299.803C600.568 300.156 600.855 300.442 601.209 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M601.636 283.786H597.74C597.633 283.786 597.473 283.68 597.473 283.52V281.87C597.473 281.764 597.579 281.604 597.74 281.604H601.636C601.743 281.604 601.903 281.711 601.903 281.87V283.52C601.85 283.68 601.743 283.786 601.636 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M610.39 280.114H603.184V312.361H610.39V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 293.524C609.035 293.524 609.322 293.238 609.322 292.885C609.322 292.533 609.035 292.247 608.682 292.247C608.328 292.247 608.041 292.533 608.041 292.885C608.041 293.238 608.328 293.524 608.682 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 295.865C609.035 295.865 609.322 295.579 609.322 295.227C609.322 294.874 609.035 294.588 608.682 294.588C608.328 294.588 608.041 294.874 608.041 295.227C608.041 295.579 608.328 295.865 608.682 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 298.154C609.035 298.154 609.322 297.868 609.322 297.515C609.322 297.162 609.035 296.876 608.682 296.876C608.328 296.876 608.041 297.162 608.041 297.515C608.041 297.868 608.328 298.154 608.682 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.682 300.442C609.035 300.442 609.322 300.156 609.322 299.803C609.322 299.45 609.035 299.165 608.682 299.165C608.328 299.165 608.041 299.45 608.041 299.803C608.041 300.156 608.328 300.442 608.682 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M608.789 283.786H604.892C604.785 283.786 604.625 283.68 604.625 283.52V281.87C604.625 281.764 604.732 281.604 604.892 281.604H608.789C608.895 281.604 609.055 281.711 609.055 281.87V283.52C609.002 283.68 608.895 283.786 608.789 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M617.597 280.114H610.391V312.361H617.597V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 293.524C615.975 293.524 616.262 293.238 616.262 292.885C616.262 292.533 615.975 292.247 615.621 292.247C615.267 292.247 614.98 292.533 614.98 292.885C614.98 293.238 615.267 293.524 615.621 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 295.865C615.975 295.865 616.262 295.579 616.262 295.227C616.262 294.874 615.975 294.588 615.621 294.588C615.267 294.588 614.98 294.874 614.98 295.227C614.98 295.579 615.267 295.865 615.621 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 298.154C615.975 298.154 616.262 297.868 616.262 297.515C616.262 297.162 615.975 296.876 615.621 296.876C615.267 296.876 614.98 297.162 614.98 297.515C614.98 297.868 615.267 298.154 615.621 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.621 300.442C615.975 300.442 616.262 300.156 616.262 299.803C616.262 299.45 615.975 299.165 615.621 299.165C615.267 299.165 614.98 299.45 614.98 299.803C614.98 300.156 615.267 300.442 615.621 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M615.943 283.786H612.046C611.939 283.786 611.779 283.68 611.779 283.52V281.87C611.779 281.764 611.886 281.604 612.046 281.604H615.943C616.05 281.604 616.21 281.711 616.21 281.87V283.52C616.156 283.68 616.05 283.786 615.943 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M624.749 280.114H617.543V312.361H624.749V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 293.524C623.447 293.524 623.734 293.238 623.734 292.885C623.734 292.533 623.447 292.247 623.094 292.247C622.74 292.247 622.453 292.533 622.453 292.885C622.453 293.238 622.74 293.524 623.094 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 295.865C623.447 295.865 623.734 295.579 623.734 295.227C623.734 294.874 623.447 294.588 623.094 294.588C622.74 294.588 622.453 294.874 622.453 295.227C622.453 295.579 622.74 295.865 623.094 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 298.154C623.447 298.154 623.734 297.868 623.734 297.515C623.734 297.162 623.447 296.876 623.094 296.876C622.74 296.876 622.453 297.162 622.453 297.515C622.453 297.868 622.74 298.154 623.094 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.094 300.442C623.447 300.442 623.734 300.156 623.734 299.803C623.734 299.45 623.447 299.165 623.094 299.165C622.74 299.165 622.453 299.45 622.453 299.803C622.453 300.156 622.74 300.442 623.094 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M623.095 283.786H619.199C619.092 283.786 618.932 283.68 618.932 283.52V281.87C618.932 281.764 619.038 281.604 619.199 281.604H623.095C623.202 281.604 623.362 281.711 623.362 281.87V283.52C623.362 283.68 623.255 283.786 623.095 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M631.902 280.114H569.182V312.361H631.902V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M631.956 280.114H624.75V312.361H631.956V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 293.524C630.389 293.524 630.676 293.238 630.676 292.885C630.676 292.533 630.389 292.247 630.035 292.247C629.681 292.247 629.395 292.533 629.395 292.885C629.395 293.238 629.681 293.524 630.035 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 295.865C630.389 295.865 630.676 295.579 630.676 295.227C630.676 294.874 630.389 294.588 630.035 294.588C629.681 294.588 629.395 294.874 629.395 295.227C629.395 295.579 629.681 295.865 630.035 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 298.154C630.389 298.154 630.676 297.868 630.676 297.515C630.676 297.162 630.389 296.876 630.035 296.876C629.681 296.876 629.395 297.162 629.395 297.515C629.395 297.868 629.681 298.154 630.035 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.035 300.442C630.389 300.442 630.676 300.156 630.676 299.803C630.676 299.45 630.389 299.165 630.035 299.165C629.681 299.165 629.395 299.45 629.395 299.803C629.395 300.156 629.681 300.442 630.035 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M630.302 283.786H626.406C626.299 283.786 626.139 283.68 626.139 283.52V281.87C626.139 281.764 626.245 281.604 626.406 281.604H630.302C630.409 281.604 630.569 281.711 630.569 281.87V283.52C630.516 283.68 630.409 283.786 630.302 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M588.825 280.114H569.182V312.361H588.825V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 299.431H570.57V302.464H571.798V299.431Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 303.794H570.57V306.827H571.798V303.794Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M571.798 308.158H570.57V311.191H571.798V308.158Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M588.825 280.114H569.182V291.076H588.825V280.114Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M586.584 282.881C586.938 282.881 587.224 282.595 587.224 282.243C587.224 281.89 586.938 281.604 586.584 281.604C586.23 281.604 585.943 281.89 585.943 282.243C585.943 282.595 586.23 282.881 586.584 282.881Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M687.95 193.909H680.744V226.156H687.95V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 207.319C686.436 207.319 686.723 207.033 686.723 206.68C686.723 206.327 686.436 206.041 686.082 206.041C685.728 206.041 685.441 206.327 685.441 206.68C685.441 207.033 685.728 207.319 686.082 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 209.66C686.436 209.66 686.723 209.374 686.723 209.021C686.723 208.669 686.436 208.383 686.082 208.383C685.728 208.383 685.441 208.669 685.441 209.021C685.441 209.374 685.728 209.66 686.082 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 211.948C686.436 211.948 686.723 211.662 686.723 211.31C686.723 210.957 686.436 210.671 686.082 210.671C685.728 210.671 685.441 210.957 685.441 211.31C685.441 211.662 685.728 211.948 686.082 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 214.236C686.436 214.236 686.723 213.95 686.723 213.598C686.723 213.245 686.436 212.959 686.082 212.959C685.728 212.959 685.441 213.245 685.441 213.598C685.441 213.95 685.728 214.236 686.082 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.242 197.581H682.345C682.238 197.581 682.078 197.474 682.078 197.315V195.665C682.078 195.558 682.185 195.399 682.345 195.399H686.242C686.348 195.399 686.509 195.505 686.509 195.665V197.315C686.455 197.474 686.348 197.581 686.242 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M695.103 193.909H687.896V226.156H695.103V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 207.319C693.375 207.319 693.662 207.033 693.662 206.68C693.662 206.327 693.375 206.041 693.021 206.041C692.668 206.041 692.381 206.327 692.381 206.68C692.381 207.033 692.668 207.319 693.021 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 209.66C693.375 209.66 693.662 209.374 693.662 209.021C693.662 208.669 693.375 208.383 693.021 208.383C692.668 208.383 692.381 208.669 692.381 209.021C692.381 209.374 692.668 209.66 693.021 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 211.948C693.375 211.948 693.662 211.662 693.662 211.31C693.662 210.957 693.375 210.671 693.021 210.671C692.668 210.671 692.381 210.957 692.381 211.31C692.381 211.662 692.668 211.948 693.021 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 214.236C693.375 214.236 693.662 213.95 693.662 213.598C693.662 213.245 693.375 212.959 693.021 212.959C692.668 212.959 692.381 213.245 692.381 213.598C692.381 213.95 692.668 214.236 693.021 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.449 197.581H689.552C689.445 197.581 689.285 197.474 689.285 197.315V195.665C689.285 195.558 689.392 195.399 689.552 195.399H693.449C693.555 195.399 693.716 195.505 693.716 195.665V197.315C693.662 197.474 693.555 197.581 693.449 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M702.202 193.909H694.996V226.156H702.202V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 207.319C700.848 207.319 701.135 207.033 701.135 206.68C701.135 206.327 700.848 206.041 700.494 206.041C700.14 206.041 699.854 206.327 699.854 206.68C699.854 207.033 700.14 207.319 700.494 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 209.66C700.848 209.66 701.135 209.374 701.135 209.021C701.135 208.669 700.848 208.383 700.494 208.383C700.14 208.383 699.854 208.669 699.854 209.021C699.854 209.374 700.14 209.66 700.494 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 211.948C700.848 211.948 701.135 211.662 701.135 211.31C701.135 210.957 700.848 210.671 700.494 210.671C700.14 210.671 699.854 210.957 699.854 211.31C699.854 211.662 700.14 211.948 700.494 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 214.236C700.848 214.236 701.135 213.95 701.135 213.598C701.135 213.245 700.848 212.959 700.494 212.959C700.14 212.959 699.854 213.245 699.854 213.598C699.854 213.95 700.14 214.236 700.494 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.601 197.581H696.704C696.598 197.581 696.438 197.474 696.438 197.315V195.665C696.438 195.558 696.544 195.399 696.704 195.399H700.601C700.708 195.399 700.868 195.505 700.868 195.665V197.315C700.815 197.474 700.708 197.581 700.601 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M709.409 193.909H702.203V226.156H709.409V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 207.319C707.787 207.319 708.074 207.033 708.074 206.68C708.074 206.327 707.787 206.041 707.434 206.041C707.08 206.041 706.793 206.327 706.793 206.68C706.793 207.033 707.08 207.319 707.434 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 209.66C707.787 209.66 708.074 209.374 708.074 209.021C708.074 208.669 707.787 208.383 707.434 208.383C707.08 208.383 706.793 208.669 706.793 209.021C706.793 209.374 707.08 209.66 707.434 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 211.948C707.787 211.948 708.074 211.662 708.074 211.31C708.074 210.957 707.787 210.671 707.434 210.671C707.08 210.671 706.793 210.957 706.793 211.31C706.793 211.662 707.08 211.948 707.434 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 214.236C707.787 214.236 708.074 213.95 708.074 213.598C708.074 213.245 707.787 212.959 707.434 212.959C707.08 212.959 706.793 213.245 706.793 213.598C706.793 213.95 707.08 214.236 707.434 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.755 197.581H703.859C703.752 197.581 703.592 197.474 703.592 197.315V195.665C703.592 195.558 703.699 195.399 703.859 195.399H707.755C707.862 195.399 708.022 195.505 708.022 195.665V197.315C707.969 197.474 707.862 197.581 707.755 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M716.562 193.909H709.355V226.156H716.562V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 207.319C715.26 207.319 715.547 207.033 715.547 206.68C715.547 206.327 715.26 206.041 714.906 206.041C714.552 206.041 714.266 206.327 714.266 206.68C714.266 207.033 714.552 207.319 714.906 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 209.66C715.26 209.66 715.547 209.374 715.547 209.021C715.547 208.669 715.26 208.383 714.906 208.383C714.552 208.383 714.266 208.669 714.266 209.021C714.266 209.374 714.552 209.66 714.906 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 211.948C715.26 211.948 715.547 211.662 715.547 211.31C715.547 210.957 715.26 210.671 714.906 210.671C714.552 210.671 714.266 210.957 714.266 211.31C714.266 211.662 714.552 211.948 714.906 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 214.236C715.26 214.236 715.547 213.95 715.547 213.598C715.547 213.245 715.26 212.959 714.906 212.959C714.552 212.959 714.266 213.245 714.266 213.598C714.266 213.95 714.552 214.236 714.906 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.908 197.581H711.011C710.904 197.581 710.744 197.474 710.744 197.315V195.665C710.744 195.558 710.851 195.399 711.011 195.399H714.908C715.015 195.399 715.175 195.505 715.175 195.665V197.315C715.175 197.474 715.068 197.581 714.908 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M723.715 193.909H660.994V226.156H723.715V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M723.769 193.909H716.562V226.156H723.769V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 207.319C722.201 207.319 722.488 207.033 722.488 206.68C722.488 206.327 722.201 206.041 721.848 206.041C721.494 206.041 721.207 206.327 721.207 206.68C721.207 207.033 721.494 207.319 721.848 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 209.66C722.201 209.66 722.488 209.374 722.488 209.021C722.488 208.669 722.201 208.383 721.848 208.383C721.494 208.383 721.207 208.669 721.207 209.021C721.207 209.374 721.494 209.66 721.848 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 211.948C722.201 211.948 722.488 211.662 722.488 211.31C722.488 210.957 722.201 210.671 721.848 210.671C721.494 210.671 721.207 210.957 721.207 211.31C721.207 211.662 721.494 211.948 721.848 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 214.236C722.201 214.236 722.488 213.95 722.488 213.598C722.488 213.245 722.201 212.959 721.848 212.959C721.494 212.959 721.207 213.245 721.207 213.598C721.207 213.95 721.494 214.236 721.848 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M722.115 197.581H718.218C718.111 197.581 717.951 197.474 717.951 197.315V195.665C717.951 195.558 718.058 195.399 718.218 195.399H722.115C722.222 195.399 722.382 195.505 722.382 195.665V197.315C722.328 197.474 722.222 197.581 722.115 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M680.638 193.909H660.994V226.156H680.638V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 213.225H662.383V216.258H663.61V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 217.589H662.383V220.622H663.61V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 221.952H662.383V224.985H663.61V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M680.638 193.909H660.994V204.871H680.638V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M678.396 196.676C678.75 196.676 679.037 196.39 679.037 196.037C679.037 195.685 678.75 195.399 678.396 195.399C678.043 195.399 677.756 195.685 677.756 196.037C677.756 196.39 678.043 196.676 678.396 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M687.95 237.012H680.744V269.259H687.95V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 250.421C686.436 250.421 686.723 250.135 686.723 249.783C686.723 249.43 686.436 249.144 686.082 249.144C685.728 249.144 685.441 249.43 685.441 249.783C685.441 250.135 685.728 250.421 686.082 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 252.763C686.436 252.763 686.723 252.477 686.723 252.124C686.723 251.771 686.436 251.486 686.082 251.486C685.728 251.486 685.441 251.771 685.441 252.124C685.441 252.477 685.728 252.763 686.082 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 255.051C686.436 255.051 686.723 254.765 686.723 254.412C686.723 254.06 686.436 253.774 686.082 253.774C685.728 253.774 685.441 254.06 685.441 254.412C685.441 254.765 685.728 255.051 686.082 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 257.339C686.436 257.339 686.723 257.053 686.723 256.7C686.723 256.348 686.436 256.062 686.082 256.062C685.728 256.062 685.441 256.348 685.441 256.7C685.441 257.053 685.728 257.339 686.082 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.242 240.683H682.345C682.238 240.683 682.078 240.577 682.078 240.417V238.768C682.078 238.661 682.185 238.502 682.345 238.502H686.242C686.348 238.502 686.509 238.608 686.509 238.768V240.417C686.455 240.577 686.348 240.683 686.242 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M695.103 237.012H687.896V269.259H695.103V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 250.421C693.375 250.421 693.662 250.135 693.662 249.783C693.662 249.43 693.375 249.144 693.021 249.144C692.668 249.144 692.381 249.43 692.381 249.783C692.381 250.135 692.668 250.421 693.021 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 252.763C693.375 252.763 693.662 252.477 693.662 252.124C693.662 251.771 693.375 251.486 693.021 251.486C692.668 251.486 692.381 251.771 692.381 252.124C692.381 252.477 692.668 252.763 693.021 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 255.051C693.375 255.051 693.662 254.765 693.662 254.412C693.662 254.06 693.375 253.774 693.021 253.774C692.668 253.774 692.381 254.06 692.381 254.412C692.381 254.765 692.668 255.051 693.021 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 257.339C693.375 257.339 693.662 257.053 693.662 256.7C693.662 256.348 693.375 256.062 693.021 256.062C692.668 256.062 692.381 256.348 692.381 256.7C692.381 257.053 692.668 257.339 693.021 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.449 240.683H689.552C689.445 240.683 689.285 240.577 689.285 240.417V238.768C689.285 238.661 689.392 238.502 689.552 238.502H693.449C693.555 238.502 693.716 238.608 693.716 238.768V240.417C693.662 240.577 693.555 240.683 693.449 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M702.202 237.012H694.996V269.259H702.202V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 250.421C700.848 250.421 701.135 250.135 701.135 249.783C701.135 249.43 700.848 249.144 700.494 249.144C700.14 249.144 699.854 249.43 699.854 249.783C699.854 250.135 700.14 250.421 700.494 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 252.763C700.848 252.763 701.135 252.477 701.135 252.124C701.135 251.771 700.848 251.486 700.494 251.486C700.14 251.486 699.854 251.771 699.854 252.124C699.854 252.477 700.14 252.763 700.494 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 255.051C700.848 255.051 701.135 254.765 701.135 254.412C701.135 254.06 700.848 253.774 700.494 253.774C700.14 253.774 699.854 254.06 699.854 254.412C699.854 254.765 700.14 255.051 700.494 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 257.339C700.848 257.339 701.135 257.053 701.135 256.7C701.135 256.348 700.848 256.062 700.494 256.062C700.14 256.062 699.854 256.348 699.854 256.7C699.854 257.053 700.14 257.339 700.494 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.601 240.683H696.704C696.598 240.683 696.438 240.577 696.438 240.417V238.768C696.438 238.661 696.544 238.502 696.704 238.502H700.601C700.708 238.502 700.868 238.608 700.868 238.768V240.417C700.815 240.577 700.708 240.683 700.601 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M709.409 237.012H702.203V269.259H709.409V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 250.421C707.787 250.421 708.074 250.135 708.074 249.783C708.074 249.43 707.787 249.144 707.434 249.144C707.08 249.144 706.793 249.43 706.793 249.783C706.793 250.135 707.08 250.421 707.434 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 252.763C707.787 252.763 708.074 252.477 708.074 252.124C708.074 251.771 707.787 251.486 707.434 251.486C707.08 251.486 706.793 251.771 706.793 252.124C706.793 252.477 707.08 252.763 707.434 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 255.051C707.787 255.051 708.074 254.765 708.074 254.412C708.074 254.06 707.787 253.774 707.434 253.774C707.08 253.774 706.793 254.06 706.793 254.412C706.793 254.765 707.08 255.051 707.434 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 257.339C707.787 257.339 708.074 257.053 708.074 256.7C708.074 256.348 707.787 256.062 707.434 256.062C707.08 256.062 706.793 256.348 706.793 256.7C706.793 257.053 707.08 257.339 707.434 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.755 240.683H703.859C703.752 240.683 703.592 240.577 703.592 240.417V238.768C703.592 238.661 703.699 238.502 703.859 238.502H707.755C707.862 238.502 708.022 238.608 708.022 238.768V240.417C707.969 240.577 707.862 240.683 707.755 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M716.562 237.012H709.355V269.259H716.562V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 250.421C715.26 250.421 715.547 250.135 715.547 249.783C715.547 249.43 715.26 249.144 714.906 249.144C714.552 249.144 714.266 249.43 714.266 249.783C714.266 250.135 714.552 250.421 714.906 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 252.763C715.26 252.763 715.547 252.477 715.547 252.124C715.547 251.771 715.26 251.486 714.906 251.486C714.552 251.486 714.266 251.771 714.266 252.124C714.266 252.477 714.552 252.763 714.906 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 255.051C715.26 255.051 715.547 254.765 715.547 254.412C715.547 254.06 715.26 253.774 714.906 253.774C714.552 253.774 714.266 254.06 714.266 254.412C714.266 254.765 714.552 255.051 714.906 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 257.339C715.26 257.339 715.547 257.053 715.547 256.7C715.547 256.348 715.26 256.062 714.906 256.062C714.552 256.062 714.266 256.348 714.266 256.7C714.266 257.053 714.552 257.339 714.906 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.908 240.683H711.011C710.904 240.683 710.744 240.577 710.744 240.417V238.768C710.744 238.661 710.851 238.502 711.011 238.502H714.908C715.015 238.502 715.175 238.608 715.175 238.768V240.417C715.175 240.577 715.068 240.683 714.908 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M723.715 237.012H660.994V269.259H723.715V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M723.769 237.012H716.562V269.259H723.769V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 250.421C722.201 250.421 722.488 250.135 722.488 249.783C722.488 249.43 722.201 249.144 721.848 249.144C721.494 249.144 721.207 249.43 721.207 249.783C721.207 250.135 721.494 250.421 721.848 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 252.763C722.201 252.763 722.488 252.477 722.488 252.124C722.488 251.771 722.201 251.486 721.848 251.486C721.494 251.486 721.207 251.771 721.207 252.124C721.207 252.477 721.494 252.763 721.848 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 255.051C722.201 255.051 722.488 254.765 722.488 254.412C722.488 254.06 722.201 253.774 721.848 253.774C721.494 253.774 721.207 254.06 721.207 254.412C721.207 254.765 721.494 255.051 721.848 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 257.339C722.201 257.339 722.488 257.053 722.488 256.7C722.488 256.348 722.201 256.062 721.848 256.062C721.494 256.062 721.207 256.348 721.207 256.7C721.207 257.053 721.494 257.339 721.848 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M722.115 240.683H718.218C718.111 240.683 717.951 240.577 717.951 240.417V238.768C717.951 238.661 718.058 238.502 718.218 238.502H722.115C722.222 238.502 722.382 238.608 722.382 238.768V240.417C722.328 240.577 722.222 240.683 722.115 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M680.638 237.012H660.994V269.259H680.638V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 256.328H662.383V259.361H663.61V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 260.691H662.383V263.725H663.61V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 265.055H662.383V268.088H663.61V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M680.638 237.012H660.994V247.973H680.638V237.012Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M678.396 239.779C678.75 239.779 679.037 239.493 679.037 239.14C679.037 238.787 678.75 238.502 678.396 238.502C678.043 238.502 677.756 238.787 677.756 239.14C677.756 239.493 678.043 239.779 678.396 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M687.95 280.114H680.744V312.361H687.95V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 293.524C686.436 293.524 686.723 293.238 686.723 292.885C686.723 292.533 686.436 292.247 686.082 292.247C685.728 292.247 685.441 292.533 685.441 292.885C685.441 293.238 685.728 293.524 686.082 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 295.865C686.436 295.865 686.723 295.579 686.723 295.227C686.723 294.874 686.436 294.588 686.082 294.588C685.728 294.588 685.441 294.874 685.441 295.227C685.441 295.579 685.728 295.865 686.082 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 298.154C686.436 298.154 686.723 297.868 686.723 297.515C686.723 297.162 686.436 296.876 686.082 296.876C685.728 296.876 685.441 297.162 685.441 297.515C685.441 297.868 685.728 298.154 686.082 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.082 300.442C686.436 300.442 686.723 300.156 686.723 299.803C686.723 299.45 686.436 299.165 686.082 299.165C685.728 299.165 685.441 299.45 685.441 299.803C685.441 300.156 685.728 300.442 686.082 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M686.242 283.786H682.345C682.238 283.786 682.078 283.68 682.078 283.52V281.87C682.078 281.764 682.185 281.604 682.345 281.604H686.242C686.348 281.604 686.509 281.711 686.509 281.87V283.52C686.455 283.68 686.348 283.786 686.242 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M695.103 280.114H687.896V312.361H695.103V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 293.524C693.375 293.524 693.662 293.238 693.662 292.885C693.662 292.533 693.375 292.247 693.021 292.247C692.668 292.247 692.381 292.533 692.381 292.885C692.381 293.238 692.668 293.524 693.021 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 295.865C693.375 295.865 693.662 295.579 693.662 295.227C693.662 294.874 693.375 294.588 693.021 294.588C692.668 294.588 692.381 294.874 692.381 295.227C692.381 295.579 692.668 295.865 693.021 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 298.154C693.375 298.154 693.662 297.868 693.662 297.515C693.662 297.162 693.375 296.876 693.021 296.876C692.668 296.876 692.381 297.162 692.381 297.515C692.381 297.868 692.668 298.154 693.021 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.021 300.442C693.375 300.442 693.662 300.156 693.662 299.803C693.662 299.45 693.375 299.165 693.021 299.165C692.668 299.165 692.381 299.45 692.381 299.803C692.381 300.156 692.668 300.442 693.021 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M693.449 283.786H689.552C689.445 283.786 689.285 283.68 689.285 283.52V281.87C689.285 281.764 689.392 281.604 689.552 281.604H693.449C693.555 281.604 693.716 281.711 693.716 281.87V283.52C693.662 283.68 693.555 283.786 693.449 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M702.202 280.114H694.996V312.361H702.202V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 293.524C700.848 293.524 701.135 293.238 701.135 292.885C701.135 292.533 700.848 292.247 700.494 292.247C700.14 292.247 699.854 292.533 699.854 292.885C699.854 293.238 700.14 293.524 700.494 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 295.865C700.848 295.865 701.135 295.579 701.135 295.227C701.135 294.874 700.848 294.588 700.494 294.588C700.14 294.588 699.854 294.874 699.854 295.227C699.854 295.579 700.14 295.865 700.494 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 298.154C700.848 298.154 701.135 297.868 701.135 297.515C701.135 297.162 700.848 296.876 700.494 296.876C700.14 296.876 699.854 297.162 699.854 297.515C699.854 297.868 700.14 298.154 700.494 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.494 300.442C700.848 300.442 701.135 300.156 701.135 299.803C701.135 299.45 700.848 299.165 700.494 299.165C700.14 299.165 699.854 299.45 699.854 299.803C699.854 300.156 700.14 300.442 700.494 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M700.601 283.786H696.704C696.598 283.786 696.438 283.68 696.438 283.52V281.87C696.438 281.764 696.544 281.604 696.704 281.604H700.601C700.708 281.604 700.868 281.711 700.868 281.87V283.52C700.815 283.68 700.708 283.786 700.601 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M709.409 280.114H702.203V312.361H709.409V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 293.524C707.787 293.524 708.074 293.238 708.074 292.885C708.074 292.533 707.787 292.247 707.434 292.247C707.08 292.247 706.793 292.533 706.793 292.885C706.793 293.238 707.08 293.524 707.434 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 295.865C707.787 295.865 708.074 295.579 708.074 295.227C708.074 294.874 707.787 294.588 707.434 294.588C707.08 294.588 706.793 294.874 706.793 295.227C706.793 295.579 707.08 295.865 707.434 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 298.154C707.787 298.154 708.074 297.868 708.074 297.515C708.074 297.162 707.787 296.876 707.434 296.876C707.08 296.876 706.793 297.162 706.793 297.515C706.793 297.868 707.08 298.154 707.434 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.434 300.442C707.787 300.442 708.074 300.156 708.074 299.803C708.074 299.45 707.787 299.165 707.434 299.165C707.08 299.165 706.793 299.45 706.793 299.803C706.793 300.156 707.08 300.442 707.434 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M707.755 283.786H703.859C703.752 283.786 703.592 283.68 703.592 283.52V281.87C703.592 281.764 703.699 281.604 703.859 281.604H707.755C707.862 281.604 708.022 281.711 708.022 281.87V283.52C707.969 283.68 707.862 283.786 707.755 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M716.562 280.114H709.355V312.361H716.562V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 293.524C715.26 293.524 715.547 293.238 715.547 292.885C715.547 292.533 715.26 292.247 714.906 292.247C714.552 292.247 714.266 292.533 714.266 292.885C714.266 293.238 714.552 293.524 714.906 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 295.865C715.26 295.865 715.547 295.579 715.547 295.227C715.547 294.874 715.26 294.588 714.906 294.588C714.552 294.588 714.266 294.874 714.266 295.227C714.266 295.579 714.552 295.865 714.906 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 298.154C715.26 298.154 715.547 297.868 715.547 297.515C715.547 297.162 715.26 296.876 714.906 296.876C714.552 296.876 714.266 297.162 714.266 297.515C714.266 297.868 714.552 298.154 714.906 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.906 300.442C715.26 300.442 715.547 300.156 715.547 299.803C715.547 299.45 715.26 299.165 714.906 299.165C714.552 299.165 714.266 299.45 714.266 299.803C714.266 300.156 714.552 300.442 714.906 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M714.908 283.786H711.011C710.904 283.786 710.744 283.68 710.744 283.52V281.87C710.744 281.764 710.851 281.604 711.011 281.604H714.908C715.015 281.604 715.175 281.711 715.175 281.87V283.52C715.175 283.68 715.068 283.786 714.908 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M723.715 280.114H660.994V312.361H723.715V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M723.769 280.114H716.562V312.361H723.769V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 293.524C722.201 293.524 722.488 293.238 722.488 292.885C722.488 292.533 722.201 292.247 721.848 292.247C721.494 292.247 721.207 292.533 721.207 292.885C721.207 293.238 721.494 293.524 721.848 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 295.865C722.201 295.865 722.488 295.579 722.488 295.227C722.488 294.874 722.201 294.588 721.848 294.588C721.494 294.588 721.207 294.874 721.207 295.227C721.207 295.579 721.494 295.865 721.848 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 298.154C722.201 298.154 722.488 297.868 722.488 297.515C722.488 297.162 722.201 296.876 721.848 296.876C721.494 296.876 721.207 297.162 721.207 297.515C721.207 297.868 721.494 298.154 721.848 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M721.848 300.442C722.201 300.442 722.488 300.156 722.488 299.803C722.488 299.45 722.201 299.165 721.848 299.165C721.494 299.165 721.207 299.45 721.207 299.803C721.207 300.156 721.494 300.442 721.848 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M722.115 283.786H718.218C718.111 283.786 717.951 283.68 717.951 283.52V281.87C717.951 281.764 718.058 281.604 718.218 281.604H722.115C722.222 281.604 722.382 281.711 722.382 281.87V283.52C722.328 283.68 722.222 283.786 722.115 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M680.638 280.114H660.994V312.361H680.638V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 299.431H662.383V302.464H663.61V299.431Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 303.794H662.383V306.827H663.61V303.794Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M663.61 308.158H662.383V311.191H663.61V308.158Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M680.638 280.114H660.994V291.076H680.638V280.114Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M678.396 282.881C678.75 282.881 679.037 282.595 679.037 282.243C679.037 281.89 678.75 281.604 678.396 281.604C678.043 281.604 677.756 281.89 677.756 282.243C677.756 282.595 678.043 282.881 678.396 282.881Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M779.23 193.909H772.023V226.156H779.23V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 207.319C777.715 207.319 778.002 207.033 778.002 206.68C778.002 206.327 777.715 206.041 777.361 206.041C777.008 206.041 776.721 206.327 776.721 206.68C776.721 207.033 777.008 207.319 777.361 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 209.66C777.715 209.66 778.002 209.374 778.002 209.021C778.002 208.669 777.715 208.383 777.361 208.383C777.008 208.383 776.721 208.669 776.721 209.021C776.721 209.374 777.008 209.66 777.361 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 211.948C777.715 211.948 778.002 211.662 778.002 211.31C778.002 210.957 777.715 210.671 777.361 210.671C777.008 210.671 776.721 210.957 776.721 211.31C776.721 211.662 777.008 211.948 777.361 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 214.236C777.715 214.236 778.002 213.95 778.002 213.598C778.002 213.245 777.715 212.959 777.361 212.959C777.008 212.959 776.721 213.245 776.721 213.598C776.721 213.95 777.008 214.236 777.361 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.521 197.581H773.624C773.518 197.581 773.357 197.474 773.357 197.315V195.665C773.357 195.558 773.464 195.399 773.624 195.399H777.521C777.628 195.399 777.788 195.505 777.788 195.665V197.315C777.734 197.474 777.628 197.581 777.521 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M786.382 193.909H779.176V226.156H786.382V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 207.319C784.655 207.319 784.941 207.033 784.941 206.68C784.941 206.327 784.655 206.041 784.301 206.041C783.947 206.041 783.66 206.327 783.66 206.68C783.66 207.033 783.947 207.319 784.301 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 209.66C784.655 209.66 784.941 209.374 784.941 209.021C784.941 208.669 784.655 208.383 784.301 208.383C783.947 208.383 783.66 208.669 783.66 209.021C783.66 209.374 783.947 209.66 784.301 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 211.948C784.655 211.948 784.941 211.662 784.941 211.31C784.941 210.957 784.655 210.671 784.301 210.671C783.947 210.671 783.66 210.957 783.66 211.31C783.66 211.662 783.947 211.948 784.301 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 214.236C784.655 214.236 784.941 213.95 784.941 213.598C784.941 213.245 784.655 212.959 784.301 212.959C783.947 212.959 783.66 213.245 783.66 213.598C783.66 213.95 783.947 214.236 784.301 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.728 197.581H780.831C780.725 197.581 780.564 197.474 780.564 197.315V195.665C780.564 195.558 780.671 195.399 780.831 195.399H784.728C784.835 195.399 784.995 195.505 784.995 195.665V197.315C784.942 197.474 784.835 197.581 784.728 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M793.482 193.909H786.275V226.156H793.482V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 207.319C792.127 207.319 792.414 207.033 792.414 206.68C792.414 206.327 792.127 206.041 791.773 206.041C791.42 206.041 791.133 206.327 791.133 206.68C791.133 207.033 791.42 207.319 791.773 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 209.66C792.127 209.66 792.414 209.374 792.414 209.021C792.414 208.669 792.127 208.383 791.773 208.383C791.42 208.383 791.133 208.669 791.133 209.021C791.133 209.374 791.42 209.66 791.773 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 211.948C792.127 211.948 792.414 211.662 792.414 211.31C792.414 210.957 792.127 210.671 791.773 210.671C791.42 210.671 791.133 210.957 791.133 211.31C791.133 211.662 791.42 211.948 791.773 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 214.236C792.127 214.236 792.414 213.95 792.414 213.598C792.414 213.245 792.127 212.959 791.773 212.959C791.42 212.959 791.133 213.245 791.133 213.598C791.133 213.95 791.42 214.236 791.773 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.88 197.581H787.984C787.877 197.581 787.717 197.474 787.717 197.315V195.665C787.717 195.558 787.824 195.399 787.984 195.399H791.88C791.987 195.399 792.147 195.505 792.147 195.665V197.315C792.094 197.474 791.987 197.581 791.88 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M800.689 193.909H793.482V226.156H800.689V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 207.319C799.067 207.319 799.353 207.033 799.353 206.68C799.353 206.327 799.067 206.041 798.713 206.041C798.359 206.041 798.072 206.327 798.072 206.68C798.072 207.033 798.359 207.319 798.713 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 209.66C799.067 209.66 799.353 209.374 799.353 209.021C799.353 208.669 799.067 208.383 798.713 208.383C798.359 208.383 798.072 208.669 798.072 209.021C798.072 209.374 798.359 209.66 798.713 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 211.948C799.067 211.948 799.353 211.662 799.353 211.31C799.353 210.957 799.067 210.671 798.713 210.671C798.359 210.671 798.072 210.957 798.072 211.31C798.072 211.662 798.359 211.948 798.713 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 214.236C799.067 214.236 799.353 213.95 799.353 213.598C799.353 213.245 799.067 212.959 798.713 212.959C798.359 212.959 798.072 213.245 798.072 213.598C798.072 213.95 798.359 214.236 798.713 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M799.035 197.581H795.138C795.031 197.581 794.871 197.474 794.871 197.315V195.665C794.871 195.558 794.978 195.399 795.138 195.399H799.035C799.141 195.399 799.302 195.505 799.302 195.665V197.315C799.248 197.474 799.141 197.581 799.035 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M807.841 193.909H800.635V226.156H807.841V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 207.319C806.539 207.319 806.826 207.033 806.826 206.68C806.826 206.327 806.539 206.041 806.186 206.041C805.832 206.041 805.545 206.327 805.545 206.68C805.545 207.033 805.832 207.319 806.186 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 209.66C806.539 209.66 806.826 209.374 806.826 209.021C806.826 208.669 806.539 208.383 806.186 208.383C805.832 208.383 805.545 208.669 805.545 209.021C805.545 209.374 805.832 209.66 806.186 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 211.948C806.539 211.948 806.826 211.662 806.826 211.31C806.826 210.957 806.539 210.671 806.186 210.671C805.832 210.671 805.545 210.957 805.545 211.31C805.545 211.662 805.832 211.948 806.186 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 214.236C806.539 214.236 806.826 213.95 806.826 213.598C806.826 213.245 806.539 212.959 806.186 212.959C805.832 212.959 805.545 213.245 805.545 213.598C805.545 213.95 805.832 214.236 806.186 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.187 197.581H802.29C802.184 197.581 802.023 197.474 802.023 197.315V195.665C802.023 195.558 802.13 195.399 802.29 195.399H806.187C806.294 195.399 806.454 195.505 806.454 195.665V197.315C806.454 197.474 806.347 197.581 806.187 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M814.994 193.909H752.273V226.156H814.994V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M815.048 193.909H807.842V226.156H815.048V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 207.319C813.481 207.319 813.767 207.033 813.767 206.68C813.767 206.327 813.481 206.041 813.127 206.041C812.773 206.041 812.486 206.327 812.486 206.68C812.486 207.033 812.773 207.319 813.127 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 209.66C813.481 209.66 813.767 209.374 813.767 209.021C813.767 208.669 813.481 208.383 813.127 208.383C812.773 208.383 812.486 208.669 812.486 209.021C812.486 209.374 812.773 209.66 813.127 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 211.948C813.481 211.948 813.767 211.662 813.767 211.31C813.767 210.957 813.481 210.671 813.127 210.671C812.773 210.671 812.486 210.957 812.486 211.31C812.486 211.662 812.773 211.948 813.127 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 214.236C813.481 214.236 813.767 213.95 813.767 213.598C813.767 213.245 813.481 212.959 813.127 212.959C812.773 212.959 812.486 213.245 812.486 213.598C812.486 213.95 812.773 214.236 813.127 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.394 197.581H809.497C809.391 197.581 809.23 197.474 809.23 197.315V195.665C809.23 195.558 809.337 195.399 809.497 195.399H813.394C813.501 195.399 813.661 195.505 813.661 195.665V197.315C813.608 197.474 813.501 197.581 813.394 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M771.917 193.909H752.273V226.156H771.917V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M754.89 213.225H753.662V216.258H754.89V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M754.89 217.589H753.662V220.622H754.89V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M754.89 221.952H753.662V224.985H754.89V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M771.917 193.909H752.273V204.871H771.917V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M769.676 196.676C770.03 196.676 770.316 196.39 770.316 196.037C770.316 195.685 770.03 195.399 769.676 195.399C769.322 195.399 769.035 195.685 769.035 196.037C769.035 196.39 769.322 196.676 769.676 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M779.23 237.012H772.023V269.259H779.23V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 250.421C777.715 250.421 778.002 250.135 778.002 249.783C778.002 249.43 777.715 249.144 777.361 249.144C777.008 249.144 776.721 249.43 776.721 249.783C776.721 250.135 777.008 250.421 777.361 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 252.763C777.715 252.763 778.002 252.477 778.002 252.124C778.002 251.771 777.715 251.486 777.361 251.486C777.008 251.486 776.721 251.771 776.721 252.124C776.721 252.477 777.008 252.763 777.361 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 255.051C777.715 255.051 778.002 254.765 778.002 254.412C778.002 254.06 777.715 253.774 777.361 253.774C777.008 253.774 776.721 254.06 776.721 254.412C776.721 254.765 777.008 255.051 777.361 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.361 257.339C777.715 257.339 778.002 257.053 778.002 256.7C778.002 256.348 777.715 256.062 777.361 256.062C777.008 256.062 776.721 256.348 776.721 256.7C776.721 257.053 777.008 257.339 777.361 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M777.521 240.683H773.624C773.518 240.683 773.357 240.577 773.357 240.417V238.768C773.357 238.661 773.464 238.502 773.624 238.502H777.521C777.628 238.502 777.788 238.608 777.788 238.768V240.417C777.734 240.577 777.628 240.683 777.521 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M786.382 237.012H779.176V269.259H786.382V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 250.421C784.655 250.421 784.941 250.135 784.941 249.783C784.941 249.43 784.655 249.144 784.301 249.144C783.947 249.144 783.66 249.43 783.66 249.783C783.66 250.135 783.947 250.421 784.301 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 252.763C784.655 252.763 784.941 252.477 784.941 252.124C784.941 251.771 784.655 251.486 784.301 251.486C783.947 251.486 783.66 251.771 783.66 252.124C783.66 252.477 783.947 252.763 784.301 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 255.051C784.655 255.051 784.941 254.765 784.941 254.412C784.941 254.06 784.655 253.774 784.301 253.774C783.947 253.774 783.66 254.06 783.66 254.412C783.66 254.765 783.947 255.051 784.301 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.301 257.339C784.655 257.339 784.941 257.053 784.941 256.7C784.941 256.348 784.655 256.062 784.301 256.062C783.947 256.062 783.66 256.348 783.66 256.7C783.66 257.053 783.947 257.339 784.301 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M784.728 240.683H780.831C780.725 240.683 780.564 240.577 780.564 240.417V238.768C780.564 238.661 780.671 238.502 780.831 238.502H784.728C784.835 238.502 784.995 238.608 784.995 238.768V240.417C784.942 240.577 784.835 240.683 784.728 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M793.482 237.012H786.275V269.259H793.482V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 250.421C792.127 250.421 792.414 250.135 792.414 249.783C792.414 249.43 792.127 249.144 791.773 249.144C791.42 249.144 791.133 249.43 791.133 249.783C791.133 250.135 791.42 250.421 791.773 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 252.763C792.127 252.763 792.414 252.477 792.414 252.124C792.414 251.771 792.127 251.486 791.773 251.486C791.42 251.486 791.133 251.771 791.133 252.124C791.133 252.477 791.42 252.763 791.773 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 255.051C792.127 255.051 792.414 254.765 792.414 254.412C792.414 254.06 792.127 253.774 791.773 253.774C791.42 253.774 791.133 254.06 791.133 254.412C791.133 254.765 791.42 255.051 791.773 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.773 257.339C792.127 257.339 792.414 257.053 792.414 256.7C792.414 256.348 792.127 256.062 791.773 256.062C791.42 256.062 791.133 256.348 791.133 256.7C791.133 257.053 791.42 257.339 791.773 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M791.88 240.683H787.984C787.877 240.683 787.717 240.577 787.717 240.417V238.768C787.717 238.661 787.824 238.502 787.984 238.502H791.88C791.987 238.502 792.147 238.608 792.147 238.768V240.417C792.094 240.577 791.987 240.683 791.88 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M800.689 237.012H793.482V269.259H800.689V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 250.421C799.067 250.421 799.353 250.135 799.353 249.783C799.353 249.43 799.067 249.144 798.713 249.144C798.359 249.144 798.072 249.43 798.072 249.783C798.072 250.135 798.359 250.421 798.713 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 252.763C799.067 252.763 799.353 252.477 799.353 252.124C799.353 251.771 799.067 251.486 798.713 251.486C798.359 251.486 798.072 251.771 798.072 252.124C798.072 252.477 798.359 252.763 798.713 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 255.051C799.067 255.051 799.353 254.765 799.353 254.412C799.353 254.06 799.067 253.774 798.713 253.774C798.359 253.774 798.072 254.06 798.072 254.412C798.072 254.765 798.359 255.051 798.713 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M798.713 257.339C799.067 257.339 799.353 257.053 799.353 256.7C799.353 256.348 799.067 256.062 798.713 256.062C798.359 256.062 798.072 256.348 798.072 256.7C798.072 257.053 798.359 257.339 798.713 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M799.035 240.683H795.138C795.031 240.683 794.871 240.577 794.871 240.417V238.768C794.871 238.661 794.978 238.502 795.138 238.502H799.035C799.141 238.502 799.302 238.608 799.302 238.768V240.417C799.248 240.577 799.141 240.683 799.035 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M807.841 237.012H800.635V269.259H807.841V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 250.421C806.539 250.421 806.826 250.135 806.826 249.783C806.826 249.43 806.539 249.144 806.186 249.144C805.832 249.144 805.545 249.43 805.545 249.783C805.545 250.135 805.832 250.421 806.186 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 252.763C806.539 252.763 806.826 252.477 806.826 252.124C806.826 251.771 806.539 251.486 806.186 251.486C805.832 251.486 805.545 251.771 805.545 252.124C805.545 252.477 805.832 252.763 806.186 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 255.051C806.539 255.051 806.826 254.765 806.826 254.412C806.826 254.06 806.539 253.774 806.186 253.774C805.832 253.774 805.545 254.06 805.545 254.412C805.545 254.765 805.832 255.051 806.186 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.186 257.339C806.539 257.339 806.826 257.053 806.826 256.7C806.826 256.348 806.539 256.062 806.186 256.062C805.832 256.062 805.545 256.348 805.545 256.7C805.545 257.053 805.832 257.339 806.186 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M806.187 240.683H802.29C802.184 240.683 802.023 240.577 802.023 240.417V238.768C802.023 238.661 802.13 238.502 802.29 238.502H806.187C806.294 238.502 806.454 238.608 806.454 238.768V240.417C806.454 240.577 806.347 240.683 806.187 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M814.994 237.012H752.273V269.259H814.994V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M815.048 237.012H807.842V269.259H815.048V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 250.421C813.481 250.421 813.767 250.135 813.767 249.783C813.767 249.43 813.481 249.144 813.127 249.144C812.773 249.144 812.486 249.43 812.486 249.783C812.486 250.135 812.773 250.421 813.127 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 252.763C813.481 252.763 813.767 252.477 813.767 252.124C813.767 251.771 813.481 251.486 813.127 251.486C812.773 251.486 812.486 251.771 812.486 252.124C812.486 252.477 812.773 252.763 813.127 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 255.051C813.481 255.051 813.767 254.765 813.767 254.412C813.767 254.06 813.481 253.774 813.127 253.774C812.773 253.774 812.486 254.06 812.486 254.412C812.486 254.765 812.773 255.051 813.127 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.127 257.339C813.481 257.339 813.767 257.053 813.767 256.7C813.767 256.348 813.481 256.062 813.127 256.062C812.773 256.062 812.486 256.348 812.486 256.7C812.486 257.053 812.773 257.339 813.127 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M813.394 240.683H809.497C809.391 240.683 809.23 240.577 809.23 240.417V238.768C809.23 238.661 809.337 238.502 809.497 238.502H813.394C813.501 238.502 813.661 238.608 813.661 238.768V240.417C813.608 240.577 813.501 240.683 813.394 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M771.917 237.012H752.273V269.259H771.917V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M754.89 256.328H753.662V259.361H754.89V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M754.89 260.691H753.662V263.725H754.89V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M754.89 265.055H753.662V268.088H754.89V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M771.917 237.012H752.273V247.973H771.917V237.012Z" fill="white"/>

            <motion.path variants={pathVariants} custom={1} d="M378.351 193.909H371.145V226.156H378.351V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 207.319C376.836 207.319 377.123 207.033 377.123 206.68C377.123 206.327 376.836 206.041 376.482 206.041C376.129 206.041 375.842 206.327 375.842 206.68C375.842 207.033 376.129 207.319 376.482 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 209.66C376.836 209.66 377.123 209.374 377.123 209.021C377.123 208.669 376.836 208.383 376.482 208.383C376.129 208.383 375.842 208.669 375.842 209.021C375.842 209.374 376.129 209.66 376.482 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 211.948C376.836 211.948 377.123 211.662 377.123 211.31C377.123 210.957 376.836 210.671 376.482 210.671C376.129 210.671 375.842 210.957 375.842 211.31C375.842 211.662 376.129 211.948 376.482 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 214.236C376.836 214.236 377.123 213.95 377.123 213.598C377.123 213.245 376.836 212.959 376.482 212.959C376.129 212.959 375.842 213.245 375.842 213.598C375.842 213.95 376.129 214.236 376.482 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.642 197.581H372.745C372.639 197.581 372.479 197.474 372.479 197.315V195.665C372.479 195.558 372.585 195.399 372.745 195.399H376.642C376.749 195.399 376.909 195.505 376.909 195.665V197.315C376.856 197.474 376.749 197.581 376.642 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M385.503 193.909H378.297V226.156H385.503V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 207.319C383.776 207.319 384.062 207.033 384.062 206.68C384.062 206.327 383.776 206.041 383.422 206.041C383.068 206.041 382.781 206.327 382.781 206.68C382.781 207.033 383.068 207.319 383.422 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 209.66C383.776 209.66 384.062 209.374 384.062 209.021C384.062 208.669 383.776 208.383 383.422 208.383C383.068 208.383 382.781 208.669 382.781 209.021C382.781 209.374 383.068 209.66 383.422 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 211.948C383.776 211.948 384.062 211.662 384.062 211.31C384.062 210.957 383.776 210.671 383.422 210.671C383.068 210.671 382.781 210.957 382.781 211.31C382.781 211.662 383.068 211.948 383.422 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 214.236C383.776 214.236 384.062 213.95 384.062 213.598C384.062 213.245 383.776 212.959 383.422 212.959C383.068 212.959 382.781 213.245 382.781 213.598C382.781 213.95 383.068 214.236 383.422 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.849 197.581H379.952C379.846 197.581 379.686 197.474 379.686 197.315V195.665C379.686 195.558 379.792 195.399 379.952 195.399H383.849C383.956 195.399 384.116 195.505 384.116 195.665V197.315C384.063 197.474 383.956 197.581 383.849 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M392.603 193.909H385.396V226.156H392.603V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 207.319C391.248 207.319 391.535 207.033 391.535 206.68C391.535 206.327 391.248 206.041 390.894 206.041C390.541 206.041 390.254 206.327 390.254 206.68C390.254 207.033 390.541 207.319 390.894 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 209.66C391.248 209.66 391.535 209.374 391.535 209.021C391.535 208.669 391.248 208.383 390.894 208.383C390.541 208.383 390.254 208.669 390.254 209.021C390.254 209.374 390.541 209.66 390.894 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 211.948C391.248 211.948 391.535 211.662 391.535 211.31C391.535 210.957 391.248 210.671 390.894 210.671C390.541 210.671 390.254 210.957 390.254 211.31C390.254 211.662 390.541 211.948 390.894 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 214.236C391.248 214.236 391.535 213.95 391.535 213.598C391.535 213.245 391.248 212.959 390.894 212.959C390.541 212.959 390.254 213.245 390.254 213.598C390.254 213.95 390.541 214.236 390.894 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M391.001 197.581H387.105C386.998 197.581 386.838 197.474 386.838 197.315V195.665C386.838 195.558 386.945 195.399 387.105 195.399H391.001C391.108 195.399 391.268 195.505 391.268 195.665V197.315C391.215 197.474 391.108 197.581 391.001 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M399.81 193.909H392.604V226.156H399.81V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 207.319C398.188 207.319 398.474 207.033 398.474 206.68C398.474 206.327 398.188 206.041 397.834 206.041C397.48 206.041 397.193 206.327 397.193 206.68C397.193 207.033 397.48 207.319 397.834 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 209.66C398.188 209.66 398.474 209.374 398.474 209.021C398.474 208.669 398.188 208.383 397.834 208.383C397.48 208.383 397.193 208.669 397.193 209.021C397.193 209.374 397.48 209.66 397.834 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 211.948C398.188 211.948 398.474 211.662 398.474 211.31C398.474 210.957 398.188 210.671 397.834 210.671C397.48 210.671 397.193 210.957 397.193 211.31C397.193 211.662 397.48 211.948 397.834 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 214.236C398.188 214.236 398.474 213.95 398.474 213.598C398.474 213.245 398.188 212.959 397.834 212.959C397.48 212.959 397.193 213.245 397.193 213.598C397.193 213.95 397.48 214.236 397.834 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M398.156 197.581H394.259C394.152 197.581 393.992 197.474 393.992 197.315V195.665C393.992 195.558 394.099 195.399 394.259 195.399H398.156C398.263 195.399 398.423 195.505 398.423 195.665V197.315C398.369 197.474 398.263 197.581 398.156 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M406.962 193.909H399.756V226.156H406.962V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 207.319C405.66 207.319 405.947 207.033 405.947 206.68C405.947 206.327 405.66 206.041 405.307 206.041C404.953 206.041 404.666 206.327 404.666 206.68C404.666 207.033 404.953 207.319 405.307 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 209.66C405.66 209.66 405.947 209.374 405.947 209.021C405.947 208.669 405.66 208.383 405.307 208.383C404.953 208.383 404.666 208.669 404.666 209.021C404.666 209.374 404.953 209.66 405.307 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 211.948C405.66 211.948 405.947 211.662 405.947 211.31C405.947 210.957 405.66 210.671 405.307 210.671C404.953 210.671 404.666 210.957 404.666 211.31C404.666 211.662 404.953 211.948 405.307 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 214.236C405.66 214.236 405.947 213.95 405.947 213.598C405.947 213.245 405.66 212.959 405.307 212.959C404.953 212.959 404.666 213.245 404.666 213.598C404.666 213.95 404.953 214.236 405.307 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.308 197.581H401.411C401.305 197.581 401.145 197.474 401.145 197.315V195.665C401.145 195.558 401.251 195.399 401.411 195.399H405.308C405.415 195.399 405.575 195.505 405.575 195.665V197.315C405.575 197.474 405.468 197.581 405.308 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M414.115 193.909H351.395V226.156H414.115V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M414.169 193.909H406.963V226.156H414.169V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 207.319C412.602 207.319 412.889 207.033 412.889 206.68C412.889 206.327 412.602 206.041 412.248 206.041C411.894 206.041 411.607 206.327 411.607 206.68C411.607 207.033 411.894 207.319 412.248 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 209.66C412.602 209.66 412.889 209.374 412.889 209.021C412.889 208.669 412.602 208.383 412.248 208.383C411.894 208.383 411.607 208.669 411.607 209.021C411.607 209.374 411.894 209.66 412.248 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 211.948C412.602 211.948 412.889 211.662 412.889 211.31C412.889 210.957 412.602 210.671 412.248 210.671C411.894 210.671 411.607 210.957 411.607 211.31C411.607 211.662 411.894 211.948 412.248 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 214.236C412.602 214.236 412.889 213.95 412.889 213.598C412.889 213.245 412.602 212.959 412.248 212.959C411.894 212.959 411.607 213.245 411.607 213.598C411.607 213.95 411.894 214.236 412.248 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.515 197.581H408.618C408.512 197.581 408.352 197.474 408.352 197.315V195.665C408.352 195.558 408.458 195.399 408.618 195.399H412.515C412.622 195.399 412.782 195.505 412.782 195.665V197.315C412.729 197.474 412.622 197.581 412.515 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M371.038 193.909H351.395V226.156H371.038V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 213.225H352.783V216.258H354.011V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 217.589H352.783V220.622H354.011V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 221.952H352.783V224.985H354.011V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M371.038 193.909H351.395V204.871H371.038V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M368.797 196.676C369.151 196.676 369.437 196.39 369.437 196.037C369.437 195.685 369.151 195.399 368.797 195.399C368.443 195.399 368.156 195.685 368.156 196.037C368.156 196.39 368.443 196.676 368.797 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M378.351 237.012H371.145V269.259H378.351V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 250.421C376.836 250.421 377.123 250.135 377.123 249.783C377.123 249.43 376.836 249.144 376.482 249.144C376.129 249.144 375.842 249.43 375.842 249.783C375.842 250.135 376.129 250.421 376.482 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 252.763C376.836 252.763 377.123 252.477 377.123 252.124C377.123 251.771 376.836 251.486 376.482 251.486C376.129 251.486 375.842 251.771 375.842 252.124C375.842 252.477 376.129 252.763 376.482 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 255.051C376.836 255.051 377.123 254.765 377.123 254.412C377.123 254.06 376.836 253.774 376.482 253.774C376.129 253.774 375.842 254.06 375.842 254.412C375.842 254.765 376.129 255.051 376.482 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 257.339C376.836 257.339 377.123 257.053 377.123 256.7C377.123 256.348 376.836 256.062 376.482 256.062C376.129 256.062 375.842 256.348 375.842 256.7C375.842 257.053 376.129 257.339 376.482 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.642 240.683H372.745C372.639 240.683 372.479 240.577 372.479 240.417V238.768C372.479 238.661 372.585 238.502 372.745 238.502H376.642C376.749 238.502 376.909 238.608 376.909 238.768V240.417C376.856 240.577 376.749 240.683 376.642 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M385.503 237.012H378.297V269.259H385.503V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 250.421C383.776 250.421 384.062 250.135 384.062 249.783C384.062 249.43 383.776 249.144 383.422 249.144C383.068 249.144 382.781 249.43 382.781 249.783C382.781 250.135 383.068 250.421 383.422 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 252.763C383.776 252.763 384.062 252.477 384.062 252.124C384.062 251.771 383.776 251.486 383.422 251.486C383.068 251.486 382.781 251.771 382.781 252.124C382.781 252.477 383.068 252.763 383.422 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 255.051C383.776 255.051 384.062 254.765 384.062 254.412C384.062 254.06 383.776 253.774 383.422 253.774C383.068 253.774 382.781 254.06 382.781 254.412C382.781 254.765 383.068 255.051 383.422 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 257.339C383.776 257.339 384.062 257.053 384.062 256.7C384.062 256.348 383.776 256.062 383.422 256.062C383.068 256.062 382.781 256.348 382.781 256.7C382.781 257.053 383.068 257.339 383.422 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.849 240.683H379.952C379.846 240.683 379.686 240.577 379.686 240.417V238.768C379.686 238.661 379.792 238.502 379.952 238.502H383.849C383.956 238.502 384.116 238.608 384.116 238.768V240.417C384.063 240.577 383.956 240.683 383.849 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M392.603 237.012H385.396V269.259H392.603V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 250.421C391.248 250.421 391.535 250.135 391.535 249.783C391.535 249.43 391.248 249.144 390.894 249.144C390.541 249.144 390.254 249.43 390.254 249.783C390.254 250.135 390.541 250.421 390.894 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 252.763C391.248 252.763 391.535 252.477 391.535 252.124C391.535 251.771 391.248 251.486 390.894 251.486C390.541 251.486 390.254 251.771 390.254 252.124C390.254 252.477 390.541 252.763 390.894 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 255.051C391.248 255.051 391.535 254.765 391.535 254.412C391.535 254.06 391.248 253.774 390.894 253.774C390.541 253.774 390.254 254.06 390.254 254.412C390.254 254.765 390.541 255.051 390.894 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 257.339C391.248 257.339 391.535 257.053 391.535 256.7C391.535 256.348 391.248 256.062 390.894 256.062C390.541 256.062 390.254 256.348 390.254 256.7C390.254 257.053 390.541 257.339 390.894 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M391.001 240.683H387.105C386.998 240.683 386.838 240.577 386.838 240.417V238.768C386.838 238.661 386.945 238.502 387.105 238.502H391.001C391.108 238.502 391.268 238.608 391.268 238.768V240.417C391.215 240.577 391.108 240.683 391.001 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M399.81 237.012H392.604V269.259H399.81V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 250.421C398.188 250.421 398.474 250.135 398.474 249.783C398.474 249.43 398.188 249.144 397.834 249.144C397.48 249.144 397.193 249.43 397.193 249.783C397.193 250.135 397.48 250.421 397.834 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 252.763C398.188 252.763 398.474 252.477 398.474 252.124C398.474 251.771 398.188 251.486 397.834 251.486C397.48 251.486 397.193 251.771 397.193 252.124C397.193 252.477 397.48 252.763 397.834 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 255.051C398.188 255.051 398.474 254.765 398.474 254.412C398.474 254.06 398.188 253.774 397.834 253.774C397.48 253.774 397.193 254.06 397.193 254.412C397.193 254.765 397.48 255.051 397.834 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 257.339C398.188 257.339 398.474 257.053 398.474 256.7C398.474 256.348 398.188 256.062 397.834 256.062C397.48 256.062 397.193 256.348 397.193 256.7C397.193 257.053 397.48 257.339 397.834 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M398.156 240.683H394.259C394.152 240.683 393.992 240.577 393.992 240.417V238.768C393.992 238.661 394.099 238.502 394.259 238.502H398.156C398.263 238.502 398.423 238.608 398.423 238.768V240.417C398.369 240.577 398.263 240.683 398.156 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M406.962 237.012H399.756V269.259H406.962V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 250.421C405.66 250.421 405.947 250.135 405.947 249.783C405.947 249.43 405.66 249.144 405.307 249.144C404.953 249.144 404.666 249.43 404.666 249.783C404.666 250.135 404.953 250.421 405.307 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 252.763C405.66 252.763 405.947 252.477 405.947 252.124C405.947 251.771 405.66 251.486 405.307 251.486C404.953 251.486 404.666 251.771 404.666 252.124C404.666 252.477 404.953 252.763 405.307 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 255.051C405.66 255.051 405.947 254.765 405.947 254.412C405.947 254.06 405.66 253.774 405.307 253.774C404.953 253.774 404.666 254.06 404.666 254.412C404.666 254.765 404.953 255.051 405.307 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 257.339C405.66 257.339 405.947 257.053 405.947 256.7C405.947 256.348 405.66 256.062 405.307 256.062C404.953 256.062 404.666 256.348 404.666 256.7C404.666 257.053 404.953 257.339 405.307 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.308 240.683H401.411C401.305 240.683 401.145 240.577 401.145 240.417V238.768C401.145 238.661 401.251 238.502 401.411 238.502H405.308C405.415 238.502 405.575 238.608 405.575 238.768V240.417C405.575 240.577 405.468 240.683 405.308 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M414.115 237.012H351.395V269.259H414.115V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M414.169 237.012H406.963V269.259H414.169V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 250.421C412.602 250.421 412.889 250.135 412.889 249.783C412.889 249.43 412.602 249.144 412.248 249.144C411.894 249.144 411.607 249.43 411.607 249.783C411.607 250.135 411.894 250.421 412.248 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 252.763C412.602 252.763 412.889 252.477 412.889 252.124C412.889 251.771 412.602 251.486 412.248 251.486C411.894 251.486 411.607 251.771 411.607 252.124C411.607 252.477 411.894 252.763 412.248 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 255.051C412.602 255.051 412.889 254.765 412.889 254.412C412.889 254.06 412.602 253.774 412.248 253.774C411.894 253.774 411.607 254.06 411.607 254.412C411.607 254.765 411.894 255.051 412.248 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 257.339C412.602 257.339 412.889 257.053 412.889 256.7C412.889 256.348 412.602 256.062 412.248 256.062C411.894 256.062 411.607 256.348 411.607 256.7C411.607 257.053 411.894 257.339 412.248 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.515 240.683H408.618C408.512 240.683 408.352 240.577 408.352 240.417V238.768C408.352 238.661 408.458 238.502 408.618 238.502H412.515C412.622 238.502 412.782 238.608 412.782 238.768V240.417C412.729 240.577 412.622 240.683 412.515 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M371.038 237.012H351.395V269.259H371.038V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 256.328H352.783V259.361H354.011V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 260.691H352.783V263.725H354.011V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 265.055H352.783V268.088H354.011V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M371.038 237.012H351.395V247.973H371.038V237.012Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M368.797 239.779C369.151 239.779 369.437 239.493 369.437 239.14C369.437 238.787 369.151 238.502 368.797 238.502C368.443 238.502 368.156 238.787 368.156 239.14C368.156 239.493 368.443 239.779 368.797 239.779Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M378.351 280.114H371.145V312.361H378.351V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 293.524C376.836 293.524 377.123 293.238 377.123 292.885C377.123 292.533 376.836 292.247 376.482 292.247C376.129 292.247 375.842 292.533 375.842 292.885C375.842 293.238 376.129 293.524 376.482 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 295.865C376.836 295.865 377.123 295.579 377.123 295.227C377.123 294.874 376.836 294.588 376.482 294.588C376.129 294.588 375.842 294.874 375.842 295.227C375.842 295.579 376.129 295.865 376.482 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 298.154C376.836 298.154 377.123 297.868 377.123 297.515C377.123 297.162 376.836 296.876 376.482 296.876C376.129 296.876 375.842 297.162 375.842 297.515C375.842 297.868 376.129 298.154 376.482 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.482 300.442C376.836 300.442 377.123 300.156 377.123 299.803C377.123 299.45 376.836 299.165 376.482 299.165C376.129 299.165 375.842 299.45 375.842 299.803C375.842 300.156 376.129 300.442 376.482 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M376.642 283.786H372.745C372.639 283.786 372.479 283.68 372.479 283.52V281.87C372.479 281.764 372.585 281.604 372.745 281.604H376.642C376.749 281.604 376.909 281.711 376.909 281.87V283.52C376.856 283.68 376.749 283.786 376.642 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M385.503 280.114H378.297V312.361H385.503V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 293.524C383.776 293.524 384.062 293.238 384.062 292.885C384.062 292.533 383.776 292.247 383.422 292.247C383.068 292.247 382.781 292.533 382.781 292.885C382.781 293.238 383.068 293.524 383.422 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 295.865C383.776 295.865 384.062 295.579 384.062 295.227C384.062 294.874 383.776 294.588 383.422 294.588C383.068 294.588 382.781 294.874 382.781 295.227C382.781 295.579 383.068 295.865 383.422 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 298.154C383.776 298.154 384.062 297.868 384.062 297.515C384.062 297.162 383.776 296.876 383.422 296.876C383.068 296.876 382.781 297.162 382.781 297.515C382.781 297.868 383.068 298.154 383.422 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.422 300.442C383.776 300.442 384.062 300.156 384.062 299.803C384.062 299.45 383.776 299.165 383.422 299.165C383.068 299.165 382.781 299.45 382.781 299.803C382.781 300.156 383.068 300.442 383.422 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M383.849 283.786H379.952C379.846 283.786 379.686 283.68 379.686 283.52V281.87C379.686 281.764 379.792 281.604 379.952 281.604H383.849C383.956 281.604 384.116 281.711 384.116 281.87V283.52C384.063 283.68 383.956 283.786 383.849 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M392.603 280.114H385.396V312.361H392.603V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 293.524C391.248 293.524 391.535 293.238 391.535 292.885C391.535 292.533 391.248 292.247 390.894 292.247C390.541 292.247 390.254 292.533 390.254 292.885C390.254 293.238 390.541 293.524 390.894 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 295.865C391.248 295.865 391.535 295.579 391.535 295.227C391.535 294.874 391.248 294.588 390.894 294.588C390.541 294.588 390.254 294.874 390.254 295.227C390.254 295.579 390.541 295.865 390.894 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 298.154C391.248 298.154 391.535 297.868 391.535 297.515C391.535 297.162 391.248 296.876 390.894 296.876C390.541 296.876 390.254 297.162 390.254 297.515C390.254 297.868 390.541 298.154 390.894 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M390.894 300.442C391.248 300.442 391.535 300.156 391.535 299.803C391.535 299.45 391.248 299.165 390.894 299.165C390.541 299.165 390.254 299.45 390.254 299.803C390.254 300.156 390.541 300.442 390.894 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M391.001 283.786H387.105C386.998 283.786 386.838 283.68 386.838 283.52V281.87C386.838 281.764 386.945 281.604 387.105 281.604H391.001C391.108 281.604 391.268 281.711 391.268 281.87V283.52C391.215 283.68 391.108 283.786 391.001 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M399.81 280.114H392.604V312.361H399.81V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 293.524C398.188 293.524 398.474 293.238 398.474 292.885C398.474 292.533 398.188 292.247 397.834 292.247C397.48 292.247 397.193 292.533 397.193 292.885C397.193 293.238 397.48 293.524 397.834 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 295.865C398.188 295.865 398.474 295.579 398.474 295.227C398.474 294.874 398.188 294.588 397.834 294.588C397.48 294.588 397.193 294.874 397.193 295.227C397.193 295.579 397.48 295.865 397.834 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 298.154C398.188 298.154 398.474 297.868 398.474 297.515C398.474 297.162 398.188 296.876 397.834 296.876C397.48 296.876 397.193 297.162 397.193 297.515C397.193 297.868 397.48 298.154 397.834 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M397.834 300.442C398.188 300.442 398.474 300.156 398.474 299.803C398.474 299.45 398.188 299.165 397.834 299.165C397.48 299.165 397.193 299.45 397.193 299.803C397.193 300.156 397.48 300.442 397.834 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M398.156 283.786H394.259C394.152 283.786 393.992 283.68 393.992 283.52V281.87C393.992 281.764 394.099 281.604 394.259 281.604H398.156C398.263 281.604 398.423 281.711 398.423 281.87V283.52C398.369 283.68 398.263 283.786 398.156 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M406.962 280.114H399.756V312.361H406.962V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 293.524C405.66 293.524 405.947 293.238 405.947 292.885C405.947 292.533 405.66 292.247 405.307 292.247C404.953 292.247 404.666 292.533 404.666 292.885C404.666 293.238 404.953 293.524 405.307 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 295.865C405.66 295.865 405.947 295.579 405.947 295.227C405.947 294.874 405.66 294.588 405.307 294.588C404.953 294.588 404.666 294.874 404.666 295.227C404.666 295.579 404.953 295.865 405.307 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 298.154C405.66 298.154 405.947 297.868 405.947 297.515C405.947 297.162 405.66 296.876 405.307 296.876C404.953 296.876 404.666 297.162 404.666 297.515C404.666 297.868 404.953 298.154 405.307 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.307 300.442C405.66 300.442 405.947 300.156 405.947 299.803C405.947 299.45 405.66 299.165 405.307 299.165C404.953 299.165 404.666 299.45 404.666 299.803C404.666 300.156 404.953 300.442 405.307 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M405.308 283.786H401.411C401.305 283.786 401.145 283.68 401.145 283.52V281.87C401.145 281.764 401.251 281.604 401.411 281.604H405.308C405.415 281.604 405.575 281.711 405.575 281.87V283.52C405.575 283.68 405.468 283.786 405.308 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M414.115 280.114H351.395V312.361H414.115V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M414.169 280.114H406.963V312.361H414.169V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 293.524C412.602 293.524 412.889 293.238 412.889 292.885C412.889 292.533 412.602 292.247 412.248 292.247C411.894 292.247 411.607 292.533 411.607 292.885C411.607 293.238 411.894 293.524 412.248 293.524Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 295.865C412.602 295.865 412.889 295.579 412.889 295.227C412.889 294.874 412.602 294.588 412.248 294.588C411.894 294.588 411.607 294.874 411.607 295.227C411.607 295.579 411.894 295.865 412.248 295.865Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 298.154C412.602 298.154 412.889 297.868 412.889 297.515C412.889 297.162 412.602 296.876 412.248 296.876C411.894 296.876 411.607 297.162 411.607 297.515C411.607 297.868 411.894 298.154 412.248 298.154Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.248 300.442C412.602 300.442 412.889 300.156 412.889 299.803C412.889 299.45 412.602 299.165 412.248 299.165C411.894 299.165 411.607 299.45 411.607 299.803C411.607 300.156 411.894 300.442 412.248 300.442Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M412.515 283.786H408.618C408.512 283.786 408.352 283.68 408.352 283.52V281.87C408.352 281.764 408.458 281.604 408.618 281.604H412.515C412.622 281.604 412.782 281.711 412.782 281.87V283.52C412.729 283.68 412.622 283.786 412.515 283.786Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M371.038 280.114H351.395V312.361H371.038V280.114Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 299.431H352.783V302.464H354.011V299.431Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 303.794H352.783V306.827H354.011V303.794Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M354.011 308.158H352.783V311.191H354.011V308.158Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M371.038 280.114H351.395V291.076H371.038V280.114Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M368.797 282.881C369.151 282.881 369.437 282.595 369.437 282.243C369.437 281.89 369.151 281.604 368.797 281.604C368.443 281.604 368.156 281.89 368.156 282.243C368.156 282.595 368.443 282.881 368.797 282.881Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M469.63 193.909H462.424V226.156H469.63V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 207.319C468.115 207.319 468.402 207.033 468.402 206.68C468.402 206.327 468.115 206.041 467.762 206.041C467.408 206.041 467.121 206.327 467.121 206.68C467.121 207.033 467.408 207.319 467.762 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 209.66C468.115 209.66 468.402 209.374 468.402 209.021C468.402 208.669 468.115 208.383 467.762 208.383C467.408 208.383 467.121 208.669 467.121 209.021C467.121 209.374 467.408 209.66 467.762 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 211.948C468.115 211.948 468.402 211.662 468.402 211.31C468.402 210.957 468.115 210.671 467.762 210.671C467.408 210.671 467.121 210.957 467.121 211.31C467.121 211.662 467.408 211.948 467.762 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 214.236C468.115 214.236 468.402 213.95 468.402 213.598C468.402 213.245 468.115 212.959 467.762 212.959C467.408 212.959 467.121 213.245 467.121 213.598C467.121 213.95 467.408 214.236 467.762 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.921 197.581H464.025C463.918 197.581 463.758 197.474 463.758 197.315V195.665C463.758 195.558 463.865 195.399 464.025 195.399H467.921C468.028 195.399 468.188 195.505 468.188 195.665V197.315C468.135 197.474 468.028 197.581 467.921 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M476.782 193.909H469.576V226.156H476.782V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 207.319C475.055 207.319 475.342 207.033 475.342 206.68C475.342 206.327 475.055 206.041 474.701 206.041C474.347 206.041 474.061 206.327 474.061 206.68C474.061 207.033 474.347 207.319 474.701 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 209.66C475.055 209.66 475.342 209.374 475.342 209.021C475.342 208.669 475.055 208.383 474.701 208.383C474.347 208.383 474.061 208.669 474.061 209.021C474.061 209.374 474.347 209.66 474.701 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 211.948C475.055 211.948 475.342 211.662 475.342 211.31C475.342 210.957 475.055 210.671 474.701 210.671C474.347 210.671 474.061 210.957 474.061 211.31C474.061 211.662 474.347 211.948 474.701 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 214.236C475.055 214.236 475.342 213.95 475.342 213.598C475.342 213.245 475.055 212.959 474.701 212.959C474.347 212.959 474.061 213.245 474.061 213.598C474.061 213.95 474.347 214.236 474.701 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M475.128 197.581H471.232C471.125 197.581 470.965 197.474 470.965 197.315V195.665C470.965 195.558 471.072 195.399 471.232 195.399H475.128C475.235 195.399 475.395 195.505 475.395 195.665V197.315C475.342 197.474 475.235 197.581 475.128 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M483.882 193.909H476.676V226.156H483.882V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 207.319C482.528 207.319 482.814 207.033 482.814 206.68C482.814 206.327 482.528 206.041 482.174 206.041C481.82 206.041 481.533 206.327 481.533 206.68C481.533 207.033 481.82 207.319 482.174 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 209.66C482.528 209.66 482.814 209.374 482.814 209.021C482.814 208.669 482.528 208.383 482.174 208.383C481.82 208.383 481.533 208.669 481.533 209.021C481.533 209.374 481.82 209.66 482.174 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 211.948C482.528 211.948 482.814 211.662 482.814 211.31C482.814 210.957 482.528 210.671 482.174 210.671C481.82 210.671 481.533 210.957 481.533 211.31C481.533 211.662 481.82 211.948 482.174 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 214.236C482.528 214.236 482.814 213.95 482.814 213.598C482.814 213.245 482.528 212.959 482.174 212.959C481.82 212.959 481.533 213.245 481.533 213.598C481.533 213.95 481.82 214.236 482.174 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.281 197.581H478.384C478.277 197.581 478.117 197.474 478.117 197.315V195.665C478.117 195.558 478.224 195.399 478.384 195.399H482.281C482.388 195.399 482.548 195.505 482.548 195.665V197.315C482.494 197.474 482.388 197.581 482.281 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M491.089 193.909H483.883V226.156H491.089V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 207.319C489.467 207.319 489.754 207.033 489.754 206.68C489.754 206.327 489.467 206.041 489.113 206.041C488.759 206.041 488.473 206.327 488.473 206.68C488.473 207.033 488.759 207.319 489.113 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 209.66C489.467 209.66 489.754 209.374 489.754 209.021C489.754 208.669 489.467 208.383 489.113 208.383C488.759 208.383 488.473 208.669 488.473 209.021C488.473 209.374 488.759 209.66 489.113 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 211.948C489.467 211.948 489.754 211.662 489.754 211.31C489.754 210.957 489.467 210.671 489.113 210.671C488.759 210.671 488.473 210.957 488.473 211.31C488.473 211.662 488.759 211.948 489.113 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 214.236C489.467 214.236 489.754 213.95 489.754 213.598C489.754 213.245 489.467 212.959 489.113 212.959C488.759 212.959 488.473 213.245 488.473 213.598C488.473 213.95 488.759 214.236 489.113 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.435 197.581H485.538C485.432 197.581 485.271 197.474 485.271 197.315V195.665C485.271 195.558 485.378 195.399 485.538 195.399H489.435C489.542 195.399 489.702 195.505 489.702 195.665V197.315C489.649 197.474 489.542 197.581 489.435 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M498.241 193.909H491.035V226.156H498.241V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 207.319C496.94 207.319 497.226 207.033 497.226 206.68C497.226 206.327 496.94 206.041 496.586 206.041C496.232 206.041 495.945 206.327 495.945 206.68C495.945 207.033 496.232 207.319 496.586 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 209.66C496.94 209.66 497.226 209.374 497.226 209.021C497.226 208.669 496.94 208.383 496.586 208.383C496.232 208.383 495.945 208.669 495.945 209.021C495.945 209.374 496.232 209.66 496.586 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 211.948C496.94 211.948 497.226 211.662 497.226 211.31C497.226 210.957 496.94 210.671 496.586 210.671C496.232 210.671 495.945 210.957 495.945 211.31C495.945 211.662 496.232 211.948 496.586 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 214.236C496.94 214.236 497.226 213.95 497.226 213.598C497.226 213.245 496.94 212.959 496.586 212.959C496.232 212.959 495.945 213.245 495.945 213.598C495.945 213.95 496.232 214.236 496.586 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.587 197.581H492.691C492.584 197.581 492.424 197.474 492.424 197.315V195.665C492.424 195.558 492.531 195.399 492.691 195.399H496.587C496.694 195.399 496.854 195.505 496.854 195.665V197.315C496.854 197.474 496.748 197.581 496.587 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M505.394 193.909H442.674V226.156H505.394V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M505.448 193.909H498.242V226.156H505.448V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 207.319C503.881 207.319 504.168 207.033 504.168 206.68C504.168 206.327 503.881 206.041 503.527 206.041C503.174 206.041 502.887 206.327 502.887 206.68C502.887 207.033 503.174 207.319 503.527 207.319Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 209.66C503.881 209.66 504.168 209.374 504.168 209.021C504.168 208.669 503.881 208.383 503.527 208.383C503.174 208.383 502.887 208.669 502.887 209.021C502.887 209.374 503.174 209.66 503.527 209.66Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 211.948C503.881 211.948 504.168 211.662 504.168 211.31C504.168 210.957 503.881 210.671 503.527 210.671C503.174 210.671 502.887 210.957 502.887 211.31C502.887 211.662 503.174 211.948 503.527 211.948Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 214.236C503.881 214.236 504.168 213.95 504.168 213.598C504.168 213.245 503.881 212.959 503.527 212.959C503.174 212.959 502.887 213.245 502.887 213.598C502.887 213.95 503.174 214.236 503.527 214.236Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.794 197.581H499.898C499.791 197.581 499.631 197.474 499.631 197.315V195.665C499.631 195.558 499.738 195.399 499.898 195.399H503.794C503.901 195.399 504.061 195.505 504.061 195.665V197.315C504.008 197.474 503.901 197.581 503.794 197.581Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M462.317 193.909H442.674V226.156H462.317V193.909Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M445.29 213.225H444.062V216.258H445.29V213.225Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M445.29 217.589H444.062V220.622H445.29V217.589Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M445.29 221.952H444.062V224.985H445.29V221.952Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M462.317 193.909H442.674V204.871H462.317V193.909Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M460.076 196.676C460.43 196.676 460.717 196.39 460.717 196.037C460.717 195.685 460.43 195.399 460.076 195.399C459.722 195.399 459.436 195.685 459.436 196.037C459.436 196.39 459.722 196.676 460.076 196.676Z" fill="#19005C" stroke="white" strokeMiterlimit="10"/>
            <motion.path variants={pathVariants} custom={1} d="M469.63 237.012H462.424V269.259H469.63V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 250.421C468.115 250.421 468.402 250.135 468.402 249.783C468.402 249.43 468.115 249.144 467.762 249.144C467.408 249.144 467.121 249.43 467.121 249.783C467.121 250.135 467.408 250.421 467.762 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 252.763C468.115 252.763 468.402 252.477 468.402 252.124C468.402 251.771 468.115 251.486 467.762 251.486C467.408 251.486 467.121 251.771 467.121 252.124C467.121 252.477 467.408 252.763 467.762 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 255.051C468.115 255.051 468.402 254.765 468.402 254.412C468.402 254.06 468.115 253.774 467.762 253.774C467.408 253.774 467.121 254.06 467.121 254.412C467.121 254.765 467.408 255.051 467.762 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.762 257.339C468.115 257.339 468.402 257.053 468.402 256.7C468.402 256.348 468.115 256.062 467.762 256.062C467.408 256.062 467.121 256.348 467.121 256.7C467.121 257.053 467.408 257.339 467.762 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M467.921 240.683H464.025C463.918 240.683 463.758 240.577 463.758 240.417V238.768C463.758 238.661 463.865 238.502 464.025 238.502H467.921C468.028 238.502 468.188 238.608 468.188 238.768V240.417C468.135 240.577 468.028 240.683 467.921 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M476.782 237.012H469.576V269.259H476.782V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 250.421C475.055 250.421 475.342 250.135 475.342 249.783C475.342 249.43 475.055 249.144 474.701 249.144C474.347 249.144 474.061 249.43 474.061 249.783C474.061 250.135 474.347 250.421 474.701 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 252.763C475.055 252.763 475.342 252.477 475.342 252.124C475.342 251.771 475.055 251.486 474.701 251.486C474.347 251.486 474.061 251.771 474.061 252.124C474.061 252.477 474.347 252.763 474.701 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 255.051C475.055 255.051 475.342 254.765 475.342 254.412C475.342 254.06 475.055 253.774 474.701 253.774C474.347 253.774 474.061 254.06 474.061 254.412C474.061 254.765 474.347 255.051 474.701 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M474.701 257.339C475.055 257.339 475.342 257.053 475.342 256.7C475.342 256.348 475.055 256.062 474.701 256.062C474.347 256.062 474.061 256.348 474.061 256.7C474.061 257.053 474.347 257.339 474.701 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M475.128 240.683H471.232C471.125 240.683 470.965 240.577 470.965 240.417V238.768C470.965 238.661 471.072 238.502 471.232 238.502H475.128C475.235 238.502 475.395 238.608 475.395 238.768V240.417C475.342 240.577 475.235 240.683 475.128 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M483.882 237.012H476.676V269.259H483.882V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 250.421C482.528 250.421 482.814 250.135 482.814 249.783C482.814 249.43 482.528 249.144 482.174 249.144C481.82 249.144 481.533 249.43 481.533 249.783C481.533 250.135 481.82 250.421 482.174 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 252.763C482.528 252.763 482.814 252.477 482.814 252.124C482.814 251.771 482.528 251.486 482.174 251.486C481.82 251.486 481.533 251.771 481.533 252.124C481.533 252.477 481.82 252.763 482.174 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 255.051C482.528 255.051 482.814 254.765 482.814 254.412C482.814 254.06 482.528 253.774 482.174 253.774C481.82 253.774 481.533 254.06 481.533 254.412C481.533 254.765 481.82 255.051 482.174 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.174 257.339C482.528 257.339 482.814 257.053 482.814 256.7C482.814 256.348 482.528 256.062 482.174 256.062C481.82 256.062 481.533 256.348 481.533 256.7C481.533 257.053 481.82 257.339 482.174 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M482.281 240.683H478.384C478.277 240.683 478.117 240.577 478.117 240.417V238.768C478.117 238.661 478.224 238.502 478.384 238.502H482.281C482.388 238.502 482.548 238.608 482.548 238.768V240.417C482.494 240.577 482.388 240.683 482.281 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M491.089 237.012H483.883V269.259H491.089V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 250.421C489.467 250.421 489.754 250.135 489.754 249.783C489.754 249.43 489.467 249.144 489.113 249.144C488.759 249.144 488.473 249.43 488.473 249.783C488.473 250.135 488.759 250.421 489.113 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 252.763C489.467 252.763 489.754 252.477 489.754 252.124C489.754 251.771 489.467 251.486 489.113 251.486C488.759 251.486 488.473 251.771 488.473 252.124C488.473 252.477 488.759 252.763 489.113 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 255.051C489.467 255.051 489.754 254.765 489.754 254.412C489.754 254.06 489.467 253.774 489.113 253.774C488.759 253.774 488.473 254.06 488.473 254.412C488.473 254.765 488.759 255.051 489.113 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.113 257.339C489.467 257.339 489.754 257.053 489.754 256.7C489.754 256.348 489.467 256.062 489.113 256.062C488.759 256.062 488.473 256.348 488.473 256.7C488.473 257.053 488.759 257.339 489.113 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M489.435 240.683H485.538C485.432 240.683 485.271 240.577 485.271 240.417V238.768C485.271 238.661 485.378 238.502 485.538 238.502H489.435C489.542 238.502 489.702 238.608 489.702 238.768V240.417C489.649 240.577 489.542 240.683 489.435 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M498.241 237.012H491.035V269.259H498.241V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 250.421C496.94 250.421 497.226 250.135 497.226 249.783C497.226 249.43 496.94 249.144 496.586 249.144C496.232 249.144 495.945 249.43 495.945 249.783C495.945 250.135 496.232 250.421 496.586 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 252.763C496.94 252.763 497.226 252.477 497.226 252.124C497.226 251.771 496.94 251.486 496.586 251.486C496.232 251.486 495.945 251.771 495.945 252.124C495.945 252.477 496.232 252.763 496.586 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 255.051C496.94 255.051 497.226 254.765 497.226 254.412C497.226 254.06 496.94 253.774 496.586 253.774C496.232 253.774 495.945 254.06 495.945 254.412C495.945 254.765 496.232 255.051 496.586 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.586 257.339C496.94 257.339 497.226 257.053 497.226 256.7C497.226 256.348 496.94 256.062 496.586 256.062C496.232 256.062 495.945 256.348 495.945 256.7C495.945 257.053 496.232 257.339 496.586 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M496.587 240.683H492.691C492.584 240.683 492.424 240.577 492.424 240.417V238.768C492.424 238.661 492.531 238.502 492.691 238.502H496.587C496.694 238.502 496.854 238.608 496.854 238.768V240.417C496.854 240.577 496.748 240.683 496.587 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M505.394 237.012H442.674V269.259H505.394V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M505.448 237.012H498.242V269.259H505.448V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 250.421C503.881 250.421 504.168 250.135 504.168 249.783C504.168 249.43 503.881 249.144 503.527 249.144C503.174 249.144 502.887 249.43 502.887 249.783C502.887 250.135 503.174 250.421 503.527 250.421Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 252.763C503.881 252.763 504.168 252.477 504.168 252.124C504.168 251.771 503.881 251.486 503.527 251.486C503.174 251.486 502.887 251.771 502.887 252.124C502.887 252.477 503.174 252.763 503.527 252.763Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 255.051C503.881 255.051 504.168 254.765 504.168 254.412C504.168 254.06 503.881 253.774 503.527 253.774C503.174 253.774 502.887 254.06 502.887 254.412C502.887 254.765 503.174 255.051 503.527 255.051Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.527 257.339C503.881 257.339 504.168 257.053 504.168 256.7C504.168 256.348 503.881 256.062 503.527 256.062C503.174 256.062 502.887 256.348 502.887 256.7C502.887 257.053 503.174 257.339 503.527 257.339Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M503.794 240.683H499.898C499.791 240.683 499.631 240.577 499.631 240.417V238.768C499.631 238.661 499.738 238.502 499.898 238.502H503.794C503.901 238.502 504.061 238.608 504.061 238.768V240.417C504.008 240.577 503.901 240.683 503.794 240.683Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M462.317 237.012H442.674V269.259H462.317V237.012Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <motion.path variants={pathVariants} custom={1} d="M445.29 256.328H444.062V259.361H445.29V256.328Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M445.29 260.691H444.062V263.725H445.29V260.691Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M445.29 265.055H444.062V268.088H445.29V265.055Z" fill="white"/>
            <motion.path variants={pathVariants} custom={1} d="M462.317 237.012H442.674V247.973H462.317V237.012Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_7536_27009">
            <rect width="838" height="313" fill="white"/>
            </clipPath>
            </defs>
        </motion.svg>
    );
};

export default DCSAnimated;


