import React, {useEffect, useState} from "react";
import {motion, AnimatePresence} from 'framer-motion';
import cn from "classnames";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { menuContactsVariants, menuVariants } from "../../api/animation-varints";

const menuData = [
    {
        title: "Главная",
        path: "/",
    },
    {
        title: "О компании",
        path: "/about-sdisol",
    },
    {
        title: "Sdisol PAD",
        path: "/sdisol-pad",
    },
    {
        title: "Sdisol ED",
        path: "/sdisol-ed",
    },
    {
        title: "Sdisol DCS",
        path: "/sdisol-dcs",
    },
    {
        title: "Новости",
        path: "/news",
    },
]

const Navigation = ({footer = false}) => {

    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [contactsOpen, setContactsOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('/');
    const [animateHeader, setAnimateHeader] = useState(false);

    useEffect(() => {
        setActiveMenuItem(location.pathname);    
    }, [location]);

    useEffect(() => {
        const listener = () => {
          if (window.scrollY > 4600) {
            setAnimateHeader(true);
          } else setAnimateHeader(false);
        };
        window.addEventListener("scroll", listener);

        return () => {
          window.removeEventListener("scroll", listener);
        };
    }, []);

    return (<>
        {!footer && (
            <div className={cn(
                        "navigation", 
                        menuOpen && "navigation__opened", 
                        contactsOpen && "navigation__opened")}
                    >    
            {/* <div className={cn(
                        "navigation_bg", 
                        animateHeader && "navigation_bg-animate-blue")}></div>  */}
            <div className="navigation__logo-box">
                {menuOpen &&    
                    <Link to="/">
                        <img
                            src="images/logos/logo-RU-black.svg"
                            alt=""/>
                    </Link>
                }
                {contactsOpen &&
                    <Link to="/">
                        <img
                            src="images/logos/logo-RU-white.svg"
                            alt=""/>
                    </Link> 
                }
                {!menuOpen && !contactsOpen && !animateHeader &&
                    <Link to="/">
                        <img
                            src="images/logos/logo-RU.svg"
                            alt=""/>
                    </Link>
                }
            </div>

            <div className="navigation__btn-box">
                {menuOpen ?
                    <button
                    className={cn("btn nav-btn nav-btn__cross", (contactsOpen || menuOpen) && "nav-btn__blue")}
                    onClick={() => setMenuOpen(false)}>
                    <svg className="nav-btn__icon-cross" width="24" height="24" viewBox="0 0 24 24" fill="white"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1.33552L0.712309 0.633651L23.714 23.2981L23.0017 24L0 1.33552Z"/>
                        <path d="M23.0416 0L23.7539 0.701866L0.752238 23.3664L0.0399289 22.6645L23.0416 0Z"/>
                    </svg>
                    </button>
                    :
                    <button
                        className={cn("btn nav-btn", (contactsOpen || menuOpen) && "nav-btn__brown")}
                        onClick={() => {
                            setMenuOpen(!menuOpen)
                            setContactsOpen(false)
                        }}>
                        <span className={cn("navigation__icon", (contactsOpen || menuOpen) && "navigation__icon--black")}>&nbsp;</span>
                        Меню
                    </button>
                }
                

                {contactsOpen ?
                    <button
                        className={cn("btn nav-btn nav-btn__cross", (contactsOpen || menuOpen) && "nav-btn__blue")}
                        onClick={() => setContactsOpen(false)}>
                        <svg className="nav-btn__icon-cross" width="24" height="24" viewBox="0 0 24 24" fill="white"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1.33552L0.712309 0.633651L23.714 23.2981L23.0017 24L0 1.33552Z"/>
                            <path d="M23.0416 0L23.7539 0.701866L0.752238 23.3664L0.0399289 22.6645L23.0416 0Z"/>
                        </svg>
                    </button>
                    :
                    <button
                        className={cn("btn nav-btn nav-btn__cont", menuOpen && "nav-btn__blue")}
                        onClick={() => {
                            setContactsOpen(true)
                            setMenuOpen(false)
                        }}>
                        Контакты
                    </button>
                }
            </div>
            </div>
        )}

        <AnimatePresence>
            {contactsOpen && (
                <div className="contacts-popup__background">
                    <motion.div
                        className="contacts-popup"
                        variants={menuContactsVariants} initial="hidden" animate="visible" exit="exit"
                    >
                        <div className="contacts-popup__header">Контакты</div>
                        <div className="contacts-popup__content">
                            <div className="contacts-popup__text">Хотите узнать подробнее? Напишите нам!</div>

                            <div
                                className="contacts-popup__mail"
                                // onMouseMove={setContactsBtnVisible(!contactsBtnVisible)}
                            >
                                <a href="mailto:info@sdisol.ru">
                                    info@sdisol.ru
                                </a>    
                                {/*{contactsBtnVisible &&*/}
                                <div className="contacts-popup__mail-icon-box">
                                    <a 
                                        onClick={() => navigator.clipboard.writeText('info@sdisol.ru')}
                                        className="contacts-popup__mail-icon contacts-popup__mail-icon--copy">
                                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.62 19H4C3.20435 19 2.44129 18.6839 1.87868 18.1213C1.31607 17.5587 1 16.7956 1 16V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H16C16.7956 1 17.5587 1.31607 18.1213 1.87868C18.6839 2.44129 19 3.20435 19 4V5.26M12.63 10H24.63C26.2869 10 27.63 11.3431 27.63 13V25C27.63 26.6569 26.2869 28 24.63 28H12.63C10.9732 28 9.63 26.6569 9.63 25V13C9.63 11.3431 10.9732 10 12.63 10Z"
                                                stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                        </svg>
                                    </a>

                                    <a href="mailto:info@sdisol.ru" className="contacts-popup__mail-icon">
                                        <svg width="32" height="27" viewBox="0 0 32 27" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3 3.5L15.5 13.5L28.5 3.5M1 6C1 3.23858 3.23858 1 6 1H26C28.7614 1 31 3.23858 31 6V21C31 23.7614 28.7614 26 26 26H6C3.23858 26 1 23.7614 1 21V6Z"
                                                stroke="black" strokeWidth="2"/>
                                        </svg>
                                    </a>
                                </div>
                                {/*}*/}
                            </div>
                        </div>
                        <div className="contacts-popup__contacts">
                            <div className="contacts-popup__num">
                                <a href="tel:+74951081196">
                                    +7(495)108-11-96
                                </a>
                            </div>
                            <div className="contacts-popup__address">
                                <a 
                                    href="https://yandex.ru/maps/-/CDWU6MME" 
                                    target="_blank"
                                >
                                    105064, Москва, <br /> Старая Басманная улица, <br/> дом 7 строение 2
                                </a>
                            </div>
                        </div>
                    </motion.div>
                </div>

            )}

            {(menuOpen || footer) && (
                <motion.div
                    className={cn("navigation__menu", footer && "navigation__menu-footer")}
                    variants={menuVariants} initial="hidden" animate="visible" exit="exit"
                >
                    <div className="navigation__menu-btns">
                        {menuData.map(({title, path}, i) => (
                            <div key={i} className={cn(
                                    "navigation__menu-button",
                                    footer && activeMenuItem === path && "navigation__menu-button--active-footer",
                                    activeMenuItem === path ? "navigation__menu-button--active" : "")} 
                                onClick={() => {
                                    setMenuOpen(false)
                            }}>
                                <Link to={path}>{title}</Link>
                            </div>    
                        ))}
                    </div>

                    {footer &&
                        <div className="navigation__menu-contacts">
                            <div className="navigation__menu-contacts--adress">
                                <a 
                                    href="https://yandex.ru/maps/-/CDWU6MME" 
                                    target="_blank"
                                >  
                                    105064, Москва, <br /> Старая Басманная улица, <br/> дом 7 строение 2
                                </a>
                            </div>

                            <div className="navigation__menu-contacts--adress">
                                <a href="mailto:info@sdisol.ru">info@sdisol.ru</a> <br/>
                                <a href="tel:+74951081196">+7(495)108-11-96</a>
                            </div>

                            <div className="navigation__menu-contacts--info">
                                © 2024 ООО «Смарт Цифровые Решения» <br/>
                            </div>
                        </div>
                    }

                    {footer && (<img
                        src="images/svg-elements/triangle-big-element.svg"
                        className="svg-element footer-triangle-big-element"
                        alt=""/>)}

                </motion.div>
            )}
        </AnimatePresence>
    </>);
};

export default Navigation;