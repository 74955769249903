import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const textTransition = {
    hidden: { opacity: 0, y: 50 },
    visible: custom => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
            ease: "easeInOut",
        }
    })
};

const opacityTransition = {
    visible: custom => ({
      opacity: 1,
      transition: {
        delay: custom * 0.4,
        duration: 0.3,
      },
    }),
    hidden: { opacity: 0 },
};

const pathVariants = {
    hidden: {
      pathLength: 0,
      opacity: 0,
    },
    visible: custom => ({
        pathLength: 1,
        opacity: 1,
        transition: {
            delay: custom * 0.4,
            duration: 1,
            ease: "easeInOut",
        }
    })
};


const ShieldAnimated = () => {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.5 });

    return (
        <motion.svg
            className='dcs__shield-img' 
            ref={containerRef} 
            initial="hidden"
            animate={isInView ? "visible" : "hidden"} 
            width="408" height="413" viewBox="0 0 408 413" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7537_28541)">
            <motion.path variants={pathVariants} custom={3} d="M169.031 269.559C168.699 269.559 168.477 269.559 168.144 269.559C162.824 269.338 157.836 266.8 154.622 262.608L63.512 146.2C57.305 138.255 58.7459 126.78 66.7263 120.601C74.7068 114.422 86.2341 115.856 92.4411 123.801L170.472 223.437L376.412 5.73767C383.284 -1.54472 394.922 -1.87574 402.237 4.9653C409.553 11.8063 409.885 23.392 403.013 30.6744L182.332 263.821C178.896 267.573 174.019 269.559 169.031 269.559Z" fill="#010D48"/>
            <motion.path variants={pathVariants} custom={2} d="M338.282 212.624C338.171 210.196 338.06 207.99 338.06 206.555V147.413L301.483 188.128V206.555C301.483 208.762 301.594 211.189 301.705 214.058C302.703 238.774 304.587 290.303 254.82 323.956C210.152 354.079 181.555 368.975 169.252 374.823C156.949 368.975 128.352 354.079 83.6839 323.956C33.917 290.303 35.9121 238.885 36.7988 214.058C36.9097 211.189 37.0205 208.762 37.0205 206.555V98.5329C98.2039 87.2783 147.527 56.604 169.252 41.1565C185.213 52.5214 216.137 72.0515 255.707 86.1749L284.747 57.266C232.874 42.3702 193.304 13.9027 183.218 6.17892C179.56 2.09636 174.461 -8.16528e-05 169.252 -8.16528e-05C164.043 -0.110421 158.944 2.09636 155.286 6.17892C142.872 15.7784 86.3441 56.3833 16.6261 64.8794C7.42638 65.9828 0.554333 73.7066 0.554333 82.9751V206.555C0.554333 207.99 0.443494 210.196 0.332655 212.624C-0.6649 238.995 -3.32505 309.06 63.1786 353.968C117.601 390.711 149.523 405.938 159.498 410.352C162.38 412.117 165.594 413 169.03 413C169.141 413 169.252 413 169.252 413C169.363 413 169.363 413 169.474 413C172.799 413 176.124 412.007 178.895 410.352C188.76 406.049 220.681 390.822 275.214 353.968C341.94 309.06 339.28 238.995 338.282 212.624Z" fill={"#010D48"}/>
            </g>
            <defs>
            <clipPath id="clip0_7537_28541">
            <rect width="408" height="413" fill="white"/>
            </clipPath>
            </defs>
        </motion.svg>
    );
};

export default ShieldAnimated;


