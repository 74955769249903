import React from 'react';
import './ArrowAnimation.scss';

const ArrowAnimation = () => {
  return (
    <svg width="97" height="39" viewBox="0 0 97 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3013_3052)">
        <path className="arrow" d="M25.009 19.5H25.0315L5.62254 0L0 5.6489L13.7865 19.5L0 33.3737L5.62254 39L25.0315 19.5H25.009Z" fill="url(#paint0_linear_3013_3052)" />
        <path className="arrow" d="M60.9933 19.5H61.0158L41.6068 0L35.9842 5.6489L49.7932 19.5L35.9842 33.3737L41.6068 39L61.0158 19.5H60.9933Z" fill="url(#paint1_linear_3013_3052)" />
        <path className="arrow" d="M96.9775 19.5H97L77.591 0L71.9685 5.6489L85.7549 19.5L71.9685 33.3737L77.591 39L97 19.5H96.9775Z" fill="url(#paint2_linear_3013_3052)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3013_3052" x1="-0.629724" y1="19.5" x2="94.706" y2="19.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADEE" />
          <stop offset="1" stopColor="#00B6A4" />
        </linearGradient>
        <linearGradient id="paint1_linear_3013_3052" x1="-0.629718" y1="19.5" x2="94.706" y2="19.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADEE" />
          <stop offset="1" stopColor="#00B6A4" />
        </linearGradient>
        <linearGradient id="paint2_linear_3013_3052" x1="-0.629731" y1="19.5" x2="94.706" y2="19.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADEE" />
          <stop offset="1" stopColor="#00B6A4" />
        </linearGradient>
        <clipPath id="clip0_3013_3052">
          <rect width="97" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowAnimation;