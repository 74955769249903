import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const textTransition = {
    hidden: { opacity: 0, y: 50 },
    visible: custom => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
            ease: "easeInOut",
        }
    })
};

const pathVariants = {
    hidden: {
      pathLength: 0,
      opacity: 0,
    },
    visible: custom => ({
        pathLength: 1,
        opacity: 1,
        transition: {
            delay: custom * 0.4,
            duration: 1,
            ease: "easeInOut",
        }
    })
};

const EdAnimatedIcon = ({type}) => {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.5 });

    if (type === "docEd_1") {
        return (
            <motion.svg 
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                width="238" height="204" viewBox="0 0 238 204" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7748_1276)">
                    <motion.path variants={pathVariants} custom={1} d="M44.5 24.8H233.2V199.9C233.2 199.9 26.5 199.9 19 199.9C11.5 199.9 4 192.1 4 180.1C4 168.1 8.8 162 19.7 160C30.6 158 34 168.2 34 168.2V11.2C34 11.2 31.3 3.40002 21.4 4.00002C11.5 4.60002 4 12.2 4 20.4C4 28.6 4 178.7 4 178.7" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 23.5H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 38.7H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 53.9H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 69.2H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 84.4H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 99.6H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 114.9H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 130.1H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M7.59961 145.3H18.5996" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M55.1992 68.4H206.999" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M194 143.5H207" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M75.7988 143.5H185.499" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M75.7988 99H185.499" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M55.1992 143.5H65.3992" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M65.3984 68.4V59.1" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M102.199 134.1V128.8" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M194.299 68.4V59.1" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M65.3984 152.8V80.6" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M194.299 152.8V80.6" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M153 143.5V99" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M102.199 88.9V68.4" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M102.199 120.4V99.9" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7748_1276">
                        <rect width="237.2" height="203.9" fill="white"/>
                    </clipPath>
                </defs>
            </motion.svg>
        )
    }

    if (type === "docEd_2") {
        return (
            <svg 
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                width="218" height="191" viewBox="0 0 218 191" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7764_1250)">
                    <motion.path variants={pathVariants} custom={1} d="M57.1 137.7L41.7 132.4L44.9 117.8C44.9 117.8 42 116.2 39.2 114.1C35.5 111.2 33.5 108.5 33.5 108.5L19.4 114.6L10.4 101L22.6 91.2C22.6 91.2 19.4 86.2 19.4 77.9L4 73.7L6.1 56.4H21.8C21.8 56.4 23.9 49.8 28.2 44.4L18.9 31.4L31.4 20L43.4 30C43.4 30 52.3 25.4 57.2 24.4L57.8 8.99999L74.5 8.29999L77 23.6C77 23.6 84.7 24.4 90.4 27.9L103 16.2L108.7 21" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M69.5008 97.2C82.8661 97.2 93.7008 86.3653 93.7008 73C93.7008 59.6347 82.8661 48.8 69.5008 48.8C56.1355 48.8 45.3008 59.6347 45.3008 73C45.3008 86.3653 56.1355 97.2 69.5008 97.2Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M179.6 55.6L171.6 46.3L161.7 42.5L136 12.6L146 4L184 27.5L209.2 56.9L199.6 65.1L213.5 81.3C207.8 85.7 200.8 87.9 195.4 85.7C190 83.5 185 76.3 185 76.3L171.3 88C171.3 88 172.9 89.9 175.4 92.8C178.6 96.5 179.2 101.8 178.2 106.8C177.2 111.9 172.4 116.5 172.4 116.5L157.8 99.5L56.3004 186.7L38.9004 166.4L103.4 111.1" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M174.701 50L151.301 70" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M97.4996 133.1L89.5996 123.9" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M79.9996 148.2L72.0996 138.9" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M62.4996 163.2L54.5996 154" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M155.8 77.3L112.2 114.7L101.9 112.4L100.6 100.2L126.8 77.8L108.6 36.1L119.1 27.1L145.6 57.9L148.4 68.6L155.8 77.3Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7764_1250">
                        <rect width="217.5" height="190.7" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    if (type === "docEd_3") {
        return (
            <svg 
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                width="268" height="185" viewBox="0 0 268 185" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7748_1302)">
                    <motion.path variants={pathVariants} custom={1} d="M17.3008 132V11.3C17.3008 11.3 17.3008 4 24.8008 4C32.3008 4 242.501 4 242.501 4C242.501 4 250.301 5.1 250.301 10.4C250.301 15.7 250.301 132.2 250.301 132.2" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M109.6 149.9H258.3C258.3 149.9 263.6 149.3 263.6 153.3C263.6 156.8 263 162.4 259.7 168.2C255.4 175.9 248.9 180.1 243.2 180.1C238.4 180.1 114.9 180.1 114.9 180.1" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M125.8 149.9H9.3C9.3 149.9 4 149.3 4 153.3C4 156.8 4.6 162.4 7.9 168.2C12.2 175.9 18.7 180.1 24.4 180.1C29.2 180.1 120.5 180.1 120.5 180.1" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M110.9 165H156.7" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M43.6992 38.5H178.599" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M168.398 105.3H178.598" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M62.0996 105.3H158.2" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M62.0996 65.7H158.2" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M43.6992 105.3H52.7992" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M52.7988 38.5V30.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M85.5 96.9V92.4" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M167.299 38.5V30.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M52.7988 113.5V49.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M167.299 113.5V49.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M130.6 105.3V65.7" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M85.5 56.7V38.5" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M85.5 83.6V66.5" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.circle variants={textTransition} custom={1} cx="195.398" cy="87.8" r="4" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M208.898 87.8H223.898" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M195.398 99.7V111.8" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M209.299 99.7V111.8" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M223.199 99.7V111.8" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7748_1302">
                        <rect width="267.6" height="184.1" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    if (type === "docEd_4") {
        return (
            <svg
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"} 
                width="257" height="185" viewBox="0 0 257 185" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7764_1260)">
                    <motion.path variants={pathVariants} custom={1} d="M4 180.6H142.1" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M16.8008 171.3V32.3H129.501V171.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M52.7992 56H40.1992V75.7H52.7992V56Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M52.7992 88.5H40.1992V108.2H52.7992V88.5Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M52.7992 122H40.1992V141.7H52.7992V122Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M40.9004 171.3V155.8H106.6V171.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M55.0996 171.3V155.8" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M85.1992 171.3V155.8" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M69.7004 52.9H63.4004V59.2H69.7004V52.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M83.1008 52.9H76.8008V59.2H83.1008V52.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M96.3996 52.9H90.0996V59.2H96.3996V52.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M109.8 52.9H103.5V59.2H109.8V52.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M69.7004 85.4H63.4004V91.7H69.7004V85.4Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M83.1008 85.4H76.8008V91.7H83.1008V85.4Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M96.3996 85.4H90.0996V91.7H96.3996V85.4Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M109.8 85.4H103.5V91.7H109.8V85.4Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M69.7004 118.9H63.4004V125.2H69.7004V118.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M83.1008 118.9H76.8008V125.2H83.1008V118.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M96.3996 118.9H90.0996V125.2H96.3996V118.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M109.8 118.9H103.5V125.2H109.8V118.9Z" fill="#00F0FF"/>
                    <motion.path variants={pathVariants} custom={1} d="M153.199 4V180.6H252.799L153.199 4Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M209.9 152.9H179V98.4L209.9 152.9Z" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M208.801 168.9V180.6" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M182.801 168.9V180.6" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M221.801 172.6V180.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M195.801 172.6V180.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M169.801 172.6V180.3" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M165.8 151.8H154" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M165.8 126.1H154" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M162.101 164.6H154.301" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M162.101 138.9H154.301" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M162.101 113.2H154.301" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M165.8 100.4H154" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M162.101 87.6H154.301" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M165.8 74.7H154" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M162.101 61.9H154.301" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M165.8 49H154" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M162.101 36.2H154.301" stroke="#00F0FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7764_1260">
                        <rect width="256.8" height="184.6" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    if (type === "listEd_1") {
        return (
            <motion.svg 
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                width="147" height="174" viewBox="0 0 147 174" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7815_2146)">
                    <motion.path variants={pathVariants} custom={1} d="M126.1 114.6V163.4C126.1 167.2 123 170.4 119.1 170.4H10C6.2 170.4 3 167.3 3 163.4V29.8C3 26 6.1 22.8 10 22.8H22.3" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M107.1 22.8H119.2C123 22.8 126.2 25.9 126.2 29.8V60.3" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M47.9992 122.8H24.6992V146.1H47.9992V122.8Z" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M47.9992 69V73.3H24.6992V50H35.9992" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M33.2988 60.1L37.7988 63.8L46.6988 55.8" stroke="#00F0FF" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M47.9992 105.1V109.4H24.6992V86.1001H35.9992" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M33.2988 96.2001L37.7988 99.9001L46.6988 91.9001" stroke="#00F0FF" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M60.0996 53.7H102.6" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M60.0996 89.3999H94.4996" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M60.0996 67.3H89.9996" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M60.0996 103.6H82.0996" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M60.0996 125.9H67.0996" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M89.8 14.1C87.3 14.1 80.7 14.1 80.7 14.1V7.3C80.7 7.3 79.9 3 75.5 3C74 3 71.7 3 69.3 3C69.3 3 63.9 3 59 3C56.6 3 54.3 3 52.8 3C48.3 3 47.6 7.3 47.6 7.3V14.1C47.6 14.1 41 14.1 38.5 14.1C36 14.1 33.5 16.4 33.5 18.6C33.5 20.7 33.5 29.1 33.5 29.1H61.3H67.3H95.1C95.1 29.1 95.1 20.8 95.1 18.6C94.8 16.4 92.3 14.1 89.8 14.1Z" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M140.5 72.4C137.4 69.3 132.8 68.8 130.3 71.3L79 122.6L74 138.9L90.3 133.9L141.6 82.6C144.1 80.1 143.6 75.5 140.5 72.4Z" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={1} d="M121.301 80.3999L132.601 91.6999" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7815_2146">
                        <rect width="146.2" height="173.4" fill="white"/>
                    </clipPath>
                </defs>
            </motion.svg>
        )
    }

    if (type === "listEd_2") {
        return (
            <motion.svg 
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                width="158" height="176" viewBox="0 0 158 176" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7815_2188)">
                    <motion.path variants={pathVariants} custom={2} d="M134.7 173H23.3C12.1 173 3 163.9 3 152.7V41C3 29.8 12.1 20.7 23.3 20.7H134.7C145.9 20.7 155 29.8 155 41V152.8C155 163.9 145.9 173 134.7 173Z" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M36.6992 3V35" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M119.699 3V35" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M39.9008 100H27.9008C26.7008 100 25.8008 99 25.8008 97.9V85.9C25.8008 84.7 26.8008 83.8 27.9008 83.8H39.9008C41.1008 83.8 42.0008 84.8 42.0008 85.9V97.9C42.0008 99 41.0008 100 39.9008 100Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M98.5004 100H86.5004C85.3004 100 84.4004 99 84.4004 97.9V85.9C84.4004 84.7 85.4004 83.8 86.5004 83.8H98.5004C99.7004 83.8 100.6 84.8 100.6 85.9V97.9C100.7 99 99.7004 100 98.5004 100Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M127.901 100H115.901C114.701 100 113.801 99 113.801 97.9V85.9C113.801 84.7 114.801 83.8 115.901 83.8H127.901C129.101 83.8 130.001 84.8 130.001 85.9V97.9C130.001 99 129.001 100 127.901 100Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M39.9008 156.2H27.9008C26.7008 156.2 25.8008 155.2 25.8008 154.1V142.1C25.8008 140.9 26.8008 140 27.9008 140H39.9008C41.1008 140 42.0008 141 42.0008 142.1V154.1C42.0008 155.3 41.0008 156.2 39.9008 156.2Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M98.5004 156.2H86.5004C85.3004 156.2 84.4004 155.2 84.4004 154.1V142.1C84.4004 140.9 85.4004 140 86.5004 140H98.5004C99.7004 140 100.6 141 100.6 142.1V154.1C100.7 155.3 99.7004 156.2 98.5004 156.2Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M39.9008 128.1H27.9008C26.7008 128.1 25.8008 127.1 25.8008 126V114C25.8008 112.8 26.8008 111.9 27.9008 111.9H39.9008C41.1008 111.9 42.0008 112.9 42.0008 114V126C42.0008 127.1 41.0008 128.1 39.9008 128.1Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M69.1996 128.1H57.1996C55.9996 128.1 55.0996 127.1 55.0996 126V114C55.0996 112.8 56.0996 111.9 57.1996 111.9H69.1996C70.3996 111.9 71.2996 112.9 71.2996 114V126C71.2996 127.1 70.3996 128.1 69.1996 128.1Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M127.901 128.1H115.901C114.701 128.1 113.801 127.1 113.801 126V114C113.801 112.8 114.801 111.9 115.901 111.9H127.901C129.101 111.9 130.001 112.9 130.001 114V126C130.001 127.1 129.001 128.1 127.901 128.1Z" fill="white"/>
                    <motion.path variants={pathVariants} custom={2} d="M3 68.3999H155" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M74.8 98.7V103H51.5V79.7H62.8" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M60.0996 89.8L64.4996 93.5L73.4996 85.5" stroke="#00F0FF" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M104.1 127.3V131.6H80.9004V108.3H92.1004" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M89.4004 118.4L93.9004 122.1L102.8 114.1" stroke="#00F0FF" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M74.8 155.4V159.7H51.5V136.4H62.8" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={2} d="M60.0996 146.5L64.4996 150.2L73.4996 142.2" stroke="#00F0FF" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7815_2188">
                        <rect width="158" height="176" fill="white"/>
                    </clipPath>
                </defs>
            </motion.svg>
        )
    }

    if (type === "listEd_3") {
        return (
            <motion.svg 
                ref={containerRef} 
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                width="197" height="171" viewBox="0 0 197 171" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_7818_2211)">
                    <motion.path variants={pathVariants} custom={3} d="M170.3 122.2C170.1 125.6 169.3 127.9 169.3 127.9L178.7 133.8L174.3 144.7L163.5 141.5C163.5 141.5 161.8 145.5 156.9 148.8L160.7 159.7L149.6 165.5L143.2 156.2C143.2 156.2 138.4 157.6 133.5 157.3L129.6 168.1L117.7 165.3L118.7 154.1C118.7 154.1 112.3 150.7 109.7 148.2L100.3 154.1L93.0996 144.4L101.1 136.7" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={3} d="M117.301 127.3C117.801 128.5 118.401 129.7 119.201 130.9C124.701 138.8 135.501 140.9 143.501 135.4C146.901 133.1 149.201 129.8 150.301 126.1" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={3} d="M137.8 115.4C168.838 115.4 194 90.2384 194 59.2C194 28.1616 168.838 3 137.8 3C106.761 3 81.5996 28.1616 81.5996 59.2C81.5996 90.2384 106.761 115.4 137.8 115.4Z" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={3} d="M137.801 22.5V60.3L158.601 72.3" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={3} d="M67.9 45L61.1 56.4H50.6L42.1 43.5L28.9 50L32.9 62.4L23.4 71.2L10 66.5L4.8 80.4L15.7 87.9L15.5 99.3L3 107.2L8.5 119.9L20.8 116.8L29.8 126.1L27.2 138.4L39.5 143.4L47.4 133.3L59.2 133.5L66.2 145.1L79.4 140.6L76.1 127.4L85.8 118.4L97.4 122.2" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <motion.path variants={pathVariants} custom={3} d="M53.8992 107.5C61.1894 107.5 67.0992 101.59 67.0992 94.3001C67.0992 87.0099 61.1894 81.1001 53.8992 81.1001C46.6091 81.1001 40.6992 87.0099 40.6992 94.3001C40.6992 101.59 46.6091 107.5 53.8992 107.5Z" stroke="white" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_7818_2211">
                        <rect width="197" height="171" fill="white"/>
                    </clipPath>
                </defs>
            </motion.svg>
        )
    }
};

export default EdAnimatedIcon;