import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion"
import NewsLine from "../../components/NewsLine/NewsLine";
import TextAnimated from "../../components/TextAnimated/TextAnimated";
import { fetchData } from "../../api/fetchData";

const NewsPage = () => {
    const [sortedNews, setSortedNews] = useState([]);

    const newsScrollRef = useRef(null);

    const scrollToElement = () => {
        newsScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const lines = [];

        fetchData()
        .then((data) => {
            const sortedData = data.data.sort((a, b) => b.date_number - a.date_number);
            for (let i = 0; i < sortedData.length; i += 3) {
                lines.push(sortedData.slice(i, i + 3));
            }
            setSortedNews(lines);
        })
    }, [])

    return (
        <div className="news-page">
            <motion.div 
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true }}
                className="section-header"
            >
                <p className="section-header__h1">
                    <TextAnimated text={'Новости'} fontSize={76} order={1}/>
                </p>

                <button className="btn round-arrow-btn" onClick={scrollToElement}>
                    <img src="images/icons/arrow-small.svg" alt="" className="icon-arrow"/>
                    <img src="images/icons/arrow-small.svg" alt="" className="icon-arrow icon-arrow--outside"/>
                </button>
            </motion.div>

            <div className="section-all-news" ref={newsScrollRef}>
                <div className="section-all-news__title">Все новости</div>
                {sortedNews.map((newsLine, idx) => (
                    <NewsLine line={newsLine} index={idx}/>
                ))}
            </div>

        </div>
    );
};

export default NewsPage;
