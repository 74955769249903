import React, { useEffect, useRef } from 'react'
import "./AnimatedLeftHalfItem.scss"

const AnimatedLeftHalfItem = ({r, angleDegrees, dur = 0, type = '', reverse = true}) => {
  const circleRef = useRef(null);

function getCirclePoint(cx, cy, r, angleDegrees) {
  let angleRadians = angleDegrees * (Math.PI / 180);

  let x = cx + r * Math.cos(angleRadians);
  let y = cy + r * Math.sin(angleRadians);

  return { x: x, y: y };
}

let cx = 650;
let cy = 650;

let angle = getCirclePoint(cx, cy, r, angleDegrees);

useEffect(() => {
  const circle = circleRef.current;

  const animateCircle = () => {
    circle.style.animation = `moveCircle ${240 + dur}s linear infinite ${reverse ? 'reverse' : ''}`;
  };

  animateCircle();
}, [])

if (type === 'half') {
  return (
    <circle
      ref={circleRef}
      className='circle'
      r="14.7"
      fill="#021047"
      style={{ strokeWidth: 7.3, stroke: 'url(#radial-gradient)' }}
      cx={angle.x}
      cy={angle.y}
    ></circle>  
  )
}

return (
    <circle
        ref={circleRef}
        className='circle'
        r="14.7"
        fill="url(#radial-gradient)"
        cx={angle.x}
        cy={angle.y}
    ></circle>
)
}

export default AnimatedLeftHalfItem