import React from 'react';
import AnimatedLeftHalfItem from './AnimatedLeftHalfItem/AnimatedLeftHalfItem';

const AnimatedSVGLeftHalf = ({ classStyle }) => {
  return (
    <svg className={`svg-element ${classStyle}`} width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <radialGradient id="radial-gradient" cx="639.77" cy="652.7" r="639.77" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#00aeef"/>
          <stop offset="1" stopColor="#00b7a5"/>
        </radialGradient>
      </defs>
      <circle cx="650" cy="650" r="637.77" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="607.4" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="577.04" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="546.67" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="516.31" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="485.94" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="455.58" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="425.21" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="394.85" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="364.48" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>



      <AnimatedLeftHalfItem r={364.48} angleDegrees={70} />
      <AnimatedLeftHalfItem r={425.21} angleDegrees={230} />
      <AnimatedLeftHalfItem r={516.31} angleDegrees={45} />
      <AnimatedLeftHalfItem r={516.31} angleDegrees={85} />

      <AnimatedLeftHalfItem r={577.04} angleDegrees={180} />
      <AnimatedLeftHalfItem r={577.04} angleDegrees={355} />

      <AnimatedLeftHalfItem r={607.4} angleDegrees={310} />
      <AnimatedLeftHalfItem r={637.77} angleDegrees={130} />
      <AnimatedLeftHalfItem r={637.77} angleDegrees={270} />


      <AnimatedLeftHalfItem r={364.48} angleDegrees={260} type='half' dur={18}/>
      <AnimatedLeftHalfItem r={394.85} angleDegrees={115} type='half' dur={18} />
      <AnimatedLeftHalfItem r={455.58} angleDegrees={300} type='half' dur={18}/>
      <AnimatedLeftHalfItem r={485.94} angleDegrees={150} type='half' dur={18} />
      <AnimatedLeftHalfItem r={516.31} angleDegrees={200} type='half' dur={18} />

      <AnimatedLeftHalfItem r={577.04} angleDegrees={110} type='half' dur={18}/>
      <AnimatedLeftHalfItem r={577.04} angleDegrees={255} type='half' dur={18} />

      <AnimatedLeftHalfItem r={637.77} angleDegrees={50} type='half' dur={18}/>
  </svg>

  );
}

export default AnimatedSVGLeftHalf;
