// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/scroll_arrow_white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes circleRotate{from{transform:translate3d(0px, 0px, 0px) rotate(0deg)}to{transform:translate3d(0px, 0px, 0px) rotate(360deg)}}.arrowBottom_wrapper{width:100px;height:100px;position:relative}.arrowBottom_svg{animation:circleRotate 26s linear infinite;height:100%;width:100%}.arrowBottom_svg circle{fill:#fff}.arrowBottom__arrow{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-size:25%;background-position:center;width:100px;height:100px}.center{left:50%;position:absolute;top:50%;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./src/components/ArrowBottom/ArrowBottom.scss"],"names":[],"mappings":"AAAA,wBACI,KACI,iDAAA,CAEJ,GACI,mDAAA,CAAA,CAIR,qBACI,WAAA,CACA,YAAA,CACA,iBAAA,CAEJ,iBACI,0CAAA,CACA,WAAA,CACA,UAAA,CAEJ,wBACI,SAAA,CAEJ,oBACI,wDAAA,CACA,2BAAA,CACA,mBAAA,CACA,0BAAA,CACA,WAAA,CACA,YAAA,CAEJ,QACI,QAAA,CACA,iBAAA,CACA,OAAA,CACA,+BAAA","sourcesContent":["@keyframes circleRotate {\n    from {\n        transform: translate3d(0px, 0px, 0px) rotate(0deg);\n    }\n    to {\n        transform: translate3d(0px, 0px, 0px) rotate(360deg);\n    }\n        \n}\n.arrowBottom_wrapper {\n    width: 100px;\n    height: 100px;\n    position: relative;\n}\n.arrowBottom_svg {\n    animation: circleRotate 26s linear infinite;\n    height: 100%;\n    width: 100%;\n}\n.arrowBottom_svg circle {\n    fill: #fff;\n}\n.arrowBottom__arrow {\n    background-image: url('/public/images/scroll_arrow_white.svg');\n    background-repeat: no-repeat;\n    background-size: 25%;\n    background-position: center;\n    width: 100px;\n    height: 100px;\n}\n.center{\n    left:50%;\n    position:absolute;\n    top:50%;\n    transform:translate(-50%,-50%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
