import axios from 'axios';

export const fetchData = async () => {
    try {
        const response = await axios.get('https://gitlab.sdisol.ru/api/v4/projects/32/repository/files/src%2Fdata.json/raw?ref=main', {
            headers: {
            'PRIVATE-TOKEN': 'tfoRRgFfzBHL7i1UYQBg',
            }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error.message);
        return error.message
    }
};
