import React, { useRef, useEffect, useState } from 'react';
import "./NewsCarousel.scss";
import Icon from "../Icon";
import NewsCard from "../NewsCard/NewsCard";
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { animateLeftMove, animateRightMove } from '../../api/animation-varints';
const NEWS_SCROLL_X_SIZE_PX = 450;

const Carousel = ({ items }) => {

  const carouselRef = useRef(null);
  const [isPrevButtonActive, setIsPrevButtonActive] = useState(false);
  const [isNextButtonActive, setIsNextButtonActive] = useState(true);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    if (carouselRef.current) {
      const scrollLeft = carouselRef.current.scrollLeft;
      const scrollWidth = carouselRef.current.scrollWidth;
      const clientWidth = carouselRef.current.clientWidth;

      const isScrollEnd = scrollLeft + clientWidth === scrollWidth;

      setIsPrevButtonActive(scrollLeft > 0);
      setIsNextButtonActive(!isScrollEnd);
    }
  };

  const scrollCarousel = (scrollOffset) => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: scrollOffset,
        behavior: 'smooth',
      });
    }
  };

  const ButtonsBar = () => {
    return (
        <div 
          className="carousel-buttons"
        >
            <div className="carousel-buttons__arrows">
                <div className={`carousel-button  ${isPrevButtonActive ? '' : 'carousel-button--inactive'}`}
                     onClick={() => scrollCarousel(-NEWS_SCROLL_X_SIZE_PX)}
                >
                    <Icon type="carousel-arrow-left"/>
                </div>
                <div className={`carousel-button  ${isNextButtonActive ? '' : 'carousel-button--inactive'}`}
                     onClick={() => scrollCarousel(NEWS_SCROLL_X_SIZE_PX)}
                >
                    <Icon type="carousel-arrow-right"/>
                </div>
            </div>

            <div className="carousel-buttons__all">
              <Link to="/news">
                посмотреть все новости
              </Link>
            </div>
        </div>
    );
  };

    return (
        <>
            <motion.div 
              whileInView="visible"
              initial="hidden"
              viewport={{ once: true }}
              className="carousel" ref={carouselRef}>
                {items.map((item, index) => (
                    <NewsCard item={item} index={index}/>
                ))}
            </motion.div>
            <ButtonsBar/>
        </>
    );
};


const NewsCarousel = ({news}) => {
    return (
        <motion.div 
          whileInView="visible"
          initial="hidden"
          viewport={{ once: true }}
          className="news-carousel"
        >
            <motion.h1 custom={1} variants={animateLeftMove} >Новости</motion.h1>
            <Carousel items={news}/>
        </motion.div>
    );
};

export default NewsCarousel;
