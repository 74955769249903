import React, { useRef } from 'react'
import "./About.scss"
import AnimatedLine from '../../components/InstrumentsLine/AnimatedLine'
import AnimatedSVGCircle from '../../components/AnimatedSVG/AnimatedSVGCircle'
import TextAnimated from '../../components/TextAnimated/TextAnimated'
import { motion } from "framer-motion";

const About = () => {
    const missionRef = useRef(null);
    const teamsRef = useRef(null);
    const performanceRef = useRef(null);
    
    const borderAnimate = {
        hidden: { width: 0 },
        visible: custom => ({
            width: '100%',
            y: 0,
            transition: {
                duration: .5,
                delay: custom * 0.2,
                ease: "easeInOut",
            }
        })
    };

    const borderAnimateMin = {
        hidden: { width: 0 },
        visible: custom => ({
            width: '220px',
            y: 0,
            transition: {
                duration: .5,
                delay: custom * 0.2,
                ease: "easeInOut",
            }
        })
    };

    const opacityAnimate = {
        hidden: { opacity: 0 },
        visible: custom => ({
            opacity: 1,
            transition: {
                duration: .2,
                delay: custom * 0.2,
                ease: "easeInOut",
            }
        })
    };

    const handleScroll = (targetRef) => {
        if (targetRef.current) {
          targetRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
    };

    return (
        <div className='about'>
            <section className='about_top'>
                <div className='container'>
                    <div className='about_header'>
                        <h1>
                            <span><TextAnimated order={1} text={`Смарт Цифровые Решения`} fontSize={44} /></span>
                            <TextAnimated order={2} text={`российская компания аккредитованная Миницифры РФ на осуществление деятельности в сфере информационных технологий`} fontSize={44} /> 
                        </h1>
                        <p>
                            <TextAnimated order={3} text={`Компания была создана российскими специалистами, обладающими значительным опытом работы на промышленных объектах`} fontSize={22} />
                        </p>
                    </div>
                    <motion.ul
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true }} 
                        className='about_list'
                    >
                        <li className='about_list-item' onClick={() => handleScroll(missionRef)}>
                            <motion.div variants={borderAnimate} custom={4} className='about_border_min'></motion.div>
                            <span><TextAnimated order={4} text={`01`} fontSize={20} /></span>  
                            <span><TextAnimated order={5} text={`Миссия компании`} fontSize={20} /></span> 
                        </li>
                        <li className='about_list-item' onClick={() => handleScroll(teamsRef)}>
                            <motion.div variants={borderAnimate} custom={4} className='about_border_min'></motion.div>
                            <span><TextAnimated order={4} text={`02`} fontSize={20} /></span>  
                            <span><TextAnimated order={5} text={`О команде`} fontSize={20} /></span> 
                        </li>
                        <li className='about_list-item' onClick={() => handleScroll(performanceRef)}>
                            <motion.div variants={borderAnimate} custom={4} className='about_border_min'></motion.div>
                            <span><TextAnimated order={4} text={`03`} fontSize={20} /></span>  
                            <span><TextAnimated order={5} text={`Деятельность`} fontSize={20} /></span> 
                        </li>
                    </motion.ul>
                    <button className="btn round-arrow-btn" style={{ bottom: '4rem' }} onClick={() => handleScroll(missionRef)}>
                        <img src="images/icons/arrow-small.svg" alt="" className="icon-arrow"/>
                        <img src="images/icons/arrow-small.svg" alt="" className="icon-arrow icon-arrow--outside"/>
                    </button>
                </div>
            </section>
        

            <section className='about_mission' ref={missionRef}>
                <div className='container'>
                    <div className='about_mission_content'>
                        <h3><TextAnimated order={1} text={`Миссия компании`} fontSize={68} /></h3>
                        <p>
                            <TextAnimated order={2} text={`Cоздание, развитие и внедрение передовых IT-решений для промышленных предприятий с целью повышения эффективности технологических и производственных процессов и повышения уровня безопасности эксплуатации Опасных Производственных Объектов`} fontSize={44} />
                            
                        </p>
                    </div>
                </div>   
            </section>

            <motion.section 
                ref={teamsRef}
                className='about_team'
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true }} 
            >
                <div className='container'>
                    <div className='about_team_title'>
                        <motion.div variants={borderAnimate} custom={1} className='about_border_max'></motion.div>
                        <TextAnimated order={1} text={`Наша команда это:`} fontSize={22} />
                    </div>
                    <div className='about_team_content'>
                        <AnimatedSVGCircle classStyle={"about-page-round-big-element"}/>
                        <div className='about_team_item'>
                            <motion.div variants={borderAnimateMin} custom={2} className='about_border_min'></motion.div>
                            <div className='about_team_item-number'><TextAnimated order={2} text={`01`} fontSize={20} /></div>
                            <p className='about_team_item-text'>
                                <span><TextAnimated order={2} text={`Cпециалисты в области промышленной автоматизации,`} fontSize={24} /></span>
                                <TextAnimated order={2} text={`обладающие уникальным опытом разработки и внедрения АСУ ТП, ПАЗ, АСПС КЗ и ПТ, на промышленных объектах ключевых компаниях ТЭК РФ (ПАО "Газпром", ПАО "Роснефть", ПАО "Транснефть", ПАО "Лукойл" и.т.д.)`} fontSize={24} />   
                            </p>
                        </div>
                        <div className='about_team_item'>
                            <motion.div variants={borderAnimateMin} custom={3} className='about_border_min'></motion.div>
                            <div className='about_team_item-number'><TextAnimated order={3} text={`02`} fontSize={20} /></div>
                            <p className='about_team_item-text'>
                                <span><TextAnimated order={3} text={`Программисты и аналитики,`} fontSize={24} /></span>
                                <TextAnimated order={3} text={`имеющие значительные компетенции в области разработки систем обработки и анализа данных, длительное время занимающихся разработкой и внедрением программных продуктов для консалтинговых компаний и банков (ПАО "Сбербанк", ПАО "Альфа-Банк" Softline и.т.д.)"`} fontSize={24} />
                            </p>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section 
                ref={performanceRef}
                className='about_performance'
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true }}
            >
                <div className='container'>
                    <div className='about_performance_title'>
                        <motion.div variants={borderAnimate} custom={1} className='about_border_max'></motion.div>
                        <TextAnimated order={1} text={`Наша деятельность:`} fontSize={22} />
                    </div> 
                </div>  
                <div className='about_performance_items'>
                    <div className='about_performance_item about_performance_item-down' style={{marginTop: 52, backgroundImage: 'url(images/about/performance_1.svg)'}}>
                        <div className='about_performance_item-text'>
                            <TextAnimated order={1} text={`Наши цифровые решения позволяют промышленным предприятиям произвести цифровую трансформацию управляемо.`} fontSize={30} />    
                        </div>
                    </div>
                    <div className='about_performance_item' style={{backgroundImage: 'url(images/about/performance_2.svg)'}}>
                        <div className='about_performance_item-text'>
                            <TextAnimated order={2} text={`Главным приоритетом для нас является потребности Заказчика, которые, благодаря имеющемуся промышленному опыту, нам понятны.`} fontSize={30} />     
                        </div>
                    </div>
                    <div className='about_performance_item' style={{marginTop: 52, backgroundImage: 'url(images/about/performance_3.svg)'}}>
                        <div className='about_performance_item-text'>
                            <TextAnimated order={3} text={`Способность говорить на одном языке с эксплуатирующим персоналом промышленных предприятий является нашим основным конкурентным преимуществом.`} fontSize={30} />    
                        </div>
                    </div>
                    <div className='about_performance_item' style={{backgroundImage: 'url(images/about/performance_4.svg)'}}>
                        <div className='about_performance_item-text'>
                            <TextAnimated order={4} text={`Мы непрерывно развиваем наши продукты, благодаря чему решения предлагаемые нами всегда актуальны.`} fontSize={30} />    
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section 
                className='about_activity'
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true }}
            >
                <div className='container'>
                    <motion.div variants={borderAnimate} custom={1} className='about_border_min'></motion.div>
                    <div className='about_activity_item'>
                        <div className='about_activity_item-img'>
                            {/* <img src='images/about/activity_1.svg'> */}
                            <svg width="154" height="154" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7714_1580)">
                                <motion.path variants={opacityAnimate} custom={1} d="M78.2219 154V125.889L76.2663 126.622C74.3107 127.355 72.5996 127.6 70.6441 127.6C61.8441 127.6 54.5107 120.267 54.5107 111.467C54.5107 102.667 61.8441 95.3332 70.6441 95.3332C72.5996 95.3332 74.5552 95.5777 76.2663 96.311L78.2219 97.0443V78.4666H95.5774C95.0885 79.9332 94.8441 81.6443 94.8441 83.3554C94.8441 93.8666 103.4 102.178 113.666 102.178C123.933 102.178 132.489 93.6221 132.489 83.3554C132.489 81.6443 132.244 80.1777 131.755 78.4666H154V121C154 139.089 139.089 154 121 154H78.2219Z" fill="#00F0FF"/>
                                <motion.path variants={opacityAnimate} custom={2} d="M113.912 99.2444C105.112 99.2444 97.7782 91.9111 97.7782 83.1111C97.7782 81.1556 98.0227 79.2 98.756 77.4889L99.4893 75.5333H78.2227V51.5778C81.156 53.5333 84.5782 54.5111 88.2449 54.5111C98.756 54.5111 107.067 45.9556 107.067 35.6889C107.067 25.4222 98.5115 16.8667 88.2449 16.8667C84.8227 16.8667 81.4004 17.8444 78.2227 19.8V0H120.756C139.089 0 154 14.9111 154 33V75.5333H128.334L129.067 77.4889C129.8 79.4444 130.045 81.1556 130.045 83.1111C130.045 92.1556 122.956 99.2444 113.912 99.2444Z" fill="#00FFDB"/>
                                <motion.path variants={opacityAnimate} custom={3} d="M54.0222 75.5333C55.9778 72.6 56.9556 69.1778 56.9556 65.5111C56.9556 55 48.4 46.6889 38.1333 46.6889C27.8667 46.6889 19.3111 55.2444 19.3111 65.5111C19.3111 68.9333 20.2889 72.3556 22.2444 75.5333H0V33C0 14.9111 14.9111 0 33 0H75.5333V25.1778L77.7333 23.2222C80.6667 20.7778 84.3333 19.3111 88.2444 19.3111C97.0444 19.3111 104.378 26.6444 104.378 35.4444C104.378 44.2444 97.0444 51.5778 88.2444 51.5778C84.3333 51.5778 80.6667 50.1111 77.7333 47.6667L75.5333 45.7111V75.5333H54.0222Z" fill="#00F0FF"/>
                                <motion.path variants={opacityAnimate} custom={4} d="M33 154C14.9111 154 0 139.089 0 121V78.4668H27.6222L25.6667 76.2668C23.2222 73.3334 21.7556 69.6668 21.7556 65.7556C21.7556 56.9556 29.0889 49.6223 37.8889 49.6223C46.6889 49.6223 54.0222 56.9556 54.0222 65.7556C54.0222 69.6668 52.5556 73.3334 50.1111 76.2668L48.1556 78.4668H75.2889V93.1334C73.8222 92.6445 72.1111 92.4001 70.4 92.4001C59.8889 92.4001 51.5778 100.956 51.5778 111.222C51.5778 121.733 60.1333 130.045 70.4 130.045C72.1111 130.045 73.5778 129.8 75.2889 129.311V154H33Z" fill="#00F0FF"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_7714_1580">
                                <rect width="154" height="154" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className='about_activity_item-text'>
                            <TextAnimated order={1} text={`ООО «Смарт Цифровые Решения» входит в группу компаний вместе с проектным институтом ООО «Смарт НефтеГаз», осуществляющим полный комплекс проектно-изыскательских работ для промышленных предприятий, благодаря чему компания имеет возможность использовать знание и опыт соответствующих специалистов: технологов, механиков, метрологов, экологов, строителей и.т.д.`} fontSize={30} />
                        </div>
                    </div>
                    <motion.div variants={borderAnimate} custom={2} className='about_border_min'></motion.div>
                    <div className='about_activity_item'>
                        <div className='about_activity_item-img'>
                            <motion.img variants={opacityAnimate} custom={2} src='images/about/activity_2.svg'/>
                        </div>
                        <div className='about_activity_item-text'>
                            <TextAnimated order={2} text={`Компания осуществляет научную деятельность в области разработки эффективных методов прогнозирования и моделирования состояния технологических процессов нефтегазохимии.`} fontSize={30} />                            
                        </div>
                    </div>
                </div>
            </motion.section>

            <section className='about_partners'>
                <div className='container'>
                    <h3 className='about_partners_title'>Наши Заказчики:</h3>
                </div>
                <AnimatedLine partners={true}/>
            </section>
        </div>
    )
}

export default About