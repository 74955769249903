export const animateLeftMove = {
    hidden: {
        opacity: 0,
        x: -400
    },
    visible: custom => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
        }
    })
}

export const animateLeftMoveText = {
    hidden: {
        opacity: 0,
        x: '-50%'
    },
    visible: custom => ({
        opacity: 1,
        x: '-2%',
        transition: {
            duration: .5,
            delay: custom * 0.4,
        }
    })
}

export const animateRightMove = {
    hidden: {
        opacity: 0,
        x: 400
    },
    visible: custom => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
        }
    })
}

export const animateTopMove = {
    hidden: {
        opacity: 0,
        y: 100
    },
    visible: custom => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: .3,
            delay: custom * 0.2,
        }
    })
}

export const animateBottomMove = {
    hidden: {
        opacity: 0,
        y: -200
    },
    visible: custom => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
        }
    })
}

export const animateBottomMove2 = {
    hidden: {
        opacity: 0,
        y: -50
    },
    visible: custom => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            delay: custom * 0.4,
        }
    })
}

export const menuVariants = {
    hidden: {
        opacity: 0,
        x: '100vw',
    },
    visible: {
        opacity: 1,
        x: "0",
        transition: {
            ease: "linear",
        }
    },
    // на скрытие анимация не работает пока
    exit: {
        x: '100vw',
        transition: {ease: 'linear'}
    }
}

export const menuContactsVariants = {
    hidden: {
        opacity: 0,
        x: '100vw',
    },
    visible: {
        opacity: 1,
        x: "50vw",
        transition: {
            ease: "linear",
        }
    },
    // на скрытие анимация не работает пока
    exit: {
        x: '100vw',
        transition: {ease: 'linear'}
    }
}