import React, { useRef, useState, useReducer, useEffect } from "react";
import { useParallax } from "react-scroll-parallax";
import Icon from "../components/Icon";
import AnimText from '../components/AnimText/AnimText'
import { Link } from "react-router-dom";
import ArrowAnimation from "../components/ArrowAnimation/ArrowAnimation";
import TextAnimated from "../components/TextAnimated/TextAnimated";

const TopMenu = () => {
  const target = useRef(null);
  const [progressVal, setProgressVal] = useState(0);
  const [prevProgressVal, setPrevProgressVal] = useState(0);

  // useEffect(() => {

  //   document.querySelectorAll('a[href^="#"]').forEach((anchor,i) => {
  //     anchor.addEventListener('click', function (e) {
  //       e.preventDefault();

  //       document.querySelector(this.getAttribute('href')).scrollIntoView({
  //         behavior: 'smooth'
  //       });
  //     });
  //   });
  // }, []);
  

  useEffect(( ) => {
    if (window.pageYOffset > 1900) {
      setProgressVal(1);
    }
  }, [window.pageYOffset])

  const cloud = useParallax({
    speed: 10,
    targetElement: target.current,
    onChange: (el) => {
      setPrevProgressVal(progressVal);
      setProgressVal(el.progress);
    },
  });
  
  // const logoX = progressVal < 0.5 ? 25 : 25 - ((progressVal - 0.5)*300);
  const sloganProgress =  (progressVal - 0.42) * 4.3 ;
  const styleOpacity = progressVal === 1 ? {opacity: 0, transition: 'all 1s'} : {opacity: 1, transition: 'all 1s'};


  return <div className="menuWrapper" ref={target} >
    <div ref={cloud.ref} />
    <div className="menuContent">
      {progressVal < 1.1 && <div className="main-page_sloagan" style={prevProgressVal !== 0 && prevProgressVal < 1 ? styleOpacity : {opacity: 0}}>
        <AnimText text="Разработка
         и внедрение
         цифровых решений" progress={sloganProgress}/>

        {progressVal > 0.4 && <div className="main-page_sloagan-link">
          <a><TextAnimated text={'для промышленных предприятий'} fontSize={36}/></a>
          <div className="menuContent_link">
            <Link to="/about-sdisol">
              <ArrowAnimation/>
            </Link>
          </div> 
        </div>}
      </div>}
    </div>
  </div>
};

export default TopMenu;