import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from './components/Provider/Provider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
        <Provider>
          <App/>
        </Provider>  
      </Router>
  </React.StrictMode>
);