import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import NewsCard from "../NewsCard/NewsCard";
import { Context } from "../Provider/Provider";

const NewsLine = ({line, index}) => {
    const [toggleNews, setToggleNews] = useState(false);
    const [activeNewsIdx, setActiveNewsIdx] = useState(0);
    const contentRef = useRef(null);

    const { value, setValue } = useContext(Context);

    const scrollToElement = () => {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });

    };

    useEffect(() => {
        if (value.line === index) {
            setToggleNews(true);
            setActiveNewsIdx(value.index);
            scrollToElement()
        }

        if (value.line !== index) {
            setToggleNews(false);
        }
    }, [value])

    const newsToggleHandler = (idx) => {
        if (idx === value.index && index === value.line) {
            setToggleNews(!toggleNews);
        } else {
            setValue({
                index: idx,
                line: index,
            });
            scrollToElement();
        }
    };

    useEffect(() => {
        if (toggleNews) {
            scrollToElement();
        }
    }, [toggleNews]);

    useEffect(() => {
        if (!toggleNews && value.line !== index) {
            setTimeout(() => {
                setToggleNews(false);
            }, 300);
        }
    }, [toggleNews, value.line, index]);


    return (
        <>
            
            <div className="section-all-news__wrapper">
                
                { line.map((news, i) => (<NewsCard 
                    active={i === activeNewsIdx && index === value.line && toggleNews} 
                    item={news} index={i} link={false} onClick={() => newsToggleHandler(i)}/>))}  
                <div ref={contentRef}></div>    
            </div>
            <div
                className={`news-all-info ${toggleNews ? 'news-all-info--active' : ''}`}
            >
                <div className="news-all-info__date">{line[activeNewsIdx].date}</div>

                <div className="news-all-info__title">
                    {line[activeNewsIdx].title}   
                </div>

                <div className="news-all-info__text">
                    <div dangerouslySetInnerHTML={{ __html: line[activeNewsIdx].html_template }}></div>
                </div>
            </div> 
        </>
    )
}

export default NewsLine;