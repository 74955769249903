import React, { createContext, useState } from 'react';


const Context = createContext();

const Provider = ({ children }) => {
  const [value, setValue] = useState({
    line: null,
    index: null
  });

  return (
    <Context.Provider value={{ value, setValue }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };