import React, { useEffect, useState } from "react";
import NewsCarousel from "../../components/NewsCarousel/NewsCarousel";
import { motion } from "framer-motion"
import Page0 from "../Page0";
import TopMenu from "../topMenu";
import AnimatedLine from "../../components/InstrumentsLine/AnimatedLine";
import TextAnimated from "../../components/TextAnimated/TextAnimated";
import ScrollableBlock from "../../components/ScrollAnimatedBlock/ScrollableBlock";
import { fetchData } from "../../api/fetchData";
import ArrowBottom from "../../components/ArrowBottom/ArrowBottom";

const textPAD = [
    { id: 1, content: 'Прогнозная аналитика для промышленных предприятий' },
    { id: 2, content: 'Риск-ориентированный подход в управлении технологическими процессами' },
];

const textED = [
    { id: 1, content: 'Единая база технических знаний компании' },
    { id: 2, content: 'Цифровое сопровождение эксплуатационной деятельности' },
];

const textDCS = [
    { id: 1, content: 'Распределенная система управления технологическими процессами' },
    { id: 2, content: 'Импортозамещенный программно-аппаратный комплекс' },
];
const HomePage = () => {
    const [news, setNews] = useState(null);

    useEffect(() => {
        fetchData().then((data) => {
            const sortedData = data.data.sort((a, b) => b.date_number - a.date_number);
            
            setNews(sortedData)
        })
    }, [])

    return (
        <>
            <TopMenu />
            <Page0 />
            
            
            <ScrollableBlock sections={textPAD} type={'pad'}/>
            <ScrollableBlock sections={textED} type={'ed'}/>
            <ScrollableBlock sections={textDCS} type={'dcs'}/>

            {news ? <NewsCarousel news={news}/> : null}

            <motion.section 
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true }}
                className="section-technology"
            >
                <div className="section-technology__h1">
                    <TextAnimated text={'Наш стек технологий'} fontSize={72}/>
                </div>

                <AnimatedLine />
            </motion.section>

        </>
    );
};

export default HomePage;