import React, { useContext, useEffect, useRef, useState } from 'react';
import Icon from "../Icon";
import { Link } from 'react-router-dom';
import { Context } from '../Provider/Provider';


const NewsCard = ({ item, index, link = true, onClick, active }) => {
    const { setValue } = useContext(Context);
    const blockRef = useRef(null);
    const [blockStyle, setBlockStyle] = useState({ width: '100%', height: 'auto' });
  
    useEffect(() => {
        const updateHeight = () => {
            if (blockRef.current) {
                const width = blockRef.current.offsetWidth;
                setBlockStyle({
                    width: '100%',
                    height: width * 0.5 + 'px'
                });
            }
        };
  
        updateHeight();
    
        window.addEventListener('resize', updateHeight);
    
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const handleClick = (index) => {
        setValue({
            index: index < 3 ? index : index % 3,
            line: Math.floor(index / 3)
        })
    }

    if (!link) {
        return (
            <div onClick={onClick} to={'/news'} key={item.id} className={`card ${active ? 'card--active' : ''}`}>
                <div ref={blockRef} className="card-image" style={{ backgroundImage: `url(${item.image})`, ...blockStyle}}></div>

                <div className="card-content">
                    <div className="card-text">
                        {item.title}
                    </div>

                    <div className="card-content-footer">
                        <div className="card-logo">
                            <Icon type={`carousel-logo-${item.logo}`}/>
                        </div>
                        <div className="card-date">{item.date}</div>
                    </div>
                </div>
            </div>    
        )
    }

    return (
        // card--line
        <Link to={'/news'} onClick={() => handleClick(index)} key={item.id} className="card">
            {/* <div className="card-image card-image--line" style={{ backgroundImage: `url(${item.image})` }}></div> */}
            <div ref={blockRef} className="card-image" style={{ backgroundImage: `url(${item.image})`, ...blockStyle}}></div>
            <div className="card-content">
                <div className="card-text">
                    {item.title}
                </div>

                <div className="card-content-footer">
                    <div className="card-logo">
                        <Icon type={`carousel-logo-${item.logo}`}/>
                    </div>
                    <div className="card-date">{item.date}</div>
                </div>
            </div>
        </Link>
    );
};

export default NewsCard;
