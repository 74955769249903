import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './AnimatedLine.scss';
import { Autoplay } from 'swiper/modules';
import React from 'react';

const AnimatedLine = ({partners = false}) => {

  if (partners) {
    return (
      <>
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          autoplay={{
              delay: 1000,
              disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay]}
        >       
          <SwiperSlide key={1}>
              <img src="images/about/partner_1.png" alt="partner_1" className="instruments-line__image"/>
          </SwiperSlide>
          <SwiperSlide key={2}>
              <img src="images/about/partner_2.png" alt="partner_2" className="instruments-line__image"/>
          </SwiperSlide>
          <SwiperSlide key={3}>
              <img src="images/about/partner_3.png" alt="partner_3" className="instruments-line__image"/>
          </SwiperSlide>
          <SwiperSlide key={4}>
              <img src="images/about/partner_4.png" alt="partner_4" className="instruments-line__image"/>
          </SwiperSlide>
          <SwiperSlide key={5}>
              <img src="images/about/partner_5.png" alt="partner_5" className="instruments-line__image"/>
          </SwiperSlide>
        </Swiper>
      </>
    )
  }

  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        autoplay={{
            delay: 1000,
            disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
      >       
        <SwiperSlide key={1}>
            <img src="images/technology/JS_img.jpg" alt="JS" className="instruments-line__image"/>
        </SwiperSlide>
        <SwiperSlide key={2}>
            <img src="images/technology/kotlin_img.png" alt="Kotlin" className="instruments-line__image"/>
        </SwiperSlide>
        <SwiperSlide key={3}>
            <img src="images/technology/python_img.png" alt="Python" className="instruments-line__image"/>
        </SwiperSlide>
        <SwiperSlide key={4}>
            <img src="images/technology/react_img.png" alt="React" className="instruments-line__image"/>
        </SwiperSlide>
        <SwiperSlide key={5}>
            <img src="images/technology/spring_img.png" alt="Spring" className="instruments-line__image"/>
        </SwiperSlide>
        <SwiperSlide key={6}>
            <img src="images/technology/sql_img.png" alt="PostgreSQL" className="instruments-line__image"/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}


export default AnimatedLine;