import React, {useRef, useState, useReducer, useEffect, useCallback} from "react";
import { useParallax } from "react-scroll-parallax";
import { Stage, Container, Sprite,useTick, Graphics } from '@pixi/react';
import ArrowBottom from "../components/ArrowBottom/ArrowBottom";

var progressVal = 0.5;
const da = 0.002;

const Page0 = () => {
  const target = useRef(null);
  const stageWrapperRef = useRef(null);
  const arrowBtnRef = useRef(null);

  var w = 1024;
  var h = 768;

  var n = 600;
  var d = 250;

  var vx = 0;
  var vy = 0;
  var vz = 0;
  var points1 = [];
  var points2 = [];
  var points3 = [];
  var tpoint1 = [];
  var tpoint2 = [];
  var tpoint3 = [];
  var pAlpha = [];
  var tAlpha = [];
  var tflicker = [];
  var pTint = [];

  const arcs = [{
    radius: 200,
    startAngle: 110,
    endAngle: 310,
    anticlockwise: false,
  }
  ,{
    radius: 230,
    startAngle: 295,
    endAngle: 385,
    anticlockwise: false,
  }
  ,{
    radius: 260,
    startAngle: 80,
    endAngle: 220,
    anticlockwise: false,
  },{
    radius: 290,
    startAngle: 190,
    endAngle: 330,
    anticlockwise: false,
  },
  {
    radius: 290,
    startAngle: 25,
    endAngle: 105,
    anticlockwise: false,
  },{
    radius: 320,
    startAngle: 115,
    endAngle: 220,
    anticlockwise: false,
  },{
    radius: 350,
    startAngle: 300,
    endAngle: 420,
    anticlockwise: false,
  },{
    radius: 380,
    startAngle: 250,
    endAngle: 315,
    anticlockwise: false,
  },{
    radius: 380,
    startAngle: 40,
    endAngle: 140,
    anticlockwise: false,
  },{
    radius: 410,
    startAngle: 165,
    endAngle: 220,
    anticlockwise: false,
  },{
    radius: 410,
    startAngle: 290,
    endAngle: 380,
    anticlockwise: false,
  }
  ];

  const vvx = 0.0005;
  const vvy = 0.0008;
  const vvz = 0.000;

  const starColors = [
      '#FFEEFF', "#fad9a9", "#7dc9ff", "#ffc1c1",
      '#e4f1ff', "#b5efff", "#c8f7ff", "#ffebe4",
    '#e8e5e8', "#fff1cc", "#fffbca","#ffecec"
  ];

  const handleParallaChange = (el) => {
    const maxScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const targetScrollHeight = maxScrollHeight * 0.8;
    
    progressVal = Math.min(el.progress / 0.8, 1.0);
  
    if (progressVal > 0.98) {
      stageWrapperRef.current.style.transition = "all .5s ease-in-out";
      stageWrapperRef.current.style.opacity = 0;
      arrowBtnRef.current.style.opacity = 0;
    } else {
      stageWrapperRef.current.style.transition = "all .5s ease-in-out";
      stageWrapperRef.current.style.opacity = 1;
      arrowBtnRef.current.style.opacity = 1;
    }

    if (progressVal > 0.5) {
      arrowBtnRef.current.style.opacity = 0
      stageWrapperRef.current.style.transition = "all .5s ease-in-out";
    } else {
      arrowBtnRef.current.style.opacity = 1
      stageWrapperRef.current.style.transition = "all .5s ease-in-out";
    } 
  };


  const cloud = useParallax({
    speed: 10,
    targetElement: target.current,
    onChange: handleParallaChange,
  });

  const [stageW, setStageW] = useState(320);
  const [stageH, setStageH] = useState(200);


  useEffect(() => {
    window.addEventListener('resize', resize);
    window.addEventListener('scroll', handleScroll);

    const rect = target?.current?.getBoundingClientRect();

    if ( rect?.bottom < 0) {
      progressVal = 1.0;
      stageWrapperRef.current.style.display = "none";
    } 
    else {
      progressVal = 0.0;
      stageWrapperRef.current.style.display = "block";
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', resize);
    };
  }, []);


  const resize = () => {
    w = window.innerWidth - 32;
    h = window.innerHeight;
    setStageW(w);
    setStageH(h);

  };

  const handleScroll = ()=> {
    //const rect = target?.current?.getBoundingClientRect();
    //console.log('rect.bottom', rect?.bottom, 'top', rect?.top);
  };


  const reducer = (_, { data }) => data;

  const makeObject = () => {
    const deltaTheta = Math.PI * (3 - Math.sqrt(5)); // Угол между точками
    const radius = 23; // Радиус шара
    for (let i = 0; i < n; i++) {
      const y = 1 - (i / (n - 1)) * 2; // Равномерно распределенные значения по оси Y от -1 до 1
      const radiusAtY = Math.sqrt(1 - y * y); // Радиус на текущей высоте
      const theta = deltaTheta * i; // Угол вокруг оси Y

      const x = Math.cos(theta) * radiusAtY; // Координата X
      const z = Math.sin(theta) * radiusAtY; // Координата Z
      const sz = 400; // Начальный разброс точек во всем координатам
      const colorIndex = Math.floor(Math.random() * (starColors.length)); // Выбираем случайный цвет из всего набора

      points1[i] = x * radius;
      points2[i] = y * radius;
      points3[i] = z * radius;
      tpoint1[i] = (sz /2) - Math.random() * sz ;
      tpoint2[i] = (sz /2) - Math.random() * sz ;
      tpoint3[i] = (sz /2) - Math.random() * sz ;
      pAlpha[i]= 0.4 + (Math.random() * 0.6);  //Настройка первой яркости для мигания звезд
      tAlpha[i]= 0.1 + (Math.random() * 0.9);  //Настройка второй яркости для мигания звезд
      tflicker[i]= 30 + (Math.random() * 84);  //Настройка периода мигания звезд
      pTint[i] = starColors?.[colorIndex] || '#FFFFFF'; // Цвет перекрашивания спрайта звезды
    }
  };

  makeObject();

  const Points = ({image = "/images/pixel-blue2.png"}) => {

    const init = Array(n).fill({
      alpha: 1//(0.8 * Math.random()) + 0.01
    });

    const [motion, update] = useReducer(reducer, init);
    const iter = useRef(0);

    useTick(() => {
      const slide = (iter.current += 1);

      var x3d, y3d, z3d, tx, ty, tz, ox;
      vx = 2.5 * progressVal + (slide * vvx);
      vy = 2.5 * progressVal + (slide * vvy);
      vz = 2.5 * progressVal + (slide * vvz);

      w = window.innerWidth - 32;
      h = window.innerHeight;

      const opacity  = progressVal < 0.99 ? 1 : 1 - (progressVal );
      const progress2 = Math.min(progressVal * 1.41, 1);
      const interp = (a,b, x) => a + ((b-a)*x);

      update({
        type: 'update',
        data: motion.map((m, i) => {
          x3d = interp(tpoint1[i], points1[i], progress2);
          y3d = interp(tpoint2[i], points2[i], progress2);
          z3d = interp(tpoint3[i], points3[i], progress2);

          ty = (y3d * Math.cos(vx)) - (z3d * Math.sin(vx));
          tz = (y3d * Math.sin(vx)) + (z3d * Math.cos(vx));
          tx = (x3d * Math.cos(vy)) - (tz * Math.sin(vy));
          tz = (x3d * Math.sin(vy)) + (tz * Math.cos(vy));
          ox = tx;
          tx = (tx * Math.cos(vz)) - (ty * Math.sin(vz));
          ty = (ox * Math.sin(vz)) + (ty * Math.cos(vz));

          const flickerState = Math.floor(iter.current % tflicker[i] ) / tflicker[i]; // меняется от 0 до 1, нужно для мерцания звезд

          const  alp = interp(flickerState > 0.5 ? pAlpha[i] : tAlpha[i], flickerState > 0.5 ? tAlpha[i] : pAlpha[i], flickerState);

          return {
            x: (512 * tx) / (d - tz) + w / 2,
            y: (h/2) - (512 * ty) / (d - tz) ,
            rotation: 0,
            anchor: [0.5, 0.5],
            scale: 1,
            height: 7,
            width: 7,
            tint: pTint[i],
            alpha: progress2 < 0.99 ? interp(alp, opacity, progress2) : 0,
          }
        })

      });
    });

    return <>
      {motion.map((m, i) => <Sprite key={`sprite-0-${i}`} image={image} {...m} />)}
    </>
  };

  const Planet = ({rx, ry, da, sc = 1, angle = 0, image = "/images/page1/circle-vector.svg" }) => {
    const [motion, update] = useReducer(reducer);
    const iter = useRef(angle / 180 * Math.PI);
  
    useTick((delta) => {
      const i = (iter.current += da * delta);
      const opacity  = (progressVal*10) - 6.6;
  
      update({
        type: 'update',
        data: {
          x: Math.sin(i) * rx,
          y: Math.cos(i) * ry,
          rotation: 0,
          anchor: [0.5, 0.5],
          scale: sc,
          alpha: progressVal < 0.999 ? opacity : 0,
        },
      });
    });
  
    return <Sprite image={image} {...motion} />;
  };

  const ArcsArray = ({ da = -0.002, sc = 1, angle = 0}) => {
    const [motion, update] = useReducer(reducer);
    const iter = useRef(angle / 180 * Math.PI);
  
    useTick((delta) => {
      const i = (iter.current += da * delta);
      const opacity  = (progressVal*10) - 5.6;
  
      update({
        type: 'update',
        data: {
          x: 0,
          y: 0,
          rotation: i,
          anchor: [0.5,0.5],
          scale: sc,
          alpha: progressVal > 0.50 ? opacity : 0,
        },
      });
    });
  
    return <Graphics draw={draw}  {...motion}/>;
  };

  const rx = stageW / 2;
  const ry = stageH / 2;

  const draw = useCallback((g) => {
    g.clear();
    g.lineStyle(4, 0x01AFEB, 1);


    for (const {radius, startAngle, endAngle, anticlockwise} of arcs) {
      g.arc(0, 0, radius, startAngle / 180 * Math.PI, endAngle / 180 * Math.PI, anticlockwise);
      g.endFill();
    }
  }, [arcs]);


  return (
    <div ref={target} className="page0wrapper">
      <div ref={cloud.ref} />
      <div ref={stageWrapperRef} className="stageWrapper">
        <Stage
          width={stageW}
          height={stageH}
          onMount={resize}
          options={{
            backgroundAlpha: 0,
            antialias: true,
            autoDensity: true,
          }}
        >
          <Container x={0} y={0}>
            <Points />
          </Container>

          <Container x={rx} y={ry}>
            <ArcsArray />
          </Container>

          <Container x={rx} y={ry}>
            <Planet rx={290} ry={290} da={da} sc={0.5} angle={90}/>
            <Planet rx={350} ry={350} da={da} sc={0.5} angle={0}/>
            <Planet rx={410} ry={410} da={da} sc={0.5} angle={170}/>

            <Planet rx={200} ry={200} da={da} sc={.9} angle={25}/>
            <Planet rx={380} ry={380} da={da} sc={.9} angle={215}/>

            <Planet rx={410} ry={410} da={da} sc={.9} angle={315}/>
            <Planet rx={410} ry={410} da={da} sc={.9} angle={85}/>
          </Container>

          <Container x={rx - 60} y={ry + 40}>
            <Planet rx={410} ry={410} da={da} sc={.7} angle={315} image={'/images/page1/ED.svg'}/>
          </Container>

          <Container x={rx - 100} y={ry + 40}>
            <Planet rx={410} ry={410} da={da} sc={.7} angle={85} image={'/images/page1/DCS.svg'}/>
          </Container>

          <Container x={rx - 100} y={ry + 15}>
            <Planet rx={380} ry={380} da={da} sc={.7} angle={215} image={'/images/page1/PAD.svg'}/>
          </Container>

          <Container x={rx - 175} y={ry}>
            <Planet rx={0} ry={-20} da={0} sc={.7} angle={0} image={'/images/page1/Sdisol-logo.svg'}/>
          </Container>
        </Stage>
      </div>
      <div ref={arrowBtnRef} className="page0Arrow">
          <ArrowBottom />
      </div>
    </div>
  );
}
export default Page0;