import React, { useEffect, useState } from 'react'
import "./ArrowBottom.scss";

const ArrowBottom = () => {
  // const [isMouseDown, setIsMouseDown] = useState(false);

  // useEffect(() => {
  //   let intervalId;

  //   if (isMouseDown) {
  //     intervalId = setInterval(() => {
  //       // Проверяем, достигли ли мы позиции 600px
  //       if (window.scrollY >= 1800) {
  //         setIsMouseDown(false); // Останавливаем скролл
  //         return; // Выходим из функции
  //       }
  //       window.scrollBy(0, 20); // Плавный скролл вниз на 10 пикселей
  //     }, 20); // Интервал в 20 мс
  //   }

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [isMouseDown]);

  // const handleMouseDown = () => {
  //   setIsMouseDown(true);
  // };

  // const handleMouseUp = () => {
  //   setIsMouseDown(false);
  // };

  return (
    <div id="scroll" className="arrowBottom_wrapper">
        <svg className='arrowBottom_svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.19 133.19">
            <circle cx="66.15" cy="2.13" r="2.13"></circle>
            <circle cx="86.09" cy="5.15" r="2.13"></circle>
            <circle cx="104.13" cy="14.18" r="2.13"></circle>
            <circle cx="118.49" cy="28.34" r="2.13"></circle>
            <circle cx="127.77" cy="46.25" r="2.13"></circle>
            <circle cx="131.06" cy="66.15" r="2.13"></circle>
            <circle cx="128.04" cy="86.09" r="2.13"></circle>
            <circle cx="119.01" cy="104.13" r="2.13"></circle>
            <circle cx="104.85" cy="118.49" r="2.13"></circle>
            <circle cx="86.94" cy="127.77" r="2.13"></circle>
            <circle cx="67.04" cy="131.06" r="2.13"></circle>
            <circle cx="47.1" cy="128.04" r="2.13"></circle>
            <circle cx="29.07" cy="119.01" r="2.13"></circle>
            <circle cx="14.71" cy="104.85" r="2.13"></circle>
            <circle cx="5.42" cy="86.94" r="2.13"></circle>
            <circle cx="2.13" cy="67.04" r="2.13"></circle>
            <circle cx="5.15" cy="47.1" r="2.13"></circle>
            <circle cx="14.18" cy="29.07" r="2.13"></circle>
            <circle cx="28.34" cy="14.71" r="2.13"></circle>
            <circle cx="46.25" cy="5.42" r="2.13"></circle>
        </svg>
        <div 
        // onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseUp}
        // onMouseLeave={handleMouseUp} 
        className="arrowBottom__arrow center" ></div>
    </div>
  )
}

export default ArrowBottom