import React from 'react';
import AnimatedCircleItem from './AnimatedCircleItem/AnimatedCircleItem';

const AnimatedSVGCircle = ({ classStyle }) => {

  return (
    <svg className={`svg-element ${classStyle}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1300 1300">
      <defs>
        <radialGradient id="radial-gradient" cx="639.77" cy="652.7" r="639.77" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#00aeef"/>
          <stop offset="1" stopColor="#00b7a5"/>
        </radialGradient>
      </defs>
      <circle cx="650" cy="650" r="637.77" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="607.4" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="577.04" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="546.67" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="516.31" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="485.94" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="455.58" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="425.21" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="394.85" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>
      <circle cx="650" cy="650" r="364.48" style={{fill: 'none', strokeMiterlimit: 10, strokeWidth: '4px', stroke: 'url(#radial-gradient)'}}/>



      <AnimatedCircleItem r={364.48} angleDegrees={70} reverse={false}/>
      <AnimatedCircleItem r={425.21} angleDegrees={230} />
      <AnimatedCircleItem r={516.31} angleDegrees={45} reverse={false}/>
      <AnimatedCircleItem r={516.31} angleDegrees={85} />

      <AnimatedCircleItem r={577.04} angleDegrees={180} reverse={false}/>
      <AnimatedCircleItem r={577.04} angleDegrees={355} />

      <AnimatedCircleItem r={607.4} angleDegrees={310} reverse={false}/>
      <AnimatedCircleItem r={637.77} angleDegrees={130} />
      <AnimatedCircleItem r={637.77} angleDegrees={270} reverse={false}/>


      <AnimatedCircleItem r={364.48} angleDegrees={260} type='half' dur={18}/>
      <AnimatedCircleItem r={394.85} angleDegrees={115} type='half' dur={18} reverse={false}/>
      <AnimatedCircleItem r={455.58} angleDegrees={300} type='half' dur={18}/>
      <AnimatedCircleItem r={485.94} angleDegrees={150} type='half' dur={18} reverse={false}/>
      <AnimatedCircleItem r={516.31} angleDegrees={200} type='half' dur={18} reverse={false}/>

      <AnimatedCircleItem r={577.04} angleDegrees={110} type='half' dur={18}/>
      <AnimatedCircleItem r={577.04} angleDegrees={255} type='half' dur={18} reverse={false}/>

      <AnimatedCircleItem r={637.77} angleDegrees={50} type='half' dur={18}/>

    </svg>
  );
}

export default AnimatedSVGCircle;
