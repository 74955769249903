
import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import './ScrollableBlock.scss';
import TextAnimated from '../TextAnimated/TextAnimated';
import { Link } from "react-router-dom";
import { animateTopMove } from '../../api/animation-varints';
import ArrowAnimation from '../ArrowAnimation/ArrowAnimation';
import AnimatedSVGLeftHalf from '../AnimatedSVG/AnimatedSVGLeftHalf';
import AnimatedSVGCircle from '../AnimatedSVG/AnimatedSVGCircle';

const ScrollableBlock = ({ sections, type='pad' }) => {
  const containerRef = useRef(null);
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [logoUrl, setLogoUrl] = useState('');
  const [linkUrl, setLinkUrl] = useState('');

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const sectionHeight = 1 / 4;
  const activeIndex = useTransform(
    scrollYProgress,
    [sectionHeight, sectionHeight * 2, sectionHeight * 3, 1],
    [0, 1, 2, 2]
  );

  useEffect(() => {
    switch (type) {
      case 'pad':
        setLogoUrl("images/logos/sdisol-PAD-logo.svg")
        setLinkUrl("/sdisol-pad")
        break;
      case 'ed':
        setLogoUrl("images/logos/sdisol-ED-logo.svg")
        setLinkUrl("/sdisol-ed")
        break;
      case 'dcs':
        setLogoUrl("images/DCSpage/logo_DCS.svg")
        setLinkUrl("/sdisol-dcs")
        break;
      default:
        setLogoUrl("")
        break;
    }
  })

  useEffect(() => {
    activeIndex.onChange((index) => {
      const newIndex = Math.min(Math.round(index), sections.length - 1);
      setActiveSection(sections[newIndex].id);
    });
  }, [activeIndex]);

  return (
    <div className="scroll-block" ref={containerRef}>
      <div
        className="scroll-block_content"
      >
        <section 
            className="section-solutions section-solutions2"
            style={{backgroundImage: type === 'eco' && 'url(images/ECO-bg.png)'}}
        >
            {type === 'pad' && (
              <img
                src="images/svg-elements/small-triangle-element.svg"
                className="svg-element ED-triangle-element"
              /> 
            )}

            {type === 'pad' && (
              <img
                src="images/svg-elements/landscape-big-element.svg"
                className="svg-element PAD-landscape-element"
              />
            )}

            {type === 'pad' && (
              <AnimatedSVGLeftHalf classStyle={'svg-half-top-right'}/>
            )}


            {type === 'ed' && (
              <AnimatedSVGLeftHalf classStyle={'svg-half-bottom-right'}/>
            )}
            {type === 'ed' && (
              <img
                src="images/svg-elements/small-triangle-element.svg"
                className="svg-element ED-triangle-element"
              /> 
            )}
            
            {type === 'ed' && (
              <img
                src="images/svg-elements/Sdisol-ED-ellipse-element.svg"
                className="svg-element ED-ellipse-element"
                alt=""/> 
            )}

            {type === 'dcs' && (
              <img
                src="images/svg-elements/small-triangle-element.svg"
                className="svg-element ED-triangle-element"
              /> 
            )}

            {type === 'dcs' && (
              <AnimatedSVGCircle classStyle={'svg-circle-bottom-right-full'}/>
            )}

            <motion.div 
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true }}
                className="section-solutions__text-box section-solutions__text-box2"
            >
                <motion.p
                  key={activeSection}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="h1"
                  style={{minHeight: 300}}
                >
                    <TextAnimated text={sections.find((section) => section.id === activeSection)?.content} fontSize={80}/>
                </motion.p>

                <motion.div variants={animateTopMove} custom={5} className="section-solutions__logo-arrow-box">
                    <img
                        src={logoUrl}
                        className="section-solutions__sdisol-PAD-logo"
                        alt=""/>

                    <Link to={linkUrl}>
                        <div className="arrow-three__box">
                            <ArrowAnimation />
                        </div>
                    </Link>
                </motion.div>
            </motion.div>
        </section>
      </div>
      <div className="scroll-block_spacer"></div>
    </div>
  );
};

export default ScrollableBlock;