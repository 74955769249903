import React, { useEffect, useRef } from 'react'
import './SdisolEd.scss'
import { motion } from "framer-motion";
import {animateLeftMove} from '../../api/animation-varints';
import TextAnimated from '../../components/TextAnimated/TextAnimated';
import AnimatedSVGCircle from '../../components/AnimatedSVG/AnimatedSVGCircle';
import Gallery from '../../components/Gallery/Gallery';
import EdAnimatedLine from '../../components/EdAnimatedLine/EdAnimatedLine';
import EdCalendarAnimated from '../../components/EdCalendarAnimated/EdCalendarAnimated';
import EdAnimatedIcon from '../../components/EdAnimatedIcon/EdAnimatedIcon';
import EdSteps from '../../components/EdSteps/EdSteps';
import ArrowBottom from '../../components/ArrowBottom/ArrowBottom';


const SdisolEd = () => {

    const textTransition = {
        hidden: { opacity: 0, y: 50 },
        visible: custom => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: .5,
                delay: custom * 0.4,
                ease: "easeInOut",
            }
        })
    };

    const arrowBtnRef = useRef(null)

    const handleScroll = () => {
        arrowBtnRef.current.style.opacity = (1 - (window.scrollY * 0.0008))
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []); 

    return (

        <div className="ed">
            <AnimatedSVGCircle classStyle={'svg-circle-right-full-dcs'}/>

            <motion.img
                custom={3} variants={animateLeftMove}
                src="images/svg-elements/landscape-big-element.svg"
                className="svg-element PAD-page-landscape-element "
            />

            <motion.img
                custom={4} variants={animateLeftMove}
                src="images/svg-elements/dsc_triangle.svg"
                className="svg-element PAD-page-round-element"
                alt=""/>

            <motion.section 
                initial="hidden"
                animate="visible" 
                className="ed__main"
            >
                <div className="container">
                    <div className="ed__main__header">
                        <h1 className="ed__main__header-title">
                            <TextAnimated text={`Единая база технических знаний для промышленных предприятий`} fontSize={50} />
                        </h1>
                        <div className='ed__main__header-wrapper'>
                            <motion.img className='ed__main__header-logo' custom={1} variants={textTransition} src="images/logos/sdisol-ED-logo.svg" alt="sdisol-ED-logo"/>
                            {/* <motion.img custom={1} variants={textTransition} className='ed__main__header-wrapper_cifra' src="images/PADpage/min-cifr.svg" alt="min-cifra"/>     */}
                        </div>
                    </div>
                </div>

                <div className='ed__main-animatedLine'>
                    <EdAnimatedLine />
                </div>

                <div className="container">
                    <div className="ed__main__content">
                        <div className="ed__main__content-text">
                            <TextAnimated 
                                text={`Цифровое сопровождение производственной деятельности в едином информационном пространстве`} 
                                fontSize={32}
                                order={4}
                            />  
                        </div> 
                    </div>
                </div>
                
            </motion.section>
            <div className='arrowBottomEd_position' ref={arrowBtnRef}>
                <ArrowBottom />
            </div>
            <section className="ed__method">
                <div className="container container-borderTop">
                    <h2 className="ed__method-title">
                        <TextAnimated 
                            text={`Позволяет:`} 
                            fontSize={46}
                        />   
                    </h2>
                    <motion.div 
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='ed__method-grahp'
                    >
                        <div className='ed__method-grahp-wrapper'>
                            <EdAnimatedIcon type={"docEd_1"} />
                            <EdAnimatedIcon type={"docEd_2"} />
                            <EdAnimatedIcon type={"docEd_3"} />
                            <EdAnimatedIcon type={"docEd_4"} />
                        </div>
                        <div className='ed__method-text'>
                            <TextAnimated 
                                text={`Поддерживать техническую документацию в актуальном состоянии`} 
                                custom={5}
                                fontSize={34}
                            />
                        </div>
                    </motion.div>

                    <motion.div 
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='ed__method-calendar'
                    >
                        <EdCalendarAnimated />
                        <div className='ed__method-text' style={{ width: '422px' }}>
                            <TextAnimated 
                                text={`Автоматически контролировать сроки выполнения работ`} 
                                fontSize={34}
                            />
                        </div>    
                    </motion.div>

                    <div className='ed__method-text' style={{ textAlign: 'center' }}>
                        <TextAnimated 
                            text={`Формировать историю эксплуатации оборудования`} 
                            fontSize={34}
                        />
                    </div> 
                    </div>
                        <EdSteps />
                        
                    <div className="container container-borderBottom">
                    <div className='ed__method-text' style={{ textAlign: 'center' }}>
                        <TextAnimated 
                            text={`Выполнять цифровое планирование работ`} 
                            fontSize={34}
                        />
                    </div> 

                    <div className='ed__method-list'>
                        <EdAnimatedIcon type={"listEd_1"} />
                        <EdAnimatedIcon type={"listEd_2"} />
                        <EdAnimatedIcon type={"listEd_3"} />
                    </div>
                    {/* <motion.div 
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <ControllerAnimated />
                        <p className="ed__method-text pad__method-text--l">
                            <TextAnimated 
                                text={`Возможность горячей замены процессоров`} 
                                fontSize={32}
                            />
                        </p>
                    </motion.div>
                    <p className="ed__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Резервирование модулей ввода-вывода`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <ControllerAnimated2 />
                        <p className="ed__method-text pad__method-text--l">
                            <TextAnimated 
                                text={`Возможность горячей замены модулей ввода вывода`} 
                                fontSize={32}
                            />
                        </p>

                    </motion.div>

                    <p className="ed__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Резервирование корзин контроллеров`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >

                        <ControllerAnimated3 />
                    </motion.div>
                    <p className="ed__method-text pad__method-text--l">
                        <TextAnimated 
                            text={`Поканальная гальваническая развязка`} 
                            fontSize={32}
                        />
                    </p>
                    <motion.div
                        whileInView="visible"
                        initial="hidden"
                        viewport={{ once: true, amount: 0.5 }}
                        className='pad__method-grahp'
                    >
                        <motion.div className='pad__method-galvanic-wrapper'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                        >
                            <motion.img custom={1} variants={animateLeftMove} src='images/DCSpage/galvanic.svg'/>
                            <motion.div custom={1} variants={animateRightMove}>
                                <img className='reverse' src='images/DCSpage/galvanic.svg'/>
                            </motion.div>
                            
                        </motion.div>
                        
                    </motion.div> */}
                </div>
            </section>

            {/* <motion.section 
                className="ed__risk" 
                whileInView="visible"
                initial="hidden"
                viewport={{ once: true, amount: 0.5 }} 
            >
                <motion.img
                    custom={2} variants={animateLeftMove}
                    src="images/svg-elements/small-round-element.svg"
                    className="svg-element DCS-page-round-element"
                    alt=""
                />
                <div className='container' >
                    <h2 className="ed__risk-title">
                        <TextAnimated 
                            text={`Гибкие возможности масштабирования`} 
                            fontSize={48}
                        />
                    </h2>
                    <div className="ed__risk-text ed__risk-text--l">
                        <TextAnimated 
                            text={`Кроссплатформенная SCADA позволяет запускать один и тот же проект на операционных системах Windows, Linux и  QNX и использовать узлы с разными операционными системами в одном проекте`} 
                            fontSize={32}
                        />
                    </div>
                    
                    <DCSAnimated2 />
                </div>
            </motion.section> */}

            <div className="pad__performance-video">
                <div className='container'>
                    <Gallery pageType="ED"/>
                </div>        
            </div>

            {/* <section className='pad__performance'>
                <motion.div
                    whileInView="visible"
                    initial="hidden"
                    viewport={{ once: true, amount: 0.5 }} 
                    className='container'
                >
                    <motion.div variants={animateTopMove} className="ed__performance-video">
                        <a href="" className="ed__performance-video-btn">
                            <img src="images/PADpage/play_icon.svg" alt=""/>
                        </a>
                        <div className="ed__performance-video-text">Посмотрим как это работает</div>
                    </motion.div>
                </motion.div>
            </section> */}
        </div>
    )
}

export default SdisolEd;